import { withFormik } from 'formik';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { validationSchema } from './validationSchema';
import { Form } from './Form';
import { API } from '../../../services/api';

const t = key => I18n.t(`biological_safeties.new_edit.${key}`);

const IbcRegistrationFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const ibcRegistration = IbcRegistrationFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(ibcRegistration.id)) {
      IbcRegistrationFunctions.create(ibcRegistration, formikProps, values.afterSave);
    } else {
      IbcRegistrationFunctions.update(ibcRegistration, formikProps, values.afterSave);
    }
  },
  create: async (ibcRegistration, formikProps, afterSave) => {
    const { ok, data } = await API.biologicalSafety.ibcRegistrations.create({
      ibc_registration: ibcRegistration,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/biological_safeties/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.biological_safeties.edit.title'));
      formikProps.resetForm({
        values: IbcRegistrationFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (ibcRegistration, formikProps, afterSave) => {
    const { ok, data } = await API.biologicalSafety.ibcRegistrations.update(ibcRegistration.id, {
      ibc_registration: ibcRegistration,
    });
    if (ok) {
      formikProps.resetForm({
        values: IbcRegistrationFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: ibcRegistration => ({
    afterSave: () => {},
    inspections: ibcRegistration.inspections,
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'IBC_registration_no',
    'person_id',
    'administrative_contact_id',
    'IBC_registration_title',
    'ibc_status_id',
    'IBC_approval_date',
    'expiration_date',
    'last_inspection_date',
    'bsl_id',
  ],
  purposefullyNotSnakeCasedBackendValues: [
    'IBC_registration_no',
    'IBC_registration_title',
    'IBC_approval_date',
  ]
});

export const IbcRegisrationForm = withFormik({
  mapPropsToValues: props => IbcRegistrationFunctions.prepareInitialValues(props.ibcRegistration),
  handleSubmit: IbcRegistrationFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
