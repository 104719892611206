import get from 'lodash/get';
import { API } from '../../../services/api';
import { prepareCustomFieldsAnswers, showBackendErrorMessage } from '../../../services/utils';

const t = (key, attributes = {}) =>
  I18n.t(`inventory.equipment.equipment.new_edit.${key}`, attributes);

export const setEquipmentType = async (newSelection, setFieldValue) => {
  await setFieldValue('equipment_type_name', get(newSelection, 'data.text'));
  await setFieldValue(
    'internal_inspection_frequency',
    get(newSelection, 'data.internal_inspection_frequency'),
  );
  await setFieldValue(
    'external_inspection_frequency',
    get(newSelection, 'data.external_inspection_frequency'),
  );

  const { ok, data } = await API.equipment.equipmentFieldDefinitions.index({
    equipment_type_id: newSelection.value,
  });

  if (ok) {
    const definitions = await data.data.map(fieldDefinition => fieldDefinition.attributes);
    const answers = await prepareCustomFieldsAnswers('equipment', definitions);
    await setFieldValue('equipment_field_definitions', definitions);
    await setFieldValue('equipment_field_answers_attributes', answers);
  } else {
    await showBackendErrorMessage(t('flash.error.unexpected_error'));
  }
};
