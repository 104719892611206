import yup from '../../../../components/form/CustomYupValidator';
import { transformNaNValue } from '../../../../../services/formHelper';
import {
  currentDateTime,
  dateFormatter,
  getConvertedMoment,
  newMoment,
} from '../../../../services/dateFormatUtils';

const t = (key, params = {}) =>
  I18n.t(
    `radiation_safety.rad_inventory_items.new_edit.modals.withdrawal.validations.${key}`,
    params,
  );

export const validationSchema = yup.object().shape({
  quantity: yup
    .number()
    .transform(transformNaNValue)
    .required(I18n.t('validations.required')),
  remainingQuantity: yup
    .number()
    .transform(transformNaNValue)
    .positive(I18n.t('validations.positive')),
  remainingActivity: yup
    .number()
    .transform(transformNaNValue)
    .positive(I18n.t('validations.positive')),
  withdrawn_at: yup
    .string()
    .nullable()
    .test(
      'withdrawn_at_cannot_be_later_than_item_last_limits_related_change_at',
      t('withdrawn_at_cannot_be_later_than_item_last_limits_related_change_at'),
      // an arrow function cannot be used beneath because 'this' does not work with it
      // see https://github.com/jquense/yup#mixedtestname-string-message-string--function-test-function-schema
      function(withdrawn_at) {
        const lastChangeDate = this.parent.item_last_limits_related_change_at;

        return withdrawn_at && lastChangeDate
          ? getConvertedMoment(withdrawn_at).isSameOrAfter(getConvertedMoment(lastChangeDate))
          : true;
      },
    )
    .test(
      'withdrawn_at_cannot_be_later_than_current_time',
      t('withdrawn_at_cannot_be_later_than_current_time', {
        current_time: dateFormatter.dateTime(currentDateTime()),
      }),
      function(withdrawn_at) {
        return withdrawn_at ? getConvertedMoment(withdrawn_at).isSameOrBefore(newMoment()) : true;
      },
    )
    .required(I18n.t('validations.required')),
});
