import React from 'react';
import { passDefaultAttributesToChildren } from '../../services/utils';
import { FormSectionHeader } from './FormSectionHeader';
import { FormSectionTitle } from './FormSectionTitle';
import { getFormikErrors } from '../../../services/formHelper';
import { Paper } from './Paper';

export const FormSection = ({
  title,
  children,
  required,
  name,
  errors,
  touched,
  defaultChildrenAttributes = false,
}) => (
  <Paper>
    {title && (
      <FormSectionHeader>
        <FormSectionTitle required={required} error={getFormikErrors(name, touched, errors)}>
          {title}
        </FormSectionTitle>
      </FormSectionHeader>
    )}
    {defaultChildrenAttributes
      ? passDefaultAttributesToChildren(children, defaultChildrenAttributes)
      : children}
  </Paper>
);
