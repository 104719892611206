import React from 'react';
import { FastField } from 'formik';
import { isDefined } from '../../../services/utils';
import { FormHeader } from '../../../components/form/FormHeader';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { TextField } from '../../../components/fields/TextField';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { DateTimeField } from '../../../components/fields/DateTimeField';
import { DateField } from '../../../components/fields/DateField';
import { Assessments } from './Assessments';
import { Footer } from './Footer';
import { BiologicalAgents } from './BiologicalAgents';

const t = key => I18n.t(`biological_safeties.new_edit.${key}`);

export const Form = props => {
  const {
    values: { id, IBC_registration_no },
  } = props;
  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)}
        investigable={
          isDefined(id) && {
            id,
            identifier: IBC_registration_no,
            type: 'BiologicalSafety',
          }
        }
        pdfExportLink={
          isDefined(props.values.id)
            ? `/salute_portal/api/biological_safety/ibc_registrations/${props.values.id}.pdf`
            : ''
        }
        pdfExportMessage={I18n.t(`flash_message.async_report_render`, {
          user_email: props.currentUser.email,
        })}
      />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.biological_safety',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.biological_safety'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField required name="IBC_registration_no" component={TextField} />
          <FastField
            required
            name="person_id"
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
            hint={t('hints.person_id')}
          />
          <FastField
            name="administrative_contact_id"
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
          <FastField name="IBC_registration_title" component={TextField} />
          <FastField
            required
            name="ibc_status_id"
            api={API.selectors.ibcStatuses.index}
            selectionApi={API.selectors.ibcStatuses.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField name="IBC_approval_date" component={DateField} />
          <FastField name="expiration_date" component={DateField} />
          <FastField disabled name="last_inspection_date" component={DateTimeField} />
          <FastField
            required
            name="bsl_id"
            api={API.selectors.bsls.index}
            selectionApi={API.selectors.bsls.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
        </FormSection>
      </DefaultAttributesGroup>
      <Footer {...props} />
      <BiologicalAgents {...props} />
      <Assessments {...props} />
    </form>
  );
};
