import { withFormik } from 'formik';
import { API } from '../../../../services/api';
import { WasteRequestContainerForm } from './Form';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`requests.waste_requests.new_edit.modals.waste_request_containers.${key}`);

const WasteRequestContainerFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const wasteRequestContainer = WasteRequestContainerFunctions.prepareValuesToSubmit({
      ...values,
    });
    if (isDefined(wasteRequestContainer.id)) {
      WasteRequestContainerFunctions.update(wasteRequestContainer, formikProps);
    } else {
      WasteRequestContainerFunctions.create(wasteRequestContainer, formikProps);
    }
  },
  create: async (wasteRequestContainer, formikProps) => {
    const { ok, data } = await API.requests.wasteRequests
      .wasteRequestContainers(formikProps.props.wasteRequestId)
      .create({ waste_request_container: wasteRequestContainer });
    if (ok) {
      formikProps.resetForm({
        values: WasteRequestContainerFunctions.prepareInitialValues(data.data.attributes),
      });
      formikProps.props.hide();
      formikProps.props.listRef.current.refresh();
      showSuccessMessage(t('flash.success.create'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.create'), data);
    }
  },
  update: async (wasteRequestContainer, formikProps) => {
    const { ok, data } = await API.requests.wasteRequests
      .wasteRequestContainers(formikProps.props.wasteRequestId)
      .update(wasteRequestContainer.id, { waste_request_container: wasteRequestContainer });
    if (ok) {
      formikProps.resetForm({
        values: WasteRequestContainerFunctions.prepareInitialValues(data.data.attributes),
      });
      formikProps.props.hide();
      formikProps.props.listRef.current.refresh();
      showSuccessMessage(t('flash.success.update'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.update'));
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: {},
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'container_type_id',
    'quantity',
  ],
});

export const WasteRequestContainersModalContent = withFormik({
  mapPropsToValues: props =>
    WasteRequestContainerFunctions.prepareInitialValues(props.wasteRequestContainer),
  handleSubmit: WasteRequestContainerFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(WasteRequestContainerForm);
