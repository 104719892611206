import React, { useState, useEffect } from 'react';
import { EmployeeTrainingForm } from './Form';
import { Loader } from '../../components/Loader';
import { API } from '../../services/api';
import { isDefined } from '../../services/utils';

export const EmployeeTraining = props => {
  const [fetching, setFetching] = useState(true);
  const [employeeTraining, setEmployeeTraining] = useState({});
  useEffect(() => {
    (async () => {
      const api = isDefined(props.id)
        ? API.employeeTrainings.employeeTrainings.show
        : API.employeeTrainings.employeeTrainings.new;
      const { ok, data } = await api(props.id);
      if (ok) {
        await setEmployeeTraining(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <EmployeeTrainingForm {...props} employeeTraining={employeeTraining} />;
};
export default EmployeeTraining;
