import React from 'react';
import { ModalSection } from '../../../../components/modals/ModalSection';
import { FormFooter, FormFooterRightPart } from '../../../../components/form/FormFooter';
import { CancellationLink } from '../../../../components/links/CancellationLink';
import { BrandButton } from '../../../../components/buttons/BrandButton';
import { showBackendErrorMessage, showSuccessMessage } from '../../../../services/utils';
import { API } from '../../../../services/api';
import { PersonFunctions } from './index';

const t = (key, params = {}) =>
  I18n.t(`inventory.people.people.new_edit.modals.reset_password.${key}`, params);

export const ResetPasswordModalContent = props => {
  const {
    values: { id, fullName },
    resetForm,
    modalRef,
  } = props;

  return (
    <ModalSection>
      <p>{t('body.reset_password', { person: fullName })}</p>
      <p>{t('body.email_warning')}</p>
      <FormFooter>
        <FormFooterRightPart>
          <CancellationLink onClick={() => modalRef.current.hide()} />
          <BrandButton
            onClick={async () => {
              const { ok, data } = await API.people.people.update_password(id);
              if (ok) {
                resetForm({
                  values: PersonFunctions.prepareInitialValues(data.data.attributes),
                });
                modalRef.current.hide();
                showSuccessMessage(t('flash.success.reset_password'));
              } else {
                showBackendErrorMessage(t('flash.error.reset_password'), data);
              }
            }}
          >
            {t('submit.reset_password')}
          </BrandButton>
        </FormFooterRightPart>
      </FormFooter>
    </ModalSection>
  );
};
