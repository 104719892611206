import yup from '../../../components/form/CustomYupValidator';
import { compareTimes } from '../../../services/utils';
import { getUtcMomentIgnoringTime } from '../../../services/dateFormatUtils';

const t = (key, attributes = {}) => I18n.t(`activerecord.errors.models.${key}`, attributes);

export const validationSchema = yup.object().shape({
  requesting_person_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  responsible_person_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  request_description: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  date_requested: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  start_date: yup
    .string()
    .typeError(I18n.t('validations.date'))
    .required(I18n.t('validations.required'))
    // an arrow function cannot be used beneath because 'this' does not work with it
    // see https://github.com/jquense/yup#mixedtestname-string-message-string--function-test-function-schema
    .test(
      'start_date_cannot_be_later_than_end_date',
      t('safe_work_authorization.attributes.start_date.cannot_be_later_than', {
        date: I18n.t('activerecord.attributes.safe_work_authorization.end_date'),
      }),
      function(start_date) {
        return getUtcMomentIgnoringTime(start_date).isSameOrBefore(
          getUtcMomentIgnoringTime(this.parent.end_date),
        );
      },
    )
    .test(
      'cannot_be_more_than_3_months_earlier_than_end_date',
      t(
        'safe_work_authorization.attributes.start_date.cannot_be_more_than_3_months_earlier_than_end_date',
      ),
      function(start_date) {
        return getUtcMomentIgnoringTime(start_date)
          .add(3, 'months')
          .isSameOrAfter(getUtcMomentIgnoringTime(this.parent.end_date));
      },
    ),
  end_date: yup
    .string()
    .typeError(I18n.t('validations.date'))
    .required(I18n.t('validations.required')),
  start_time: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required'))
    .test(
      'start_time_cannot_be_later_than_end_time',
      t('safe_work_authorization.attributes.start_time.cannot_be_later_than', {
        date: I18n.t('activerecord.attributes.safe_work_authorization.end_time'),
      }),
      function(start_time) {
        return compareTimes(start_time, this.parent.end_time);
      },
    ),
  end_time: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  resolution_date: yup
    .string()
    .nullable()
    .when('submitType', {
      is: submitType => submitType === 'resolve',
      then: yup
        .string()
        .nullable()
        .required(I18n.t('validations.required')),
    }),
  swaQuestionAnswersAttributes: yup
    .array()
    .required(I18n.t('validations.required'))
    .test(
      'at_least_one_answer_should_be_selected',
      t(
        'safe_work_authorization.attributes.question_answers.at_least_one_answer_should_be_selected',
      ),
      function () {
        return this.parent.swaQuestionAnswersAttributes.some( e => {
          return e.answer !== false && e.answer !== null;
        });
      },
    ),
  swa_date_time_entries_attributes: yup.array(
    yup.object().shape({
      checked: yup
        .boolean()
        .nullable()
        .required(I18n.t('validations.required')),
      start_time: yup
        .string()
        .nullable()
        .required(I18n.t('validations.required'))
        .test(
          'start_time_cannot_be_later_than_end_time',
          t('swa_date_time_entry.attributes.start_time.cannot_be_later_than', {
            date: I18n.t('activerecord.attributes.swa_date_time_entry.end_time'),
          }),
          function(start_time) {
            return this.parent.checked && !this.parent._destroy
              ? compareTimes(start_time, this.parent.end_time)
              : true;
          },
        ),
      end_time: yup
        .string()
        .nullable()
        .required(I18n.t('validations.required')),
    }),
  ),
});
