import { withFormik } from 'formik';
import snakeCase from 'lodash/snakeCase';
import { Form } from './Form';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`requests.incident_requests.new_edit.${key}`);

const IncidentRequestFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const incidentRequest = IncidentRequestFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(incidentRequest.id)) {
      IncidentRequestFunctions.create(incidentRequest, formikProps);
    } else {
      IncidentRequestFunctions.update(incidentRequest, formikProps, values.submitType);
    }
  },
  prepareValuesToSubmit: values => {
    values.datetime_reported_zone = values.auxiliaryDatetimeReportedZone;
    values.datetime_of_occurrence_zone = values.auxiliaryDatetimeOfOccurrenceZone;

    return IncidentRequestFunctions.applyBackendValuesWhitelist(values);
  },
  create: async (incidentRequest, formikProps) => {
    const { ok, data } = await API.requests.incidentRequests.create({
      incident_request: incidentRequest,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/requests/incident_requests/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.requests.incident_requests.edit.title'));
      formikProps.resetForm({
        values: IncidentRequestFunctions.prepareInitialValues(data.data.attributes),
      });
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (incidentRequest, formikProps, submitType) => {
    const response = await API.requests.incidentRequests[
      submitType === 'save' ? 'update' : submitType
    ](incidentRequest.id, { incident_request: incidentRequest });
    const { ok, data } = response;
    if (ok) {
      formikProps.resetForm({
        values: IncidentRequestFunctions.prepareInitialValues(data.data.attributes),
      });
      showSuccessMessage(t(`flash.success.${snakeCase(submitType)}`));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t(`flash.error.${snakeCase(submitType)}`), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: incidentRequest => ({
    submitType: 'save',
    status: incidentRequest.status,
    subStatus: incidentRequest.sub_status,
    incidentCreation: true,
    auxiliaryDatetimeReportedZone: incidentRequest.datetime_reported_zone,
    auxiliaryDatetimeOfOccurrenceZone: incidentRequest.datetime_of_occurrence_zone,
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'identifier',
    'datetime_reported',
    'datetime_reported_zone',
    'reporting_person_id',
    'datetime_of_occurrence',
    'datetime_of_occurrence_zone',
    'also_notified_person_ids',
    'follow_up_contact',
    'issue_type_id',
    'event_type_id',
    'incident_issue',
    'building_id',
    'buildings_floor_id',
    'lab_id',
    'location_details',
    'assigned_person_id',
    'incident_people_group_id',
    'request_attachments',
    'request_links',
    'reopenable',
    'incident_ids',
  ],
});

export const IncidentRequestForm = withFormik({
  mapPropsToValues: props => IncidentRequestFunctions.prepareInitialValues(props.incidentRequest),
  handleSubmit: IncidentRequestFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
