import yup from '../../../components/form/CustomYupValidator';
import {
  currentDateTime,
  dateFormatter,
  newMoment,
  getConvertedMoment,
} from '../../../services/dateFormatUtils';

const t = (key, params = {}) =>
  I18n.t(`requests.incident_requests.new_edit.validations.${key}`, params);

export const validationSchema = yup.object().shape({
  datetime_reported: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  reporting_person_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  datetime_of_occurrence: yup
    .string()
    .nullable()
    .test(
      'datetime_of_occurrence_cannot_be_later_than_datetime_reported',
      t('datetime_of_occurrence_cannot_be_later_than_datetime_reported'),
      // an arrow function cannot be used beneath because 'this' does not work with it
      // see https://github.com/jquense/yup#mixedtestname-string-message-string--function-test-function-schema
      function(datetimeOfOccurrence) {
        const datetimeReported = this.parent.datetime_reported;

        return datetimeOfOccurrence && datetimeReported
          ? getConvertedMoment(datetimeOfOccurrence).isSameOrBefore(
              getConvertedMoment(datetimeReported),
            )
          : true;
      },
    )
    .test(
      'datetime_of_occurrence_cannot_be_later_than_current_time',
      t('datetime_of_occurrence_cannot_be_later_than_current_time', {
        current_time: dateFormatter.wordy(currentDateTime()),
      }),
      function(datetimeOfOccurrence) {
        return datetimeOfOccurrence
          ? getConvertedMoment(datetimeOfOccurrence).isSameOrBefore(newMoment())
          : true;
      },
    )
    .required(I18n.t('validations.required')),
  incident_issue: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  building_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  submitType: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  issue_type_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  assigned_person_id: yup
    .string()
    .nullable(true)
    .when('submitType', {
      is: submitType => submitType === 'resolveAndCreateIncident',
      then: yup
        .string()
        .nullable(true)
        .required(t('required_to_create_incident')),
    }),
  incident_people_group_id: yup
    .string()
    .nullable(true)
    .when('submitType', {
      is: submitType => submitType === 'resolveAndCreateIncident',
      then: yup
        .string()
        .nullable(true)
        .required(t('required_to_create_incident')),
    }),
  event_type_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});
