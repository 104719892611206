import { withFormik } from 'formik';
import { Form } from './Form';
import { API } from '../../../services/api';
import {
  isDefined,
  showBackendErrorMessage,
  showSuccessMessage,
  produceDefaultValidation,
  updateBreadcrumbs,
  prepareFormFunctions,
} from '../../../services/utils';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`safe_work_authorization_measures.${key}`);

const SwaMeasureFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const measure = SwaMeasureFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(measure.id)) {
      SwaMeasureFunctions.create(measure, formikProps, values.afterSave);
    } else {
      SwaMeasureFunctions.update(measure, formikProps, values.afterSave);
    }
  },
  create: async (measure, formikProps, afterSave) => {
    const {
      resetForm,
      setSubmitting,
      props: { swaId, swa },
    } = formikProps;
    const { ok, data } = await API.requests.safeWorkAuthorizations.measures(swaId).create({
      safe_work_authorization_measure: measure,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/requests/safe_work_authorizations/${swaId}/safe_work_authorization_measures/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.requests.safe_work_authorization_measures.edit.title'));
      resetForm({
        values: SwaMeasureFunctions.prepareInitialValues({
          ...data.data.attributes,
          swa,
        }),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (measure, formikProps, afterSave) => {
    const {
      resetForm,
      setSubmitting,
      props: { swaId, swa },
    } = formikProps;
    const { ok, data } = await API.requests.safeWorkAuthorizations
      .measures(swaId)
      .update(measure.id, { safe_work_authorization_measure: measure });
    if (ok) {
      resetForm({
        values: SwaMeasureFunctions.prepareInitialValues({
          ...data.data.attributes,
          swa,
        }),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: ({ swa }) => ({
    swa: swa,
    swaCompleted: swa.is_completed,
    swaId: swa.id,
    swaIdentifier: swa.identifier,
    swaStartDate: swa.start_date,
    swaEndDate: swa.end_date,
    swaStartTime: swa.start_time,
    swaEndTime: swa.end_time,
    swaWeekdays: swa.weekdays,
    swaSaturdays: swa.saturdays,
    swaSundays: swa.sundays,
    swaCustomDatesAndTimes: swa.custom_dates_and_times,
    afterSave: () => {},
    showFillFormCorrectlyFlash: false,
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'identifier',
    'safe_work_authorization_measure_type_id',
    'responsible_person_id',
    'use_swa_dates_and_times',
    'start_date',
    'end_date',
    'start_time',
    'end_time',
    'weekdays',
    'saturdays',
    'sundays',
    'custom_dates_and_times',
    'swa_measure_date_time_entries_attributes',
    'attachments',
    'links',
    'comments',
  ],
});

export const SafeWorkAuthorizationMeasureForm = withFormik({
  mapPropsToValues: props => SwaMeasureFunctions.prepareInitialValues({
    ...props.swaMeasure,
    swa: props.swa,
  }),
  handleSubmit: SwaMeasureFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
