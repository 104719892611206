import React, { useState } from 'react';
import styled from 'styled-components';
import { FieldArray } from 'formik';
import { colors } from '../../../../assets/styles/colors';
import { fontSize, fontWeights } from '../../../../assets/styles/typography';
import { NewCategorySectionBtn } from './components/NewCategorySectionBtn';
import { CategorySectionContent } from './components/CategorySectionContent';
import { DeletionLink } from '../../../../components/links/DeletionLink';

export const CategorySectionContainer = styled.div`
  background: white;
  border-radius: 4px;
  border: 1px solid ${colors.sectionSeparatorColor};
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const CategorySectionHeader = styled.div`
  padding-bottom: 0.5rem;
  display: flex;
  flex-wrap: wrap;
`;

export const CategorySectionTitle = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100px;
  font-size: ${fontSize.textMd};
  font-weight: ${fontWeights.headings};
`;

export const RemoveCategoryLink = ({ onSubmit, hidden, translation }) => (
  <DeletionLink onSubmit={onSubmit} hidden={hidden} modelName={translation('section')}>
    {translation('remove_section')}
  </DeletionLink>
);

export const ChecklistBuilder = props => {
  const { inspectionFindingDefinitionsPerCategory } = props.values;
  const [hoveredCategoryIndex, setHoveredCategoryIndex] = useState(null);

  const removeCategory = (arrayHelpers, index) => {
    arrayHelpers.remove(index);
  };

  return (
    <FieldArray
      name="inspectionFindingDefinitionsPerCategory"
      render={arrayHelpers =>
        inspectionFindingDefinitionsPerCategory?.length > 0 ? (
          <>
            {inspectionFindingDefinitionsPerCategory.map((category, index) => (
              <CategorySectionContainer
                onMouseOver={() => {
                  setHoveredCategoryIndex(index);
                }}
                onMouseLeave={() => {
                  setHoveredCategoryIndex(null);
                }}
              >
                <CategorySectionHeader>
                  <CategorySectionTitle>{category.category_name}</CategorySectionTitle>
                  <RemoveCategoryLink
                    onSubmit={() => {
                      removeCategory(arrayHelpers, index);
                    }}
                    hidden={index !== hoveredCategoryIndex}
                    translation={key =>
                      I18n.t(`administration.inspection_types.new_edit.checklist.${key}`)
                    }
                  />
                </CategorySectionHeader>
                <CategorySectionContent
                  category={category}
                  categoryIndex={index}
                  arrayHelpers={arrayHelpers}
                  {...props}
                />
              </CategorySectionContainer>
            ))}
            <NewCategorySectionBtn arrayHelpers={arrayHelpers} {...props} />
          </>
        ) : (
          <NewCategorySectionBtn arrayHelpers={arrayHelpers} {...props} />
        )
      }
    />
  );
};
