import React, { useState, useEffect } from 'react';
import { isDefined } from '../../services/utils';
import { API } from '../../services/api';
import { Loader } from '../../components/Loader';
import { EquipmentForm } from './Form';

export const Equipment = props => {
  const [fetching, setFetching] = useState(true);
  const [equipment, setEquipment] = useState({});

  useEffect(() => {
    (async () => {
      const api = isDefined(props.id) ? API.equipment.equipment.show : API.equipment.equipment.new;
      const { ok, data } = await api(props.id);
      if (ok) {
        await setEquipment(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <EquipmentForm {...props} equipment={equipment} />;
};

export default Equipment;
