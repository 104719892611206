import React from 'react';
import { FastField } from 'formik';
import { defaultShouldUpdate } from '../../../../services/fieldUtils';
import { DefaultAttributesGroup } from '../../../../components/form/DefaultAttributesGroup';
import { ModalSection } from '../../../../components/modals/ModalSection';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { FormFooter, FormFooterRightPart } from '../../../../components/form/FormFooter';
import { defaultHandleSubmit, isDefined } from '../../../../services/utils';
import { CancellationLink } from '../../../../components/links/CancellationLink';
import { BrandButton } from '../../../../components/buttons/BrandButton';
import { DateTimeField } from '../../../../components/fields/DateTimeField';
import { NumericAndUnitFields } from '../../../../components/fields/compositeFields/NumericAndUnitFields';
import { useRemainingValues } from './useRemainingValues';

const t = key => I18n.t(`radiation_safety.rad_inventory_items.new_edit.modals.withdrawal.${key}`);

export const Form = props => {
  const {
    isSubmitting,
    setFieldValue,
    handleSubmit,
    modalRef,
    values: { activity_quantity_ratio },
  } = props;

  useRemainingValues(props);

  return (
    <form>
      <ModalSection>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            labelI18nKeyPrefix: 'activerecord.attributes.rad_withdrawal',
            shouldUpdate: defaultShouldUpdate,
            resourceName: I18n.t('resource_names.rad_withdrawal'),
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
          }}
        >
          <FastField name="withdrawn_at" required component={DateTimeField} />
          <FastField
            name="remainingActivity"
            disabled
            component={NumericAndUnitFields}
            unitName="activity_unit_id"
            label={t('additional_fields.remaining_activity')}
            unitApiParams={{ um_type: ['radioactivity'] }}
          />
          <FastField
            name="remainingQuantity"
            disabled
            component={NumericAndUnitFields}
            unitName="quantity_unit_id"
            label={t('additional_fields.remaining_quantity')}
            unitApiParams={{ um_type: ['mass'] }}
          />
          <FastField
            name="activity"
            disabled
            component={NumericAndUnitFields}
            unitName="activity_unit_id"
            unitApiParams={{ um_type: ['radioactivity'] }}
          />
          <FastField
            name="quantity"
            required
            onChange={async (event, onChange) => {
              await onChange(event);
              const newValue = event.target.value;
              await setFieldValue(
                'activity',
                isDefined(newValue) && isDefined(activity_quantity_ratio)
                  ? newValue * activity_quantity_ratio
                  : '',
              );
            }}
            component={NumericAndUnitFields}
            unitName="quantity_unit_id"
            unitProps={{ required: false, disabled: true, onChange: undefined }}
            unitApiParams={{ um_type: ['mass'] }}
          />
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={() => modalRef.current.hide()} />
            <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
              {t('save')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </ModalSection>
    </form>
  );
};
