import React from 'react';
import { isDefined } from '../../../services/utils';
import { FormHeader } from '../../../components/form/FormHeader';
import { Overview } from './Overview';
import { Footer } from './Footer';
import { RadInventoryItemsTable } from './RadInventoryItemsTable';
import { RadPurchasesTable } from './RadPurchasesTable';
import { RadLicenseItemAssignments } from './RadLicenseItemAssignments/RadLicenseItemAssignments';
import { RadUsers } from './RadUsers';

const t = key => I18n.t(`radiation_safety.rad_permits.new_edit.${key}`);

export const Form = props => {
  const {
    values: { id },
  } = props;
  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)}
        auditsLink={isDefined(id) ? `/radiation_safety/rad_permits/${id}/audits` : ''}
      />
      <Overview {...props} />
      <Footer {...props} />
      <RadLicenseItemAssignments {...props} />
      <RadUsers {...props} />
      <RadInventoryItemsTable {...props} />
      <RadPurchasesTable {...props} />
    </form>
  );
};
