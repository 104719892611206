import React from 'react';
import styled from 'styled-components';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { colors } from '../../assets/styles/colors';

const Wrapper = styled.li`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &:not(:first-child) {
    border-top: 1px solid ${colors.lightGray};
  }
  ${props => (props.linkInside ? '' : 'padding: 0 1.25rem 0.75rem 0.5rem;')}
  cursor: default;
`;

const Link = styled.a`
  padding: 0 1.25rem 0.75rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  color: inherit;
  width: 100%;
  &:not(:first-child) {
    border-top: 1px solid ${colors.lightGray};
  }
  &:hover {
    color: inherit;
    text-decoration: none;
    background: ${colors.hoverBackground};
    cursor: pointer;
  }
`;

export const DraggableIconSpan = styled.div`
  height: 100%;
  padding: 1rem 0.5rem 0 0;
  &:first-child {
    font-weight: '100';
    color: ${colors.lightGray};
  }
  &:hover {
    cursor: pointer;
    &:first-child {
      color: ${colors.listButtonFontColor};
    }
  }
`;

const RowContent = ({ keyValue, href, children, setActivatorNodeRef, listeners }) => {
  const draggableIcon = (
    <DraggableIconSpan ref={setActivatorNodeRef} {...listeners}>
      <i className="fas fa-bars" />
    </DraggableIconSpan>
  );

  if (href) {
    return (
      <Link id={`${keyValue}-row-link`} href={href}>
        {draggableIcon}
        {children}
      </Link>
    );
  }

  return (
    <>
      {draggableIcon}
      {children}
    </>
  );
};

export const SortableSimpleListRow = ({ id, children, keyValue, href }) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  let style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if (isDragging) {
    style = {
      borderTop: '0',
      background: colors.white,
      position: 'relative',
      zIndex: '9999',
      boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.15)',
      ...style,
    };
  }

  return (
    <Wrapper
      id={`${keyValue}-li`}
      key={keyValue}
      linkInside={href}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <RowContent
        keyValue={keyValue}
        href={href}
        setActivatorNodeRef={setActivatorNodeRef}
        listeners={listeners}
      >
        {children}
      </RowContent>
    </Wrapper>
  );
};
