import { withFormik } from 'formik';
import { API } from '../../../../services/api';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { LogoProfileForm } from './Form';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`hsds.logo_profiles.${key}`);

const LogoProfileFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const logoProfile = LogoProfileFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(logoProfile.id)) {
      LogoProfileFunctions.create(logoProfile, formikProps);
    } else {
      LogoProfileFunctions.update(logoProfile, formikProps);
    }
  },
  create: async (logoProfile, formikProps) => {
    const { ok, data } = await API.spaceRiskAssessments.logoProfiles.create(logoProfile);
    if (ok) {
      formikProps.resetForm({
        values: LogoProfileFunctions.prepareInitialValues(data.data.attributes),
      });
      formikProps.props.hide();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (logoProfile, formikProps) => {
    const { ok, data } = await API.spaceRiskAssessments.logoProfiles.update(
      logoProfile.id,
      logoProfile,
    );
    if (ok) {
      formikProps.resetForm({
        values: LogoProfileFunctions.prepareInitialValues(data.data.attributes),
      });
      formikProps.props.hide();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage('flash.error.save', data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: () => ({}),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'name',
    'primary_logo_attachment',
    'secondary_logo_attachment',
  ],
});

export const LogoProfileModalContent = withFormik({
  mapPropsToValues: props => LogoProfileFunctions.prepareInitialValues(props.logoProfile),
  handleSubmit: LogoProfileFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(LogoProfileForm);
