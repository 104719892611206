import { useEffect } from 'react';
import { some } from 'lodash';
import { isDefined, saluteRound } from '../../../../services/utils';
import { applyHalfLife } from '../../utils';

const calcRemainingValue = (baseValue, halfLifeDays, startedAt, withdrawnValue, withdrawnAt) => {
  if (
    !isDefined(baseValue) ||
    !isDefined(startedAt) ||
    !isDefined(withdrawnValue) ||
    !isDefined(withdrawnAt)
  )
    return '';

  if (halfLifeDays === 0) return 0.0;
  if (!isDefined(halfLifeDays)) return saluteRound(baseValue - withdrawnValue);

  let result = applyHalfLife(baseValue, halfLifeDays, startedAt, withdrawnAt);
  result -= withdrawnValue;
  return applyHalfLife(result, halfLifeDays, withdrawnAt);
};

export const useRemainingValues = props => {
  const {
    setFieldValue,
    errors,
    values: {
      withdrawn_at,
      activity,
      quantity,
      item_half_life_days,
      item_last_limits_related_change_at,
      item_activity_after_last_change,
      item_quantity_after_last_change,
    },
  } = props;

  const errorKeys = Object.keys(errors);

  useEffect(() => {
    setFieldValue(
      'remainingActivity',
      some(['activity', 'withdrawn_at'], key => errorKeys.includes(key))
        ? ''
        : calcRemainingValue(
            item_activity_after_last_change,
            item_half_life_days,
            item_last_limits_related_change_at,
            activity,
            withdrawn_at,
          ),
    );
  }, [
    item_activity_after_last_change,
    item_half_life_days,
    item_last_limits_related_change_at,
    activity,
    withdrawn_at,
    JSON.stringify(errorKeys),
  ]);
  useEffect(() => {
    setFieldValue(
      'remainingQuantity',
      some(['quantity', 'withdrawn_at'], key => errorKeys.includes(key))
        ? ''
        : calcRemainingValue(
            item_quantity_after_last_change,
            item_half_life_days,
            item_last_limits_related_change_at,
            quantity,
            withdrawn_at,
          ),
    );
  }, [
    item_quantity_after_last_change,
    item_half_life_days,
    item_last_limits_related_change_at,
    quantity,
    withdrawn_at,
    JSON.stringify(errorKeys),
  ]);
};
