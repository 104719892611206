import React, { useRef, useState } from 'react';
import { FormSection } from '../../../../components/form/FormSection';
import { FormSectionHeader } from '../../../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../../../components/form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../../../components/form/FormSectionHeaderLinks';
import { AsyncSimpleList } from '../../../../components/lists/AsyncSimpleList';
import { Modal } from '../../../../components/modals/Modal';
import { API } from '../../../../services/api';
import {
  SimpleListDeletionLink,
  SimpleListLink,
} from '../../../../components/lists/SimpleListLinks';
import {
  handleSubmitWithAfterSave,
  isDefined,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { AddWasteChemicalItemCandidateFieldDefinitionModalContent } from './index';
import { EmptyListPlaceholder } from '../../../../components/lists/EmptyListPlaceholder';

const t = key => I18n.t(`waste_collection_types.${key}`);

export const WasteChemicalItemCandidateFieldDefinitions = props => {
  const {
    isSubmitting,
    setFieldValue,
    handleSubmit,
    values: { id },
  } = props;

  const addWasteChemicalItemCandidateFieldDefinitionModalRef = useRef(null);
  const listRef = useRef(null);
  const [
    wasteChemicalItemCandidateFieldDefinition,
    setWasteChemicalItemCandidateFieldDefinition,
  ] = useState({});

  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>
            {t('lists.waste_chemical_item_candidate_field_definitions.title')}
          </FormSectionTitle>
          <FormSectionHeaderLink
            disabled={isSubmitting}
            onClick={async () => {
              await handleSubmitWithAfterSave(
                setFieldValue,
                handleSubmit,
                addWasteChemicalItemCandidateFieldDefinitionModalRef.current.open,
              );
              setWasteChemicalItemCandidateFieldDefinition({});
            }}
          >
            {t(
              'lists.waste_chemical_item_candidate_field_definitions.add_waste_chemical_item_candidate_field_definition',
            )}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {!isDefined(id) && <EmptyListPlaceholder />}
        {isDefined(id) && (
          <AsyncSimpleList
            api={API.administration.wasteChemicalItemCandidateFieldDefinitions(id).index}
            ref={listRef}
            dataKey="data"
            resourceName={I18n.t('resource_names.waste_chemical_item_candidate_field_definition')}
            isSortable
            reorderItemApi={
              API.administration.wasteChemicalItemCandidateFieldDefinitions(id).reorder
            }
            mapData={({ attributes }) => [
              [
                t('lists.waste_chemical_item_candidate_field_definitions.variant'),
                I18n.t(
                  `activerecord.attributes.waste_chemical_item_candidate_field_definition.variants.${attributes.variant}`,
                ),
              ],
              [t('lists.waste_chemical_item_candidate_field_definitions.name'), attributes.name],
              [
                t('lists.waste_chemical_item_candidate_field_definitions.placeholder'),
                attributes.placeholder,
              ],
              [
                t('lists.waste_chemical_item_candidate_field_definitions.tooltip'),
                attributes.tooltip,
              ],
              <SimpleListLink
                key="edit"
                disabled={isSubmitting}
                onClick={async () => {
                  const {
                    ok,
                    data,
                  } = await API.administration
                    .wasteChemicalItemCandidateFieldDefinitions(id)
                    .show(attributes.id);
                  if (ok) {
                    await setWasteChemicalItemCandidateFieldDefinition(data.data.attributes);
                    await handleSubmitWithAfterSave(
                      setFieldValue,
                      handleSubmit,
                      addWasteChemicalItemCandidateFieldDefinitionModalRef.current.open,
                    );
                  } else {
                    showBackendErrorMessage(
                      t('lists.waste_chemical_item_candidate_field_definitions.remove.flash.error'),
                      data,
                    );
                  }
                }}
              >
                {t('lists.waste_chemical_item_candidate_field_definitions.edit')}
              </SimpleListLink>,
              <SimpleListDeletionLink
                key="delete"
                modelName={t('lists.waste_chemical_item_candidate_field_definitions.model_name')}
                onSubmit={async () => {
                  const {
                    ok,
                    data,
                  } = await API.administration
                    .wasteChemicalItemCandidateFieldDefinitions(id)
                    .destroy(attributes.id);
                  if (ok) {
                    listRef.current.refresh();
                    showSuccessMessage(
                      t(
                        'lists.waste_chemical_item_candidate_field_definitions.remove.flash.success',
                      ),
                    );
                  } else {
                    showBackendErrorMessage(
                      t('lists.waste_chemical_item_candidate_field_definitions.remove.flash.error'),
                      data,
                    );
                  }
                }}
              >
                {t('lists.waste_chemical_item_candidate_field_definitions.remove.title')}
              </SimpleListDeletionLink>,
            ]}
          />
        )}
      </FormSection>
      <Modal
        title={t('modals.add_waste_chemical_item_candidate_field_definition.title')}
        ref={addWasteChemicalItemCandidateFieldDefinitionModalRef}
      >
        {modalProps => (
          <AddWasteChemicalItemCandidateFieldDefinitionModalContent
            {...modalProps}
            {...props}
            wasteChemicalItemCandidateTypeId={id}
            wasteChemicalItemCandidateFieldDefinition={wasteChemicalItemCandidateFieldDefinition}
            listRef={listRef}
          />
        )}
      </Modal>
    </>
  );
};
