import React from 'react';
import { FastField } from 'formik';
import { ModalSection } from '../../../../components/modals/ModalSection';
import { FormFooter, FormFooterRightPart } from '../../../../components/form/FormFooter';
import { CancellationLink } from '../../../../components/links/CancellationLink';
import { BrandButton } from '../../../../components/buttons/BrandButton';
import { defaultHandleSubmit } from '../../../../services/utils';
import { DefaultAttributesGroup } from '../../../../components/form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { defaultShouldUpdate, mapToSelectorOptions } from '../../../../services/fieldUtils';
import { SelectorField } from '../../../../components/fields/SelectorField';
import { TextField } from '../../../../components/fields/TextField';
import { TextAreaField } from '../../../../components/fields/TextAreaField';

const t = (key, props = {}) =>
  I18n.t(
    `waste_collection_types.modals.add_waste_chemical_item_candidate_field_definition.${key}`,
    props,
  );

export const Form = props => {
  const { hide, isSubmitting, setFieldValue, handleSubmit, values } = props;
  return (
    <ModalSection>
      <form>
        <DefaultAttributesGroup
          defaultChildrenAttributes={{
            displayMode: fieldDisplayModes.WIDE_FORM_ROW,
            labelI18nKeyPrefix:
              'activerecord.attributes.waste_chemical_item_candidate_field_definition',
            shouldUpdate: defaultShouldUpdate,
            resourceName: I18n.t('resource_names.waste_chemical_item_candidate_field_definition'),
          }}
        >
          <FastField
            required
            name="variant"
            options={mapToSelectorOptions(props.enums.variant, 'header', 'value')}
            component={SelectorField}
          />
          <FastField
            required
            name="name"
            component={TextField}
            placeholder={t('fields.placeholders.name')}
          />
          <FastField
            name="placeholder"
            hidden={values.variant === 'boolean'}
            component={TextField}
            placeholder={t('fields.placeholders.placeholder')}
          />
          <FastField
            required
            hidden={values.variant !== 'selector'}
            name="selector_values"
            component={TextAreaField}
            placeholder={t('fields.placeholders.selector_values')}
          />
          <FastField
            name="tooltip"
            component={TextField}
            placeholder={t('fields.placeholders.tooltip')}
          />
        </DefaultAttributesGroup>
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterRightPart>
            <CancellationLink onClick={hide} />
            <BrandButton onClick={e => defaultHandleSubmit(setFieldValue, handleSubmit, e)}>
              {t('save')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
      </form>
    </ModalSection>
  );
};
