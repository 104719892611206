import { withFormik } from 'formik';
import {
  isDefined,
  prepareCustomFieldsAnswers,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { Form } from './Form';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`requests.waste_collection_candidates.new_edit.${key}`);

const WasteCollectionCandidateFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const wasteCollection = WasteCollectionCandidateFunctions.prepareValuesToSubmit({ ...values });
    if (isDefined(wasteCollection.id)) {
      WasteCollectionCandidateFunctions.update(wasteCollection, values, formikProps);
    } else {
      WasteCollectionCandidateFunctions.create(wasteCollection, values, formikProps);
    }
  },
  create: async (wasteCollection, values, formikProps) => {
    const { ok, data } = await API.requests.wasteRequests
      .wasteCollectionCandidates(formikProps.props.wasteRequestId)
      .create({
        waste_collection_candidate: wasteCollection,
        create_collection: values.createCollection,
      });
    WasteCollectionCandidateFunctions.handleApiResponse(ok, data, values, formikProps);
  },
  update: async (wasteCollection, values, formikProps) => {
    const { ok, data } = await API.requests.wasteRequests
      .wasteCollectionCandidates(formikProps.props.wasteRequestId)
      .update(wasteCollection.id, {
        waste_collection_candidate: wasteCollection,
        create_collection: values.createCollection,
      });
    WasteCollectionCandidateFunctions.handleApiResponse(ok, data, values, formikProps);
  },
  handleApiResponse: (ok, data, values, formikProps) => {
    if (ok) {
      formikProps.resetForm({
        values: WasteCollectionCandidateFunctions.prepareInitialValues(data.data.attributes),
      });
      values.afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: {
    afterSave: () => {},
    createCollection: false,
  },
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'identifier',
    'waste_collection_type_id',
    'waste_chemical_name_candidate',
    'container_qty',
    'container_size',
    'container_size_um_id',
    'responsible_person_id',
    'waste_chemical_name_id',
    'include_waste_types_sp',
    'waste_collection_type_name',
    'waste_chemical_item_candidate_field_definitions',
    'waste_chemical_item_candidate_field_answers_attributes',
    'chemical_item_created',
    'candidate_edited',
  ],
  preprocessBackendValues: backendValues => ({
    ...backendValues,
    waste_chemical_item_candidate_field_answers_attributes: prepareCustomFieldsAnswers(
      'waste_chemical_item_candidate',
      backendValues.waste_chemical_item_candidate_field_definitions,
      backendValues.waste_chemical_item_candidate_field_answers_attributes,
    ),
  }),
});

export const WasteCollectionCandidateForm = withFormik({
  validate: produceDefaultValidation(validationSchema),
  mapPropsToValues: props =>
    WasteCollectionCandidateFunctions.prepareInitialValues(props.candidate),
  handleSubmit: WasteCollectionCandidateFunctions.handleSubmit,
})(Form);
