import { isEmpty } from 'lodash';
import { withFormik } from 'formik';
import { Form } from './Form';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { API } from '../../../../services/api';
import { validationSchema } from './validationSchema';

const t = (key, props = {}) =>
  I18n.t(`equipment_types.modals.add_equipment_field_definition.${key}`, props);

export const EquipmentFieldDefinitionsFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const equipmentFieldDefinition = EquipmentFieldDefinitionsFunctions.prepareValuesToSubmit(
      values,
    );
    if (!isDefined(equipmentFieldDefinition.id)) {
      EquipmentFieldDefinitionsFunctions.create(
        formikProps.props.equipmentTypeId,
        equipmentFieldDefinition,
        formikProps,
      );
    } else {
      EquipmentFieldDefinitionsFunctions.update(
        formikProps.props.equipmentTypeId,
        equipmentFieldDefinition,
        formikProps,
      );
    }
  },
  create: async (equipmentTypeId, values, formikProps) => {
    const { hide, listRef } = formikProps.props;
    const api = API.administration.equipmentFieldDefinitions;
    const { ok, data } = await api(equipmentTypeId).create({
      equipment_field_definition: values,
    });
    if (ok) {
      formikProps.resetForm();
      hide();
      listRef.current.refresh();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (equipmentTypeId, equipmentFieldDefinition, formikProps) => {
    const api = API.administration.equipmentFieldDefinitions;
    const { ok, data } = await api(equipmentTypeId).update(equipmentFieldDefinition.id, {
      equipment_field_definition: equipmentFieldDefinition,
    });
    if (ok) {
      formikProps.resetForm();
      formikProps.props.hide();
      formikProps.props.listRef.current.refresh();
      showSuccessMessage(t('flash.success.update'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.update'), data);
    }
  },
  joinSelectorValues: selectorValues => (isEmpty(selectorValues) ? null : selectorValues.join(';')),
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: {},
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'name',
    'tooltip',
    'placeholder',
    'variant',
    'selector_values',
  ],
  preprocessBackendValues: backendValues => ({
    ...backendValues,
    selector_values: EquipmentFieldDefinitionsFunctions.joinSelectorValues(
      backendValues.selector_values,
    ),
  }),
});

export const AddEquipmentFieldDefinitionModalContent = withFormik({
  validate: values => produceDefaultValidation(validationSchema)(values),
  mapPropsToValues: props =>
    EquipmentFieldDefinitionsFunctions.prepareInitialValues(props.equipmentFieldDefinition),
  handleSubmit: EquipmentFieldDefinitionsFunctions.handleSubmit,
})(Form);
