import { withFormik } from 'formik';
import { Form } from './Form';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`biological_agent_types.new_edit.${key}`);

const BiologicalAgentTypeFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const biologicalAgentType = BiologicalAgentTypeFunctions.prepareValuesToSubmit({ ...values });
    if (isDefined(biologicalAgentType.id)) {
      BiologicalAgentTypeFunctions.update(biologicalAgentType, formikProps, values.afterSave);
    } else {
      BiologicalAgentTypeFunctions.create(biologicalAgentType, formikProps, values.afterSave);
    }
  },
  create: async (biologicalAgentType, formikProps, afterSave) => {
    const { ok, data } = await API.administration.biologicalAgentTypes.create({
      biological_agent_type: biologicalAgentType,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/administration/biological_agent_types/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.administration.biological_agent_types.edit.title'));
      formikProps.resetForm({
        values: BiologicalAgentTypeFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (biologicalAgentType, formikProps, afterSave) => {
    const { ok, data } = await API.administration.biologicalAgentTypes.update(
      biologicalAgentType.id,
      { biological_agent_type: biologicalAgentType },
    );
    if (ok) {
      formikProps.resetForm({
        values: BiologicalAgentTypeFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t(`flash.success.save`));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t(`flash.error.save`), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: {
    afterSave: () => {},
  },
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'identifier',
    'name',
  ],
});

export const BiologicalAgentTypeForm = withFormik({
  validate: produceDefaultValidation(validationSchema),
  mapPropsToValues: props =>
    BiologicalAgentTypeFunctions.prepareInitialValues(props.biologicalAgentType),
  handleSubmit: BiologicalAgentTypeFunctions.handleSubmit,
})(Form);
