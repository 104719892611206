import React from 'react';
import styled from 'styled-components';
import { FieldWrapper } from './auxiliary/FieldWrapper';
import { fieldPropDefaultTransformation, fieldWrapperProps } from '../../services/fieldUtils';

const OptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const OptionWrapper = styled.div`
  margin-right: 15px;
`;

const OptionLabel = styled.label`
  padding-left: 5px;
`;

export const RadioGroupField = props => {
  const {
    field: { name, value },
    disabled,
    options,
    optionI18nKeyPrefix,
    resourceName,
    id,
  } = props;
  const inputId = id || `${resourceName}-${name}-${optionValue}`;
  const translateOption = optionValue =>
    optionI18nKeyPrefix ? I18n.t(`${optionI18nKeyPrefix}.${optionValue}`) : optionValue;
  return (
    <FieldWrapper {...fieldWrapperProps({ inputId, ...props })}>
      <OptionsWrapper>
        {options.map(optionValue => (
          <OptionWrapper key={`radio-option-${optionValue}`}>
            <input
              {...fieldPropDefaultTransformation(props)}
              id={inputId}
              type="radio"
              value={optionValue}
              checked={optionValue === value}
              disabled={disabled}
            />
            <OptionLabel htmlFor={`${resourceName}-${name}-${optionValue}`}>
              {translateOption(optionValue)}
            </OptionLabel>
          </OptionWrapper>
        ))}
      </OptionsWrapper>
    </FieldWrapper>
  );
};
