import { useEffect } from 'react';
import { some } from 'lodash';
import { applyHalfLife } from '../utils';

export const useRemainingValues = props => {
  const {
    setFieldValue,
    errors,
    values: {
      half_life_days,
      remainingActivityBase,
      remainingQuantityBase,
      remainingValuesDecayStartAt,
    },
  } = props;

  const errorKeys = Object.keys(errors);

  useEffect(() => {
    setFieldValue(
      'remainingActivity',
      some(['started_at', 'half_life_days', 'initial_activity'], key => errorKeys.includes(key))
        ? ''
        : applyHalfLife(remainingActivityBase, half_life_days, remainingValuesDecayStartAt),
    );
  }, [
    remainingActivityBase,
    half_life_days,
    remainingValuesDecayStartAt,
    JSON.stringify(errorKeys),
  ]);
  useEffect(() => {
    setFieldValue(
      'remainingQuantity',
      some(['started_at', 'half_life_days', 'initial_quantity'], key => errorKeys.includes(key))
        ? ''
        : applyHalfLife(remainingQuantityBase, half_life_days, remainingValuesDecayStartAt),
    );
  }, [
    remainingQuantityBase,
    half_life_days,
    remainingValuesDecayStartAt,
    JSON.stringify(errorKeys),
  ]);
};
