import React, { PureComponent } from 'react';
import { FieldArray } from 'formik';
import ReactS3Uploader from 'react-s3-uploader';
import _ from 'lodash';
import styled from 'styled-components';
import { FieldWrapper } from './auxiliary/FieldWrapper';
import { fontSize } from '../../assets/styles/typography';
import { colors } from '../../assets/styles/colors';
import { fieldWrapperProps } from '../../services/fieldUtils';
import { UploadIcon } from '../../../stateless_components/icons/UploadIcon';
import { ErrorMessage } from '../../../stateless_components/inputs/elements/ErrorMessage';
import { ProgressBar } from '../../../stateless_components/other/ProgressBar';
import { DownloadIcon } from '../../../stateless_components/icons/DownloadIcon';
import { bytesToMb } from '../../../services/fileSizeHelper';

export const FilesFieldContainer = styled.div`
  width: 100%;
`;

export const StyledAttachmentNameSpan = styled.span`
  width: 100%;
  vertical-align: middle;
  display: inline-block;
  padding-right: 20px;
`;

export const StyledAttachmentLink = styled.a`
  color: ${colors.download};
  min-width: 80px;
`;

export const StyledRemoveAttachmentButton = styled.button`
  background: transparent;
  border: 0;
  padding: 0px 7px;
  margin-left: 15px;
  color: ${colors.delete};
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledAttachmentRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 3px;
  padding-bottom: 10px;
  width: 100%;
`;

export const StyledAttachmentsTable = styled.div`
  font-size: ${fontSize.textXsm};
  flex-direction: column;
  width: 100%;
`;

export const StyledMockUploadButton = styled.div`
  padding: 0.25rem 1rem;
  border: 1px solid ${colors.inputBorderColor};
  border-radius: 0.2rem;
  cursor: pointer;
  &:focus,
  &:hover {
    background: ${colors.inputBorderColor};
  }
`;

export const StyledMockUploadButtonTitle = styled.span`
  padding-left: 10px;
`;

const AttachmentRow = ({ id, pathString, filename, removeAttachment }) => (
  <StyledAttachmentRow>
    <StyledAttachmentNameSpan id={`${id}-name`}>{filename}</StyledAttachmentNameSpan>
    <StyledAttachmentLink id={`${id}-link`} download={filename} href={pathString}>
      <DownloadIcon>{I18n.t('file_uploader.download_file')}</DownloadIcon>
    </StyledAttachmentLink>
    <StyledRemoveAttachmentButton id={`${id}-remove-button`} onClick={removeAttachment}>
      {I18n.t('file_uploader.delete_file')}
    </StyledRemoveAttachmentButton>
  </StyledAttachmentRow>
);

export class FilesField extends PureComponent {
  constructor(props) {
    super(props);
    this.maxFileSizeInMb = I18n.t('config.max_attachment_size_in_mb');
    this.state = {
      showUploadButton: true,
      progressValue: 0,
      errorOccurred: false,
      error: '',
      showProgressBar: false,
    };
  }

  defaultOnChange = async e => {
    const { value, arrayHelpers, arrayHelpersMethod } = e;
    await arrayHelpers[arrayHelpersMethod](value);
    this.fileRef.value = null;
  };

  onUploadStart = (file, callback) => {
    if (bytesToMb(file.size) > this.maxFileSizeInMb) {
      this.setOnSizeExceededError();
    } else {
      callback(file);
      this.setState({
        errorOccurred: false,
        error: '',
        showUploadButton: false,
        progressValue: 0,
        showProgressBar: true,
      });
    }
  };

  onUploadProgress = progressValue => {
    this.setState({ progressValue });
  };

  setOnSizeExceededError = () => {
    this.setState({
      showProgressBar: false,
      errorOccurred: true,
      error: `${I18n.t('file_uploader.errors.too_large_file')}${this.maxFileSizeInMb}${I18n.t('file_uploader.errors.too_large_file_unit')}`,
    });
  };

  onUploadError = () => {
    this.setState({
      showUploadButton: true,
      showProgressBar: true,
      errorOccurred: true,
      error: I18n.t('file_uploader.errors.supported_extensions_info'),
    });
  };

  onUploadSuccess = (signedUrl, arrayHelpers) => {
    const { s3Secure, onChange } = this.props;
    let downloadPath = signedUrl.signedUrl.split('?')[0];
    if (s3Secure) {
      downloadPath = { url: downloadPath };
    }
    this.setState({
      showUploadButton: true,
      progressValue: 0,
      showProgressBar: false,
    });
    const event = {
      value: downloadPath,
      arrayHelpers,
      arrayHelpersMethod: 'push',
    };
    onChange ? onChange(event, this.defaultOnChange.bind(this)) : this.defaultOnChange(event);
  };

  setInputRef = ref => {
    this.fileRef = ref;
  };

  prepareAttachmentsData() {
    const {
      field: { value },
      s3Secure,
    } = this.props;

    const initialCollection = s3Secure ? value.map(attachment => attachment.url) : value;

    return initialCollection.map((pathString, valueIndex) => {
      const path = new URL(pathString).pathname.split('/');
      const filename = decodeURIComponent(_.last(path));
      return { pathString, filename, valueIndex };
    });
  }

  renderAttachments(arrayHelpers, fieldId) {
    const {
      field: { name },
      disabled,
      onChange,
      sortPredicate,
    } = this.props;

    let attachments = this.prepareAttachmentsData();
    if (sortPredicate) attachments = attachments.sort(sortPredicate);

    return (
      <FilesFieldContainer>
        <StyledAttachmentsTable>
          {attachments.map(({ pathString, filename, valueIndex }) => (
            <AttachmentRow
              pathString={pathString}
              filename={filename}
              id={`${fieldId}[${valueIndex}]`}
              name={`${name}[${valueIndex}]`}
              key={pathString}
              removeAttachment={() => {
                const event = {
                  value: valueIndex,
                  arrayHelpers,
                  arrayHelpersMethod: 'remove',
                  pathString,
                  filename,
                };
                onChange ? onChange(event, this.defaultOnChange.bind(this)) : this.defaultOnChange(event);
              }}
            />
          ))}
        </StyledAttachmentsTable>
        {!disabled && (
          <>
            <ReactS3Uploader
              id={fieldId}
              signingUrl="/file_upload"
              signingUrlMethod="GET"
              preprocess={this.onUploadStart}
              onProgress={this.onUploadProgress}
              onError={this.onUploadError}
              onFinish={signedUrl => this.onUploadSuccess(signedUrl, arrayHelpers)}
              autoUpload
              contentDisposition="attachment"
              scrubFilename={filename => encodeURIComponent(filename.trim())}
              hidden
              inputRef={this.setInputRef}
            />
            <ProgressBar
              progressValue={this.state.progressValue}
              hidden={!this.state.showProgressBar}
              progressText={I18n.t('file_uploader.progress_text')}
              errorOccurred={this.state.errorOccurred}
            />
            <ErrorMessage hidden={!this.state.errorOccurred}>{this.state.error}</ErrorMessage>
            <label htmlFor={fieldId} hidden={!this.state.showUploadButton}>
              <StyledMockUploadButton>
                <UploadIcon />
                <StyledMockUploadButtonTitle>
                  {I18n.t('file_uploader.upload_file')}
                </StyledMockUploadButtonTitle>
              </StyledMockUploadButton>
            </label>
            <ProgressBar
              progressValue={this.state.progressValue}
              hidden={!this.state.uploadPending}
              progressText={I18n.t('file_uploader.progress_text')}
              errorOccurred={this.state.uploadingErrorOccurred}
            />
          </>
        )}
      </FilesFieldContainer>
    );
  }

  render() {
    const {
      field: { name },
      resourceName,
      id,
    } = this.props;
    const inputId = id || `${resourceName}-${name}`;
    return (
      <FieldWrapper {...fieldWrapperProps({ inputId, ...this.props })}>
        <FieldArray
          name={name}
          render={arrayHelpers => this.renderAttachments(arrayHelpers, inputId)}
        />
      </FieldWrapper>
    );
  }
}

FilesField.defaultProps = {
  s3Secure: true,
};
