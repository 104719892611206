import yup from '../../../../components/form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  variant: yup.string().required(I18n.t('validations.required')),
  selector_values: yup
    .string()
    .nullable()
    .test('required selector values', I18n.t('validations.required'), function(value) {
      if (this.parent.variant === 'selector') {
        return !!value;
      }
      return true;
    }),
});
