import React, { useRef, useState } from 'react';
import { FormSection } from '../../../../components/form/FormSection';
import { FormSectionHeader } from '../../../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../../../components/form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../../../components/form/FormSectionHeaderLinks';
import { AsyncSimpleList } from '../../../../components/lists/AsyncSimpleList';
import { Modal } from '../../../../components/modals/Modal';
import { API } from '../../../../services/api';
import {
  SimpleListDeletionLink,
  SimpleListLink,
} from '../../../../components/lists/SimpleListLinks';
import {
  handleSubmitWithAfterSave,
  isDefined,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { AddEquipmentFieldDefinitionModalContent } from './index';
import { EmptyListPlaceholder } from '../../../../components/lists/EmptyListPlaceholder';

const t = key => I18n.t(`equipment_types.${key}`);

export const EquipmentFieldDefinitions = props => {
  const {
    isSubmitting,
    setFieldValue,
    handleSubmit,
    values: { id },
  } = props;

  const addEquipmentFieldDefinitionModalRef = useRef(null);
  const listRef = useRef(null);
  const [equipmentFieldDefinition, setEquipmentFieldDefinition] = useState({});

  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{t('lists.equipment_field_definitions.title')}</FormSectionTitle>
          <FormSectionHeaderLink
            disabled={isSubmitting}
            onClick={async () => {
              await handleSubmitWithAfterSave(
                setFieldValue,
                handleSubmit,
                addEquipmentFieldDefinitionModalRef.current.open,
              );
              setEquipmentFieldDefinition({});
            }}
          >
            {t('lists.equipment_field_definitions.add_equipment_field_definition')}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {!isDefined(id) && <EmptyListPlaceholder />}
        {isDefined(id) && (
          <AsyncSimpleList
            api={API.administration.equipmentFieldDefinitions(id).index}
            ref={listRef}
            dataKey="data"
            resourceName={I18n.t('resource_names.equipment_field_definition')}
            isSortable
            reorderItemApi={API.administration.equipmentFieldDefinitions(id).reorder}
            mapData={({ attributes }) => [
              [
                t('lists.equipment_field_definitions.variant'),
                I18n.t(
                  `activerecord.attributes.equipment_field_definition.variants.${attributes.variant}`,
                ),
              ],
              [t('lists.equipment_field_definitions.name'), attributes.name],
              [t('lists.equipment_field_definitions.placeholder'), attributes.placeholder],
              [t('lists.equipment_field_definitions.tooltip'), attributes.tooltip],
              <SimpleListLink
                key="edit"
                disabled={isSubmitting}
                onClick={async () => {
                  const { ok, data } = await API.administration
                    .equipmentFieldDefinitions(id)
                    .show(attributes.id);
                  if (ok) {
                    await setEquipmentFieldDefinition(data.data.attributes);
                    await handleSubmitWithAfterSave(
                      setFieldValue,
                      handleSubmit,
                      addEquipmentFieldDefinitionModalRef.current.open,
                    );
                  } else {
                    showBackendErrorMessage(
                      t('lists.equipment_field_definitions.remove.flash.error'),
                      data,
                    );
                  }
                }}
              >
                {t('lists.equipment_field_definitions.edit')}
              </SimpleListLink>,
              <SimpleListDeletionLink
                key="delete"
                modelName={t('lists.equipment_field_definitions.model_name')}
                onSubmit={async () => {
                  const { ok, data } = await API.administration
                    .equipmentFieldDefinitions(id)
                    .destroy(attributes.id);
                  if (ok) {
                    listRef.current.refresh();
                    showSuccessMessage(t('lists.equipment_field_definitions.remove.flash.success'));
                  } else {
                    showBackendErrorMessage(
                      t('lists.equipment_field_definitions.remove.flash.error'),
                      data,
                    );
                  }
                }}
              >
                {t('lists.equipment_field_definitions.remove.title')}
              </SimpleListDeletionLink>,
            ]}
          />
        )}
      </FormSection>
      <Modal
        title={t('modals.add_equipment_field_definition.title')}
        ref={addEquipmentFieldDefinitionModalRef}
      >
        {modalProps => (
          <AddEquipmentFieldDefinitionModalContent
            {...modalProps}
            {...props}
            equipmentTypeId={id}
            equipmentFieldDefinition={equipmentFieldDefinition}
            listRef={listRef}
          />
        )}
      </Modal>
    </>
  );
};
