import React, { useState, useEffect } from 'react';
import { isDefined } from '../../services/utils';
import { API } from '../../services/api';
import { Loader } from '../../components/Loader';
import { SpaceRiskAssessmentForm } from './Form';

export const SpaceRiskAssessment = props => {
  const [fetching, setFetching] = useState(true);
  const [spaceRiskAssessment, setSpaceRiskAssessment] = useState({});
  useEffect(() => {
    (async () => {
      const api = isDefined(props.id)
        ? API.spaceRiskAssessments.show
        : API.spaceRiskAssessments.new;
      const { ok, data } = await api(props.id);
      if (ok) {
        await setSpaceRiskAssessment(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <SpaceRiskAssessmentForm {...props} spaceRiskAssessment={spaceRiskAssessment} />;
};
export default SpaceRiskAssessment;
