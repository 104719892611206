import { withFormik } from 'formik';
import snakeCase from 'lodash/snakeCase';
import { Form } from './Form';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`requests.waste_requests.new_edit.${key}`);

const WasteRequestFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const wasteRequest = WasteRequestFunctions.prepareValuesToSubmit({ ...values });
    if (isDefined(wasteRequest.id)) {
      WasteRequestFunctions.update(wasteRequest, formikProps, values.submitType, values.afterSave);
    } else {
      WasteRequestFunctions.create(wasteRequest, formikProps, values.afterSave);
    }
  },
  create: async (wasteRequest, formikProps, afterSave) => {
    const { ok, data } = await API.requests.wasteRequests.create({
      waste_request: wasteRequest,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/requests/waste_requests/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.requests.waste_requests.edit.title'));
      formikProps.resetForm({
        values: WasteRequestFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (wasteRequest, formikProps, submitType, afterSave) => {
    const { ok, data } = await API.requests.wasteRequests[
      submitType === 'save' ? 'update' : submitType
    ](wasteRequest.id, { waste_request: wasteRequest });
    if (ok) {
      formikProps.resetForm({
        values: WasteRequestFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t(`flash.success.${snakeCase(submitType)}`));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t(`flash.error.${snakeCase(submitType)}`), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: wasteRequest => ({
    afterSave: () => {},
    submitType: 'save',
    status: wasteRequest.status,
    subStatus: wasteRequest.sub_status,
    resolutionDate: wasteRequest.resolution_date,
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'identifier',
    'requested_date',
    'creator_id',
    'responsible_person_id',
    'due_date',
    'also_notified_person_ids',
    'building_id',
    'buildings_floor_id',
    'lab_id',
    'location_details',
    'request_attachments',
    'request_links',
    'sub_status',
    'ehs_assignee_id',
    'contact_number',
    'alternative_contact_number',
    'waste_generator_id',
    'send_mails',
  ],
});

export const WasteRequestForm = withFormik({
  validate: produceDefaultValidation(validationSchema),
  mapPropsToValues: props => WasteRequestFunctions.prepareInitialValues(props.wasteRequest),
  handleSubmit: WasteRequestFunctions.handleSubmit,
})(Form);
