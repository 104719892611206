import yup from '../../../components/form/CustomYupValidator';

export const validationSchema = yup.object().shape({
  permits_issuer_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  primary_building_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  permits_type_fdny_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  permit_fdny_acct: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  permit_status: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});
