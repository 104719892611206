import React, { useRef, useState } from 'react';
import { API } from '../../../../services/api';
import { isDefined } from '../../../../services/utils';
import { SmallLightButton } from '../../../../components/buttons/SmallLightButton';
import { Modal } from '../../../../components/modals/Modal';
import { LogoProfileModalContent } from './index';

const t = key => I18n.t(`hsds.logo_profiles.${key}`);

export const LogoProfileButtons = props => {
  const [logoProfile, setLogoProfile] = useState({});
  const [modalTitle, setModalTitle] = useState({});
  const logoProfileModalRef = useRef(null);
  const handleAddNewProfileButtonClick = async () => {
    const { ok, data } = await API.spaceRiskAssessments.logoProfiles.new();
    if (ok) {
      await setLogoProfile(data.data.attributes);
      await setModalTitle(t('title.new'));
      logoProfileModalRef.current.open();
    }
  };
  const handleEditProfileButtonClick = async () => {
    const { ok, data } = await API.spaceRiskAssessments.logoProfiles.show(
      props.values.logo_profile_id,
    );
    if (ok) {
      await setLogoProfile(data.data.attributes);
      await setModalTitle(t('title.edit'));
      logoProfileModalRef.current.open();
    }
  };
  return (
    <>
      <div className="d-flex flex-row">
        {isDefined(props.values.logo_profile_id) && (
          <SmallLightButton
            className={`btn btn-light btn-sm mr-3 ${props.name}_edit_profile_button`}
            onClick={handleEditProfileButtonClick}
          >
            {t('edit_profile_button')}
          </SmallLightButton>
        )}
        <SmallLightButton
          className={`btn btn-light btn-sm ${props.name}_new_profile_button`}
          onClick={handleAddNewProfileButtonClick}
        >
          {t('new_profile_button')}
        </SmallLightButton>
      </div>
      <Modal title={modalTitle} ref={logoProfileModalRef}>
        {modalProps => (
          <LogoProfileModalContent
            currentUser={props.currentUser}
            logoProfile={logoProfile}
            {...modalProps}
          />
        )}
      </Modal>
    </>
  );
};
