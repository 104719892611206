import React, { useRef } from 'react';
import { FormSection } from '../../../components/form/FormSection';
import { FormSectionHeader } from '../../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../../components/form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../../components/form/FormSectionHeaderLinks';
import { AsyncSimpleList } from '../../../components/lists/AsyncSimpleList';
import { Modal } from '../../../components/modals/Modal';
import { API } from '../../../services/api';
import { SimpleListDeletionLink } from '../../../components/lists/SimpleListLinks';
import {
  handleSubmitWithAfterSave,
  isDefined,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { AddConstructionProjectModalContent } from './AddConstructionProjectModalContent';
import { EmptyListPlaceholder } from '../../../components/lists/EmptyListPlaceholder';

const t = key => I18n.t(`safe_work_authorizations.${key}`);

export const ConstructionProjects = props => {
  const {
    isSubmitting,
    setFieldValue,
    handleSubmit,
    values: { id },
    access_to_construction_projects,
  } = props;

  const addConstructionProjectModalRef = useRef(null);
  const listRef = useRef(null);

  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{t('lists.construction_projects.title')}</FormSectionTitle>
          <FormSectionHeaderLink
            disabled={isSubmitting}
            onClick={async () => {
              await handleSubmitWithAfterSave(
                setFieldValue,
                handleSubmit,
                addConstructionProjectModalRef.current.open,
              );
            }}
          >
            {t('lists.construction_projects.add_construction_project')}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {!isDefined(id) && <EmptyListPlaceholder />}
        {isDefined(id) && (
          <AsyncSimpleList
            api={API.requests.safeWorkAuthorizations.construction_projects(id).index}
            ref={listRef}
            dataKey="data"
            mapRowHrefs={
              access_to_construction_projects &&
              (({ attributes: attribute }) =>
                `/construction_projects/${attribute.construction_project.id}/edit`)
            }
            resourceName={I18n.t('resource_names.swa_construction_project')}
            mapData={({ attributes }) => [
              [
                t('lists.construction_projects.identifier'),
                attributes.construction_project.identifier,
              ],
              [
                t('lists.construction_projects.project_name'),
                attributes.construction_project.project_name,
              ],
              [
                t('lists.construction_projects.project_status'),
                attributes.construction_project.status,
              ],
              [
                t('lists.construction_projects.project_type'),
                attributes.construction_project.project_type,
              ],
              [
                t('lists.construction_projects.project_manager'),
                attributes.construction_project.project_manager,
              ],
              <SimpleListDeletionLink
                key="delete"
                modelName={t('lists.construction_projects.model_name')}
                onSubmit={async () => {
                  const {
                    ok,
                    data,
                  } = await API.requests.safeWorkAuthorizations
                    .construction_projects(id)
                    .destroy(attributes.id);
                  if (ok) {
                    listRef.current.refresh();
                    showSuccessMessage(t('lists.construction_projects.delete.flash.success'));
                  } else {
                    showBackendErrorMessage(
                      t('lists.construction_projects.delete.flash.error'),
                      data,
                    );
                  }
                }}
              >
                {t('lists.construction_projects.delete.title')}
              </SimpleListDeletionLink>,
            ]}
          />
        )}
      </FormSection>
      <Modal
        title={t('modals.add_construction_project.title')}
        ref={addConstructionProjectModalRef}
      >
        {modalProps => (
          <AddConstructionProjectModalContent {...modalProps} {...props} listRef={listRef} />
        )}
      </Modal>
    </>
  );
};
