import React, { useRef, useState } from 'react';
import { FormSection } from '../../../components/form/FormSection';
import {
  handleSubmitWithAfterSave,
  isDefined,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { IndexTableDefaultHandler } from '../../../components/tables/IndexTableDefaultHandler';
import { BiologicalAgentModalContent } from './BiologicalAgent';
import { Modal } from '../../../components/modals/Modal';
import { DeletionLink } from '../../../components/links/DeletionLink';
import { DefaultLink } from '../../../components/links/DefaultLink';

const t = key => I18n.t(`biological_safeties.new_edit.${key}`);

export const BiologicalAgents = props => {
  const {
    isSubmitting,
    setFieldValue,
    handleSubmit,
    values: { id },
  } = props;
  const modalRef = useRef(null);
  const tableRef = useRef(null);
  const [biologicalAgent, setBiologicalAgent] = useState({});
  const [modalTitle, setModalTitle] = useState('');

  const openModal = async biologicalAgentId => {
    const afterSave = async ibcRegistrationId => {
      const { ok, data } = await API.biologicalSafety
        .biologicalAgents(ibcRegistrationId)
        [biologicalAgentId ? 'show' : 'new'](biologicalAgentId);
      if (ok) {
        await setBiologicalAgent(data.data.attributes);
        await setModalTitle(
          t(`modals.biological_agent.title.${biologicalAgentId ? 'edit' : 'new'}`),
        );
        await modalRef.current.open();
      }
    };
    await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
  };

  return (
    <>
      <FormSection>
        <IndexTableDefaultHandler
          title={t('lists.biological_agents.title')}
          ref={tableRef}
          api={API.biologicalSafety.biologicalAgents(id).index}
          fixedFilters={{ biological_safety_id: id }}
          onRowClick={({ id: biologicalAgentId }) => openModal(biologicalAgentId)}
          manageColumnsModel="BiologicalAgent"
          forceEmpty={!isDefined(id)}
          additionalLinks={[
            <DefaultLink disabled={isSubmitting} onClick={() => openModal()}>
              {t('lists.biological_agents.add_biological_agent')}
            </DefaultLink>,
          ]}
          additionalColumns={[
            {
              key: 'delete',
              renderCell: biologicalAgentId => (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                <span
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <DeletionLink
                    modelName={I18n.t('activerecord.models.biological_agent')}
                    onSubmit={async () => {
                      const { ok, data } = await API.biologicalSafety
                        .biologicalAgents(id)
                        .destroy(biologicalAgentId);
                      if (ok) {
                        showSuccessMessage(t('modals.biological_agent.flash.success.remove'));
                        tableRef.current.refresh();
                      } else {
                        showBackendErrorMessage(
                          t('modals.biological_agent.flash.error.remove'),
                          data,
                        );
                      }
                    }}
                  >
                    {t('modals.biological_agent.delete')}
                  </DeletionLink>
                </span>
              ),
            },
          ]}
        />
      </FormSection>
      <Modal title={modalTitle} ref={modalRef}>
        {modalProps => (
          <BiologicalAgentModalContent
            ibcRegistrationId={id}
            biologicalAgent={biologicalAgent}
            currentUser={props.currentUser}
            tableRef={tableRef}
            {...modalProps}
          />
        )}
      </Modal>
    </>
  );
};
