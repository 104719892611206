import React, { useRef, useState, useCallback } from 'react';
import { FormSection } from '../../components/form/FormSection';
import { FormSectionHeader } from '../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../components/form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../components/form/FormSectionHeaderLinks';
import { API } from '../../services/api';
import { showBackendErrorMessage, showSuccessMessage } from '../../services/utils';
import { AsyncSimpleList } from '../../components/lists/AsyncSimpleList';
import { Modal } from '../../components/modals/Modal';
import { SimpleDictionaryOptionModalContent } from './SimpleDictionaryOption';
import { SimpleListAdditionalElement } from '../../components/lists/SimpleListAdditionalElement';
import { SimpleListRowAdditionalText } from '../../components/lists/SimpleListRowAdditionalText';
import { DeletionModalContent } from '../../components/modals/DeletionModalContent';
import { FormHeader } from '../../components/form/FormHeader';
import { produceMapData } from './produceMapData';

const t = key => I18n.t(`administration.simple_dictionaries.edit.${key}`);

const SimpleDictionary = props => {
  const {
    dictionary,
    dictionariesNamesTranslationPath,
    listTitle,
    renderLockedList,
    lockedListTitle,
  } = props;

  const listRef = useRef(null);
  const modalRef = useRef(null);
  const deletionModalRef = useRef(null);
  const [dictionaryOption, setDictionaryOption] = useState({});
  const [modalTitle, setModalTitle] = useState({});
  const [idToDelete, setIdToDelete] = useState(null);
  const elementsCount = useRef(0);
  const [maxPositionValue, setMaxPositionValue] = useState(0);

  const mapData = useCallback(
    locked =>
      produceMapData({
        elementsCount,
        dictionary,
        setDictionaryOption,
        setModalTitle,
        setMaxPositionValue,
        modalRef,
        setIdToDelete,
        deletionModalRef,
        locked,
      }),
    [elementsCount, dictionary],
  );

  return (
    <>
      <FormHeader title={I18n.t(`${dictionariesNamesTranslationPath}.${dictionary}`)} />
      {renderLockedList ? (
        <FormSection>
          <FormSectionHeader>
            <FormSectionTitle>{lockedListTitle}</FormSectionTitle>
          </FormSectionHeader>
          <AsyncSimpleList
            api={() =>
              API.administration.simpleDictionaryOptions.index({ dictionary, locked: true })
            }
            dataKey="data"
            resourceName={I18n.t('resource_names.simple_dictionary_locked_options')}
            mapData={mapData(true)}
          />
        </FormSection>
      ) : (
        <></>
      )}
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{listTitle}</FormSectionTitle>
          <FormSectionHeaderLink
            onClick={async () => {
              const { ok, data } = await API.administration.simpleDictionaryOptions.new({
                dictionary,
              });
              if (ok) {
                await setDictionaryOption(data.data.attributes);
                await setModalTitle(t('new_option_modal_title'));
                await setMaxPositionValue(elementsCount.current + 1);
                await modalRef.current.open();
              }
            }}
          >
            {t('add_option')}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        <AsyncSimpleList
          ref={listRef}
          api={() => API.administration.simpleDictionaryOptions.index({ dictionary })}
          dataKey="data"
          resourceName={I18n.t('resource_names.simple_dictionary_options')}
          isSortable
          reorderItemApi={API.administration.simpleDictionaryOptions.reorder}
          reorderItemApiAdditionalParams={{ dictionary }}
          mapData={({ attributes: { name, custom_fields = [] }, id }) => {
            const isDefault = custom_fields.find(field => field.name === 'is_default')?.value;
            const isDefaultSupported = isDefault !== undefined;

            const additionalCustomFields = custom_fields.filter(
              field => field.name !== 'is_default',
            );
            const additionalCustomFieldsLabelValuePairs = additionalCustomFields.map(field => {
              const fieldType = field.type;
              let fieldValue = field.value;
              if (fieldType === 'boolean') {
                fieldValue = fieldValue ? I18n.t('true') : I18n.t('false');
              } else if (fieldType === 'selector' && isDefined(field.translated_enum_value)) {
                fieldValue = field.translated_enum_value;
              } else if (fieldType === 'json') {
                const formattedValue = _.sortBy(
                  Object.entries(fieldValue).map(
                    ([key, value]) => `${_.capitalize(_.lowerCase(key))}: ${value}`,
                  ),
                );
                fieldValue = formattedValue.map(row => <div>{row}</div>);
              }
              return [field.label, fieldValue];
            });

            return [
              [t('form_fields.name'), name],
              ...additionalCustomFieldsLabelValuePairs,
              isDefaultSupported ? (
                // in case additional text is can be displayed in at least one row;
                // it should be displayed in all of them - display of middle columns might
                // be incorrect otherwise
                <SimpleListAdditionalElement style={{ width: '60px' }}>
                  {isDefault ? t('is_default') : ''}
                </SimpleListAdditionalElement>
              ) : null,
              <SimpleListLink
                key="view"
                onClick={async () => {
                  const { ok, data } = await API.administration.simpleDictionaryOptions.show(id, {
                    dictionary,
                  });
                  if (ok) {
                    await setDictionaryOption(data.data.attributes);
                    await setModalTitle(t('edit_option_modal_title'));
                    await modalRef.current.open();
                  }
                }}
              >
                {t('edit_option')}
              </SimpleListLink>,
              <SimpleListDeletionLink
                key="remove"
                // we use one modal for all options instead - to improve the performance
                modal={false}
                onSubmit={async () => {
                  setIdToDelete(id);
                  deletionModalRef.current.open();
                }}
              >
                {t('remove_option')}
              </SimpleListDeletionLink>,
            ];
          }}
          onDataFetched={fetchedData => {
            elementsCount.current = fetchedData.length;
          }}
          mapData={mapData(false)}
        />
      </FormSection>
      <Modal title={modalTitle} ref={modalRef}>
        {modalProps => (
          <SimpleDictionaryOptionModalContent
            dictionary={dictionary}
            dictionaryOption={dictionaryOption}
            listRef={listRef}
            maxPositionValue={maxPositionValue}
            {...modalProps}
          />
        )}
      </Modal>
      <Modal ref={deletionModalRef}>
        <DeletionModalContent
          modalRef={deletionModalRef}
          modelName={t('dictionary_option')}
          onSubmit={async () => {
            const { ok, data } = await API.administration.simpleDictionaryOptions.destroy(
              idToDelete,
              {
                dictionary,
              },
            );
            if (ok) {
              listRef.current.refresh();
              showSuccessMessage(t('flash.success.remove'));
            } else {
              showBackendErrorMessage(t('flash.error.remove'), data);
            }
            deletionModalRef.current.hide();
          }}
        />
      </Modal>
    </>
  );
};
export default SimpleDictionary;
