import { withFormik } from 'formik';
import { API } from '../../../../services/api';
import { AttendeeForm } from './Form';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`training.employee_trainings.new_edit.modals.attendee.${key}`);

const AttendeeFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const attendee = AttendeeFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(attendee.id)) {
      AttendeeFunctions.create(attendee, formikProps);
    } else {
      AttendeeFunctions.update(attendee, formikProps);
    }
  },
  create: async (attendee, formikProps) => {
    const { ok, data } = await API.employeeTrainings
      .employeeTrainingPeople(formikProps.props.employeeTrainingId)
      .create({ employee_training_person: attendee });
    if (ok) {
      formikProps.resetForm({
        values: AttendeeFunctions.prepareInitialValues(data.data.attributes),
      });
      formikProps.props.hide();
      formikProps.props.listRef.current.refresh();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: {},
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'attendee_id',
    'employee_training_id',
  ],
});

export const AttendeeModalContent = withFormik({
  handleSubmit: AttendeeFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(AttendeeForm);
