import React from 'react';
import { FastField } from 'formik';
import { FormHeader } from '../../../components/form/FormHeader';
import { isDefined } from '../../../services/utils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { Footer } from './Footer';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';
import { WasteChemicalItemCandidateFieldDefinitions } from './WasteChemicalItemCandidateFieldDefinitions/WasteChemicalItemCandidateFieldDefinitions';

const t = key => I18n.t(`waste_collection_types.new_edit.${key}`);

export const Form = props => {
  const {
    values: { id },
  } = props;

  return (
    <form>
      <FormHeader title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)} />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.waste_collection_type',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.waste_collection_type'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="name" required component={TextField} />
          <FastField name="active" component={CheckBoxField} />
          <FastField
            name="is_default"
            hint={t('form.hints.is_default')}
            component={CheckBoxField}
          />
          <FastField name="include_waste_types_sp" component={CheckBoxField} />
          <FastField name="include_waste_types_cp" component={CheckBoxField} />
        </FormSection>
      </DefaultAttributesGroup>
      <Footer {...props} />
      <WasteChemicalItemCandidateFieldDefinitions {...props} />
    </form>
  );
};
