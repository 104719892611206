import React, { useRef, useCallback } from 'react';
import { FormSection } from '../../../../components/form/FormSection';
import { FormSectionHeader } from '../../../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../../../components/form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../../../components/form/FormSectionHeaderLinks';
import { API } from '../../../../services/api';
import {
  handleSubmitWithAfterSave,
  isDefined,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { EmptyListPlaceholder } from '../../../../components/lists/EmptyListPlaceholder';
import { AsyncSimpleList } from '../../../../components/lists/AsyncSimpleList';
import {
  SimpleListDeletionLink,
  SimpleListLink,
} from '../../../../components/lists/SimpleListLinks';
import { Modal } from '../../../../components/modals/Modal';
import { ConfirmationModalContent } from '../../../../components/modals/ConfirmationModalContent';
import { CandidateStatusHandler } from './CandidateStatusHandler';

const t = key =>
  I18n.t(`requests.waste_requests.new_edit.lists.waste_collection_candidates.${key}`);
const createCollectionModalT = key =>
  I18n.t(`requests.waste_requests.new_edit.modals.create_collection.${key}`);
const createCollectionAgainModalT = key =>
  I18n.t(`requests.waste_requests.new_edit.modals.create_collection_again.${key}`);
const wasteRequestFormT = key => I18n.t(`requests.waste_requests.new_edit.${key}`);
const wasteCollectionT = key =>
  I18n.t(`activerecord.attributes.waste_chemical_item_candidate.${key}`);

export const WasteCollectionCandidatesList = props => {
  const {
    handleSubmit,
    setFieldValue,
    currentUser,
    values: { id: wasteRequestId, status: wasteRequestStatus },
  } = props;
  const listRef = useRef(null);
  const createCollectionModalRef = useRef(null);
  const createCollectionAgainModalRef = useRef(null);
  const relevantCandidateIdRef = useRef(null);
  const isWasteRequestCompleted = wasteRequestStatus === 'completed';
  const userIsAdmin = currentUser.app_admin || currentUser.org_admin;

  const createCollection = useCallback(async () => {
    const { ok, data } = await API.requests.wasteRequests
      .wasteCollectionCandidates(wasteRequestId)
      .update(relevantCandidateIdRef.current, { create_collection: true });
    if (ok) {
      listRef.current.refresh();
      showSuccessMessage(t('flash.success.create_collection'));
    } else {
      showBackendErrorMessage(t('flash.error.create_collection'), data);
    }
  }, [wasteRequestId]);

  const exportCollections = id => {
    window.open(API.requests.wasteRequests.wasteCollections(id), '_blank');
  };

  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{t('title')}</FormSectionTitle>
          <FormSectionHeaderLink
            onClick={() => exportCollections(wasteRequestId)}
            type="excel_export"
          >
            Export to:
          </FormSectionHeaderLink>
          <FormSectionHeaderLink
            hidden={isWasteRequestCompleted}
            onClick={async () => {
              const afterSave = newId => {
                setInitialFlashMessageForNextPage(wasteRequestFormT('flash.success.save'));
                window.location.href = `/requests/waste_requests/${newId}/waste_collection_candidates/new`;
              };
              await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
            }}
          >
            {t('add')}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {!isDefined(wasteRequestId) && <EmptyListPlaceholder />}
        {isDefined(wasteRequestId) && (
          <AsyncSimpleList
            ref={listRef}
            api={API.requests.wasteRequests.wasteCollectionCandidates(wasteRequestId).index}
            dataKey="data"
            resourceName={I18n.t('resource_names.waste_chemical_item_candidate')}
            mapData={({
              attributes: {
                waste_chemical_name_candidate,
                container_qty,
                container_size_display,
                waste_collection_type,
                chemical_item_created,
                candidate_edited,
              },
              id,
            }) => [
              [wasteCollectionT('waste_chemical_name_candidate'), waste_chemical_name_candidate],
              [wasteCollectionT('container_qty'), container_qty],
              [wasteCollectionT('container_size'), container_size_display],
              [wasteCollectionT('waste_collection_type_id'), waste_collection_type],
              <CandidateStatusHandler
                candidateId={id}
                isWasteRequestCompleted={isWasteRequestCompleted}
                chemicalItemCreated={chemical_item_created}
                candidateEdited={candidate_edited}
                relevantCandidateIdRef={relevantCandidateIdRef}
                createCollectionModalRef={createCollectionModalRef}
                createCollectionAgainModalRef={createCollectionAgainModalRef}
              />,
              <SimpleListLink
                key="view"
                hidden={isWasteRequestCompleted}
                onClick={async () => {
                  const afterSave = newId => {
                    setInitialFlashMessageForNextPage(wasteRequestFormT('flash.success.save'));
                    window.location.href = `/requests/waste_requests/${newId}/waste_collection_candidates/${id}/edit`;
                  };
                  await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
                }}
              >
                {t('view')}
              </SimpleListLink>,
              <SimpleListDeletionLink
                key="remove"
                modelName={t('model_name')}
                hidden={isWasteRequestCompleted || !userIsAdmin}
                onSubmit={async () => {
                  const { ok, data } = await API.requests.wasteRequests
                    .wasteCollectionCandidates(wasteRequestId)
                    .destroy(id);
                  if (ok) {
                    listRef.current.refresh();
                    showSuccessMessage(t('flash.success.destroy'));
                  } else {
                    showBackendErrorMessage(t('flash.error.destroy'), data);
                  }
                }}
              >
                {t('remove')}
              </SimpleListDeletionLink>,
            ]}
          />
        )}
      </FormSection>
      <Modal ref={createCollectionModalRef} title={createCollectionModalT('title')}>
        <ConfirmationModalContent
          modalRef={createCollectionModalRef}
          hint={createCollectionModalT('hint')}
          confirm={createCollectionModalT('confirm')}
          onConfirm={async () => {
            createCollection();
          }}
        />
      </Modal>
      <Modal ref={createCollectionAgainModalRef} title={createCollectionAgainModalT('title')}>
        <ConfirmationModalContent
          modalRef={createCollectionAgainModalRef}
          hint={createCollectionAgainModalT('hint')}
          confirm={createCollectionAgainModalT('confirm')}
          onConfirm={async () => {
            createCollection();
          }}
        />
      </Modal>
    </>
  );
};
