import React from 'react';
import { isDefined } from '../../../services/utils';
import { FormHeader } from '../../../components/form/FormHeader';
import { Footer } from './Footer';
import { RadPermitsTable } from './RadPermitsTable';
import { Overview } from './Overview';
import { RadLicenseItemsTable } from './RadLicenseItemsTable';

const t = key => I18n.t(`radiation_safety.rad_licenses.new_edit.${key}`);

export const Form = props => {
  const {
    values: { id },
  } = props;

  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)}
        auditsLink={isDefined(id) ? `/radiation_safety/rad_licenses/${id}/audits` : ''}
      />
      <Overview {...props} />
      <Footer {...props} />
      <RadLicenseItemsTable {...props} />
      <RadPermitsTable {...props} />
    </form>
  );
};
