import { withFormik } from 'formik';
import get from 'lodash/get';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { Form } from './Form';
import { API } from '../../../services/api';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`radiation_safety.rad_purchases.new_edit.${key}`);

const RadPurchaseFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const radPurchase = RadPurchaseFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(radPurchase.id)) {
      RadPurchaseFunctions.create(radPurchase, formikProps, values.afterSave);
    } else {
      RadPurchaseFunctions.update(radPurchase, formikProps, values.submitType, values.afterSave);
    }
  },
  create: async (radPurchase, formikProps, afterSave) => {
    const { ok, data } = await API.radiationSafety.radPurchases.create({
      rad_purchase: radPurchase,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/radiation_safety/rad_purchases/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.radiation_safety.rad_purchases.edit.title'));
      formikProps.resetForm({
        values: RadPurchaseFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (radPurchase, formikProps, submitType, afterSave) => {
    const { ok, data } = await API.radiationSafety.radPurchases.update(radPurchase.id, {
      rad_purchase: radPurchase,
    });
    if (ok) {
      if (submitType === 'createSimilar') {
        window.location.href = `/radiation_safety/rad_purchases/new?ref_rad_purchase_id=${radPurchase.id}`;
      } else {
        formikProps.resetForm({
          values: RadPurchaseFunctions.prepareInitialValues(data.data.attributes),
        });
        afterSave(data.data.id);
        showSuccessMessage(t('flash.success.save'));
      }
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: radPurchase => ({
    submitType: 'save',
    afterSave: () => {},
    activityLimit: radPurchase.activity_limit,
    remainingActivity: radPurchase.remaining_activity,
    remainingQuantity: radPurchase.remaining_quantity,
    activityUnitId: radPurchase.activity_unit_id,
    quantityUnitId: radPurchase.quantity_unit_id,
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'identifier',
    'rad_permit_id',
    'rad_user_id',
    'po_number',
    'po_date',
    'vendor_id',
    'comment',
    'order_contact_id',
    'rad_license_item_assignment_id',
    'purchased_activity',
    'purchased_quantity',
    'catalogue_number',
    'rad_compound_id',
    'date_calibration',
    'date_received',
    'rad_purchase_status_id',
    'date_disposed',
    'packing_slip_number',
    'attachments',
    'links',
  ],
});

export const RadPurchaseForm = withFormik({
  mapPropsToValues: props => RadPurchaseFunctions.prepareInitialValues(props.radPurchase),
  handleSubmit: RadPurchaseFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
