import React, { useRef } from 'react';
import styled from 'styled-components';
import { FieldArray } from 'formik';
import { FieldWrapper } from './auxiliary/FieldWrapper';
import { fieldWrapperProps } from '../../services/fieldUtils';
import { fontSize } from '../../assets/styles/typography';
import { colors } from '../../assets/styles/colors';
import { Modal } from '../modals/Modal';
import { AddLinksModalContent } from './auxiliary/AddLinksModalContent';
import { validHttpPrefixedLink, httpUnprefixedLink } from '../../services/linksUtils';

const t = key => I18n.t(`links_field.${key}`);

const MainContainer = styled.div`
  width: 100%;
`;

const LinkRow = styled.div`
  display: flex;
  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
`;

const LinkRowTextContainer = styled.span`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
`;

const LinkName = styled.span`
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
`;

const LinkUrl = styled.a`
  color: inherit;
  overflow: hidden;
  white-space: nowrap;
`;

const InvalidUrl = styled.span`
  color: ${colors.error};
`;

const RemoveLinkButtonContainer = styled.span`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

const RemoveLinkButton = styled.button`
  background: transparent;
  border: 0;
  padding-left: 7px;
  font-size: ${fontSize.textSm};
  color: ${colors.delete};
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const AddLinkButton = styled.button`
  padding-top: 8px;
  padding-bottom: 7px;
  padding-left: 0;
  background: transparent;
  border: 0;
  font-size: ${fontSize.textSm};
  color: ${colors.link};
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const defaultOnChange = async event => {
  const { value, arrayHelpers, arrayHelpersMethod } = event;
  await arrayHelpers[arrayHelpersMethod](value);
};

const onChange = (event, additionalOnChange) =>
  additionalOnChange ? additionalOnChange(event, defaultOnChange) : defaultOnChange(event);

const onRemove = (arrayHelpers, index, additionalOnChange) => {
  const event = {
    value: index,
    arrayHelpers,
    arrayHelpersMethod: 'remove',
  };
  onChange(event, additionalOnChange);
};

const produceOnPush = (arrayHelpers, additionalOnChange) => value => {
  const event = {
    value,
    arrayHelpers,
    arrayHelpersMethod: 'push',
  };
  onChange(event, additionalOnChange);
};

export const LinksField = props => {
  const {
    disabled,
    onChange: additionalOnChange,
    field: { name, value },
    resourceName,
    id,
  } = props;
  const addLinkModalRef = useRef(null);
  const fieldId = id || `${resourceName}-${name}`;
  return (
    <FieldWrapper {...fieldWrapperProps({ ...props, inputId: fieldId })}>
      <FieldArray
        name={name}
        render={arrayHelpers => (
          <MainContainer>
            {value.map(({ url, name: linkName }, index) => {
              const validUrl = validHttpPrefixedLink(url);
              const fieldIdWithIndex = `${fieldId}[${index}]`;
              return (
                <LinkRow key={fieldIdWithIndex}>
                  <LinkRowTextContainer>
                    <LinkName id={`${fieldIdWithIndex}-name`}>{linkName}&nbsp;</LinkName>
                    {validUrl ? (
                      <LinkUrl id={`${fieldIdWithIndex}-link`} target="_blank" href={validUrl}>
                        ({httpUnprefixedLink(validUrl)})
                      </LinkUrl>
                    ) : (
                      <InvalidUrl>{`(${t('invalid_link')})`}</InvalidUrl>
                    )}
                  </LinkRowTextContainer>
                  <RemoveLinkButtonContainer>
                    <RemoveLinkButton
                      id={`${fieldIdWithIndex}-delete`}
                      type="button"
                      hidden={disabled}
                      onClick={() => onRemove(arrayHelpers, index, additionalOnChange)}
                    >
                      {t('remove')}
                    </RemoveLinkButton>
                  </RemoveLinkButtonContainer>
                </LinkRow>
              );
            })}
            <AddLinkButton
              id={fieldId}
              type="button"
              hidden={disabled}
              onClick={() => addLinkModalRef.current.open()}
            >
              {t('add')}
            </AddLinkButton>
            <Modal title={t('add_modal.title')} ref={addLinkModalRef}>
              {modalProps => (
                <AddLinksModalContent
                  {...modalProps}
                  resourceName={fieldId}
                  onPush={produceOnPush(arrayHelpers, additionalOnChange)}
                />
              )}
            </Modal>
          </MainContainer>
        )}
      />
    </FieldWrapper>
  );
};
