import React from 'react';
import styled from 'styled-components';

const PageLimitLabel = styled.span`
  margin-right: 0.25rem;
`;

const Select = styled.select`
  &:focus {
    border-color: black;
  }
`;

export const IndexTablePageLimitSelection = ({ limitValue, onLimitValueChange }) => (
  <span>
    <PageLimitLabel className="small-text">
      {I18n.t('helpers.page_entries_info.page_limit')}:
    </PageLimitLabel>
    <Select
      value={limitValue}
      onChange={e => {
        onLimitValueChange(e.target.value);
      }}
    >
      <option value="12">12</option>
      <option value="25">25</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </Select>
  </span>
);
