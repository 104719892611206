import React from 'react';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { FormHeader } from '../../../components/form/FormHeader';
import { isDefined } from '../../../services/utils';
import { Footer } from './Footer';
import { RelatedEntityPanel } from '../../../components/form/RelatedEntityPanel';
import { Equipment } from './Equipment';
import { Overview } from './Overview';
import { DateAndTime } from './DateAndTime';

const t = key => I18n.t(`safe_work_authorization_measures.${key}`);

export const Form = props => (
  <form>
    <FormHeader title={t(`title.${isDefined(props.values.id) ? 'edit' : 'new'}`)} />
    <RelatedEntityPanel
      title={t('related_swa.title')}
      identifier={props.values.swaIdentifier}
      viewPrompt={t('related_swa.view')}
      link={`/requests/safe_work_authorizations/${props.values.swaId}/edit`}
    />
    <DefaultAttributesGroup
      defaultChildrenAttributes={{
        labelI18nKeyPrefix: 'activerecord.attributes.safe_work_authorization_measure',
        shouldUpdate: defaultShouldUpdate,
        disabled: props.values.swaCompleted,
        resourceName: I18n.t('resource_names.safe_work_authorization_measure'),
      }}
    >
      <Overview {...props} />
      <DateAndTime {...props} />
      {!props.values.swaCompleted && <Footer {...props} />}
      <Equipment {...props} />
    </DefaultAttributesGroup>
  </form>
);
