import React, { useCallback, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { xor, some, isEmpty, difference, intersection, fromPairs } from 'lodash';
import { isDefined } from '../../services/utils';
import { IndexTableFooter } from './IndexTableFooter';
import { TableHeader } from './TableHeader';
import { colors } from '../../assets/styles/colors';
import { Loader } from '../Loader';

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  min-height: 100px;
  position: relative;
`;

// 'width: calc(100% - 2px);' is here to prevent overflow-x if unnecessary.
// Unfortunately sometimes thead and tbody width
// can be incorrectly rounded out, hence '-2px'.
// 'width: 100%;' used to support old browsers
const Table = styled.table`
  margin: 0 auto;
  width: 100%;
  width: calc(100% - 2px);
`;

const SortingButton = styled.button`
  padding-left: 0;
  padding-right: 16px !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

const FilterIcon = styled.span`
  color: ${colors.yellow};
  position: absolute;
  float: right;
  right: 27px;
  top: 15px;
  font-size: 10px;
  width: 0;
`;

const CheckboxesTh = styled.th`
  width: 30px;
  padding-top: 2px !important;
`;

const CheckboxTd = styled.td`
  text-align: center;
  padding: 6px 8px 0 !important;
  cursor: default;
`;

const Checkbox = styled.input`
  &:focus {
    box-shadow: none;
    outline: none;
  }
`;

const NoDataPlaceholder = styled.div`
  padding-left: 0.2rem;
  padding-top: 1rem;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const IndexTable = props => {
  const {
    headersData,
    onFiltersChange,
    data,
    rowHover = false,
    additionalColumns = [],
    onCheckboxesChange,
    loading = false,
  } = props;

  const mappedData = useMemo(() => fromPairs(data.map(row => [row.key, row])), [data]);
  const rowsKeys = useMemo(() => data.map(row => row.key), [data]);

  // stringifiedRowsKeys is used to make sure some callbacks are triggerred only when actual
  // rowsKeys values are changed
  const stringifiedRowsKeys = useMemo(() => JSON.stringify(rowsKeys), [rowsKeys]);

  const [checkedOff, _setCheckedOff] = useState([]);
  const setCheckedOff = useCallback(
    newValue => {
      _setCheckedOff(newValue);
      if (onCheckboxesChange) onCheckboxesChange(newValue.map(value => mappedData[value]));
    },
    [onCheckboxesChange, mappedData],
  );
  useEffect(() => {
    setCheckedOff(intersection(rowsKeys, checkedOff));
  }, [stringifiedRowsKeys]);

  const [initialLoading, setInitialLoading] = useState(true);
  useEffect(() => {
    if (initialLoading && !loading) setInitialLoading(false);
  }, [loading]);

  const filtered = useMemo(
    () =>
      Array.isArray(headersData) &&
      some(headersData, ({ appliedFilters }) => !isEmpty(appliedFilters)),
    [headersData],
  );
  const allChecked = useMemo(
    () => !isEmpty(rowsKeys) && isEmpty(difference(rowsKeys, checkedOff)),
    [checkedOff, stringifiedRowsKeys],
  );
  const onCheckAllClick = useCallback(() => {
    setCheckedOff(allChecked ? [] : rowsKeys);
  }, [allChecked, stringifiedRowsKeys]);

  useEffect(tableResizer.init, []);

  return (
    <>
      <TableContainer>
        <Table
          className={`border resizable table table-default table-filterable
                      table-sm table-striped ${rowHover ? 'table-hover' : ''}`}
        >
          <thead>
            <tr>
              {onCheckboxesChange ? (
                <CheckboxesTh onClick={e => e.stopPropagation()}>
                  {initialLoading ? (
                    <></>
                  ) : (
                    <Checkbox type="checkbox" checked={allChecked} onChange={onCheckAllClick} />
                  )}
                </CheckboxesTh>
              ) : (
                <></>
              )}
              {headersData.map(headerProps => {
                const {
                  field,
                  title,
                  appliedSortingDirection,
                  appliedFilters,
                  onSortBy,
                  isFilterable,
                  filterInputType,
                } = headerProps;
                return (
                  <TableHeader
                    key={field}
                    title={title}
                    fieldName={field}
                    initialValue={appliedFilters}
                    isFilterable={isFilterable}
                    onFiltersChange={onFiltersChange}
                    filterInputType={filterInputType}
                  >
                    <div
                      className="row row-no-margin filterable-label-container
                                 hoverable-filter-label-container"
                      style={isFilterable ? {} : { borderBottom: 'solid 4px transparent' }}
                    >
                      <div
                        className="filterable-label label"
                        style={{
                          position: 'relative',
                          cursor: isFilterable ? 'pointer' : 'default',
                        }}
                      >
                        <div className="d-flex">
                          <div className="filterable-header w-100">{title}</div>
                          <FilterIcon
                            className="glyphicon glyphicon-filter"
                            hidden={!isDefined(appliedFilters)}
                          />
                        </div>
                      </div>
                      {isDefined(onSortBy) && (
                        <span className="filterable-sort">
                          <SortingButton
                            className={`list-orderable-label ${
                              appliedSortingDirection ? `current ${appliedSortingDirection}` : 'asc'
                            }`}
                            onClick={onSortBy}
                          >
                            &nbsp;
                          </SortingButton>
                        </span>
                      )}
                    </div>
                  </TableHeader>
                );
              })}
              {additionalColumns.map(({ renderHeader, key }) =>
                data.length > 0 || renderHeader ? (
                  <th key={key}>{renderHeader ? renderHeader() : ''}</th>
                ) : (
                  <></>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {data.map(({ key: rowKey, cells, onClick }) => (
              <tr
                key={rowKey}
                className={isDefined(onClick) ? 'whole-tr-link' : ''}
                onClick={onClick}
                style={loading ? { visibility: 'hidden' } : {}}
              >
                {onCheckboxesChange ? (
                  <CheckboxTd
                    onClick={e => e.stopPropagation()}
                    style={loading ? { borderRight: 'none' } : {}}
                  >
                    <Checkbox
                      type="checkbox"
                      checked={checkedOff.includes(rowKey)}
                      onChange={() => setCheckedOff(xor(checkedOff, [rowKey]))}
                    />
                  </CheckboxTd>
                ) : (
                  <></>
                )}
                {cells.map(({ field, content }) => (
                  <td
                    key={field}
                    className="limit-text-in-cell"
                    style={loading ? { borderRight: 'none' } : {}}
                  >
                    {content}
                  </td>
                ))}
                {additionalColumns.map(({ renderCell = () => null, key }) => (
                  <td key={key} style={loading ? { borderRight: 'none' } : {}}>
                    {renderCell(rowKey, cells)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
        {loading && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
        {!loading && isEmpty(data) ? (
          <NoDataPlaceholder>
            {I18n.t(filtered ? 'empty_filtered_table' : 'empty_table')}
          </NoDataPlaceholder>
        ) : (
          <></>
        )}
      </TableContainer>
      <IndexTableFooter {...props} />
    </>
  );
};
