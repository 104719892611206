import React from 'react';
import InvestigableRelatedItems from './InvestigableRelatedItems';
import { showSuccessMessage } from '../../services/utils';

export const FormHeader = ({
  title,
  auditsLink,
  pdfExportLink,
  pdfExportMessage = '',
  investigable,
  additionalActions = [],
}) => (
  <div className="page-header">
    <div className="container mb-3 shared-header form-header">
      <div className="row justify-content-end align-items-center react-form-header">
        <div className="col pl-0">
          <h1 className="header-title">{title}</h1>
        </div>
        {auditsLink && (
          <div className="mr-3">
            <a href={auditsLink} className="header-link d-flex align-items-center">
              <i className="fa fa-list" style={{ marginBottom: '2px' }} />
              <span className="ml-2">{I18n.t('form.header.audit_log')}</span>
            </a>
          </div>
        )}
        {pdfExportLink && (
          <div className="mr-3">
            <span className="header-link d-flex align-items-center">
              <span className="mr-2">{I18n.t('form.header.export_to')}</span>
              <a
                href={`${pdfExportLink}`}
                target="_blank"
                className="report-link header-link"
                data-remote="true"
                onClick={() => showSuccessMessage(pdfExportMessage)}
                aria-label={I18n.t('form.header.aria.export_to_pdf')}
              >
                <span className="report-download-icon icon icon-pdf report-icon" />
                <img
                  src="/assets/loading_indicator_110.gif"
                  className="report-download-spinner report-icon-spinner"
                  alt="loader"
                />
              </a>
            </span>
          </div>
        )}
        {investigable && (
          <div className="mr-3">
            <InvestigableRelatedItems investigable={investigable} />
          </div>
        )}
        {additionalActions.map(action => (
          <div className="d-flex align-items-center justify-content-between mr-3">{action}</div>
        ))}
      </div>
    </div>
  </div>
);
