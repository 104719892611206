import React from 'react';
import { FastField } from 'formik';
import { FormSection } from '../../../components/form/FormSection';
import { Footer } from './Footer';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { TextField } from '../../../components/fields/TextField';
import { DateTimeField } from '../../../components/fields/DateTimeField';
import { SelectorField } from '../../../components/fields/SelectorField';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncMultiSelectorField } from '../../../components/fields/AsyncMultiSelectorField';
import { TextAreaField } from '../../../components/fields/TextAreaField';
import { FilesField } from '../../../components/fields/FilesField';
import { defaultShouldUpdate, mapToSelectorOptions } from '../../../services/fieldUtils';
import { LinksField } from '../../../components/fields/LinksField';
import { FormSubtitle } from '../../../components/form/FormSubtitle';
import { Locations } from '../../../components/other/locations/Locations';
import { FormHeader } from '../../../components/form/FormHeader';
import { handleSubmitWithAfterSave, isDefined } from '../../../services/utils';
import { Measures } from './Measures';
import { Comments } from '../../../components/other/comments/Comments';
import { Assessments } from './Assessments';
import { Checklist } from './Checklist';
import { DateAndTime } from './DateAndTime';
import { ConstructionProjects } from './ConstructionProjects';

const t = key => I18n.t(`safe_work_authorizations.${key}`);

export const Form = props => (
  <form>
    <FormHeader
      title={I18n.t(
        `safe_work_authorizations.title.${isDefined(props.values.id) ? 'edit' : 'new'}`,
      )}
      investigable={
        isDefined(props.values.id) && {
          type: 'SafeWorkAuthorization',
          id: props.values.id,
          identifier: props.values.identifier,
        }
      }
      auditsLink={
        isDefined(props.values.id)
          ? `/requests/safe_work_authorizations/${props.values.id}/audits`
          : ''
      }
      pdfExportLink={
        isDefined(props.values.id)
          ? `/salute_portal/api/requests/safe_work_authorizations/safe_work_authorizations/${props.values.id}.pdf`
          : ''
      }
      pdfExportMessage={I18n.t(`flash_message.async_report_render`, {
        user_email: props.currentUser.email,
      })}
    />
    <DefaultAttributesGroup
      defaultChildrenAttributes={{
        labelI18nKeyPrefix: 'activerecord.attributes.safe_work_authorization',
        disabled: props.values.is_completed,
        shouldUpdate: defaultShouldUpdate,
        resourceName: I18n.t('resource_names.safe_work_authorization'),
      }}
    >
      <FormSection title={t('sections.overview')}>
        <FastField name="identifier" disabled component={TextField} />
        <FastField
          name="type"
          options={[{ value: 'default', data: t('other.type_label') }]}
          disabled
          component={SelectorField}
        />
        <FastField name="date_requested" required component={DateTimeField} />
        <FastField
          name="requesting_person_id"
          required
          api={API.selectors.people.index}
          selectionApi={API.selectors.people.show}
          optionRenderer={htmlOptionRenderer('text')}
          headerRenderer={defaultOptionRenderer('full_name')}
          component={AsyncSelectorField}
        />
        <FastField
          name="responsible_person_id"
          required
          api={API.selectors.people.index}
          selectionApi={API.selectors.people.show}
          optionRenderer={htmlOptionRenderer('text')}
          headerRenderer={defaultOptionRenderer('full_name')}
          component={AsyncSelectorField}
        />
        <FastField
          name="also_notified_person_ids"
          label={I18n.t('activerecord.attributes.safe_work_authorization.also_notified_people_ids')}
          api={API.selectors.people.index}
          selectionApi={API.selectors.people.show}
          optionRenderer={htmlOptionRenderer('text')}
          headerRenderer={defaultOptionRenderer('full_name')}
          component={AsyncMultiSelectorField}
        />
        <FastField name="external_contact" component={TextField} />
        <FastField name="external_phone_number" component={TextField} />
        <FastField name="external_email" component={TextField} />
        <FastField name="cost_center" component={TextField} />
        <FastField name="project" component={TextField} />
        <FastField
          name="request_description"
          required
          component={TextAreaField}
          placeholder={t('other.request_description_placeholder')}
        />
        <FastField
          name="label_ids"
          api={API.selectors.labels.index}
          selectionApi={API.selectors.labels.show}
          optionRenderer={htmlOptionRenderer('text')}
          component={AsyncMultiSelectorField}
        />
        <hr />
        <FormSubtitle
          required
          name="swaQuestionAnswersAttributes"
          errors={props.errors}
          touched={props.touched}
        >
          {t('other.checklist_subtitle')}
        </FormSubtitle>
        <Checklist {...props} />
        <FastField name="request_attachments" component={FilesField} />
        <FastField name="request_links" component={LinksField} />
      </FormSection>
      <DateAndTime {...props} />
      <FormSection title={t('sections.resolution')}>
        <FastField
          name="assigned_person_id"
          api={API.selectors.people.index}
          selectionApi={API.selectors.people.show}
          optionRenderer={htmlOptionRenderer('text')}
          headerRenderer={defaultOptionRenderer('full_name')}
          component={AsyncSelectorField}
        />
        <FastField
          name="priority"
          options={mapToSelectorOptions(props.enums.priority, 'header', 'value', () => false)}
          component={SelectorField}
        />
        <FastField
          name="sub_status"
          options={mapToSelectorOptions(
            props.enums.subStatus,
            'header',
            'value',
            () => false,
            option => !props.selectableSubStatuses.includes(option.value),
          )}
          component={SelectorField}
        />
        <FastField
          hidden={!props.values.is_completed}
          name="resolution_date"
          component={DateTimeField}
        />
      </FormSection>
      <FormSection title={t('sections.internal_information')}>
        <FastField name="internal_notes" component={TextAreaField} />
        <FastField name="internal_attachments" component={FilesField} />
        <FastField name="internal_links" component={LinksField} />
      </FormSection>
      {props.values.id && (
        <FormSection title="Comments">
          <Comments
            api={API.requests.safeWorkAuthorizations.comments(props.values.id)}
            disabled={false}
          />
        </FormSection>
      )}
    </DefaultAttributesGroup>
    <Footer {...props} />
    <Locations
      api={API.requests.safeWorkAuthorizations.locations}
      id={props.values.id}
      disabled={props.isSubmitting}
      title={t('lists.locations.title')}
      resourceName={I18n.t('resource_names.safe_work_authorization_location')}
      humanizedResourceName={t('lists.locations.model_name')}
      locationDetailsPresentationKey="locations_details"
      locationDetailsInputAttributes={{
        name: 'locations_details',
        label: t('lists.locations.location_details'),
      }}
      handleAdditionLinkOnClick={open =>
        handleSubmitWithAfterSave(props.setFieldValue, props.handleSubmit, open)
      }
      prepareValuesToSubmit={values => ({
        safe_work_authorization_location: values,
      })}
    />
    <Measures {...props} />
    <Assessments {...props} />
    <ConstructionProjects {...props} />
  </form>
);
