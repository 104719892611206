import React, { useRef, useState } from 'react';
import { FormSection } from '../../../../components/form/FormSection';
import { FormSectionHeader } from '../../../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../../../components/form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../../../components/form/FormSectionHeaderLinks';
import {
  handleSubmitWithAfterSave,
  isDefined,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { EmptyListPlaceholder } from '../../../../components/lists/EmptyListPlaceholder';
import { AsyncSimpleList } from '../../../../components/lists/AsyncSimpleList';
import { API } from '../../../../services/api';
import {
  SimpleListDeletionLink,
  SimpleListLink,
} from '../../../../components/lists/SimpleListLinks';
import { WasteRequestContainersModalContent } from './index';
import { Modal } from '../../../../components/modals/Modal';

const t = key => I18n.t(`requests.waste_requests.new_edit.lists.waste_request_containers.${key}`);
const modalTitleTranslator = key =>
  I18n.t(`requests.waste_requests.new_edit.modals.waste_request_containers.title.${key}`);
const wasteRequestContainerTranslator = key =>
  I18n.t(`activerecord.attributes.waste_request_container.${key}`);

export const WasteRequestContainersList = props => {
  const {
    isSubmitting,
    handleSubmit,
    setFieldValue,
    values: { id: wasteRequestId, status },
  } = props;
  const isCompleted = status === 'completed';
  const listRef = useRef(null);
  const modalRef = useRef(null);
  const [wasteRequestContainer, setWasteRequestContainer] = useState({});
  const [modalTitle, setModalTitle] = useState({});

  const exportContainers = id => {
    window.open(API.requests.wasteRequests.wasteContainers(id), '_blank');
  };

  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{t('title')}</FormSectionTitle>
          <FormSectionHeaderLink
            onClick={() => exportContainers(wasteRequestId)}
            type="excel_export"
          >
            Export to:
          </FormSectionHeaderLink>
          <FormSectionHeaderLink
            hidden={isCompleted}
            disabled={isSubmitting}
            onClick={async () => {
              const afterSave = async newId => {
                const { ok, data } = await API.requests.wasteRequests
                  .wasteRequestContainers(newId)
                  .new();
                if (ok) {
                  await setWasteRequestContainer(data.data.attributes);
                  await setModalTitle(modalTitleTranslator('new'));
                  await modalRef.current.open();
                }
              };
              await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
            }}
          >
            {t('add')}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {!isDefined(wasteRequestId) && <EmptyListPlaceholder />}
        {isDefined(wasteRequestId) && (
          <AsyncSimpleList
            ref={listRef}
            api={API.requests.wasteRequests.wasteRequestContainers(wasteRequestId).index}
            dataKey="data"
            resourceName={I18n.t('resource_names.waste_request_container')}
            mapData={({ attributes: { quantity, container_type }, id }) => [
              [wasteRequestContainerTranslator('container_type_id'), container_type.name],
              [wasteRequestContainerTranslator('quantity'), quantity],
              <SimpleListLink
                key="view"
                hidden={isCompleted}
                disabled={isSubmitting}
                onClick={async () => {
                  const { ok, data } = await API.requests.wasteRequests
                    .wasteRequestContainers(wasteRequestId)
                    .show(id);
                  if (ok) {
                    await setWasteRequestContainer(data.data.attributes);
                    await setModalTitle(modalTitleTranslator('edit'));
                    await handleSubmitWithAfterSave(
                      setFieldValue,
                      handleSubmit,
                      modalRef.current.open,
                    );
                  }
                }}
              >
                {t('view')}
              </SimpleListLink>,
              <SimpleListDeletionLink
                key="remove"
                modelName={t('model_name')}
                hidden={isCompleted}
                onSubmit={async () => {
                  const { ok, data } = await API.requests.wasteRequests
                    .wasteRequestContainers(wasteRequestId)
                    .destroy(id);
                  if (ok) {
                    listRef.current.refresh();
                    showSuccessMessage(t('flash.success.remove'));
                  } else {
                    showBackendErrorMessage(t('flash.error.remove'), data);
                  }
                }}
              >
                {t('remove')}
              </SimpleListDeletionLink>,
            ]}
          />
        )}
      </FormSection>
      <Modal title={modalTitle} ref={modalRef}>
        {modalProps => (
          <WasteRequestContainersModalContent
            wasteRequestId={wasteRequestId}
            wasteRequestContainer={wasteRequestContainer}
            listRef={listRef}
            {...modalProps}
          />
        )}
      </Modal>
    </>
  );
};
