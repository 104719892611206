import React, { useState, useEffect } from 'react';
import { SafeWorkAuthorizationForm } from './Form';
import { Loader } from '../../components/Loader';
import { API } from '../../services/api';
import { isDefined } from '../../services/utils';

export const SafeWorkAuthorization = props => {
  const [fetching, setFetching] = useState(true);
  const [swa, setSwa] = useState({});
  useEffect(() => {
    (async () => {
      const api = isDefined(props.id)
        ? API.requests.safeWorkAuthorizations.show
        : API.requests.safeWorkAuthorizations.new;
      const { ok, data } = await api(props.id);
      if (ok) {
        await setSwa(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <SafeWorkAuthorizationForm {...props} safeWorkAuthorization={swa} />;
};
export default SafeWorkAuthorization;
