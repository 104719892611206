import React, { useRef, useCallback, useMemo } from 'react';
import { Tooltip } from 'react-tippy';
import { LinkBase } from './auxiliary/LinkBase';
import { isDefined } from '../../services/utils';
import { DeletionModalContent } from '../modals/DeletionModalContent';
import { Modal } from '../modals/Modal';

export const DeletionLink = ({
  id,
  onSubmit,
  href,
  hidden,
  disabled,
  hint = '',
  style = {},
  modelName = I18n.t('default_model_name'),
  modal = true,
  children = isDefined(modelName)
    ? I18n.t('delete_resource_link', { model_name: modelName })
    : null,
  withIcon = false,
  marginClass = 'ml-1',
  modalBody,
  modalWarning,
  additionalModalContent,
  modalConfirmationButtonText,
}) => {
  const modalRef = useRef(null);
  const onLinkClick = useCallback(
    e => {
      if (disabled) e.preventDefault();
      else if (modal) modalRef.current.open();
      else onSubmit(e);
    },
    [disabled, modal, onSubmit],
  );
  const linkContent = useMemo(
    () => (
      <LinkBase
        id={id}
        onClick={onLinkClick}
        href={href}
        hidden={hidden}
        disabled={disabled}
        className={disabled ? '' : 'text-danger'}
        style={style}
      >
        {withIcon && (
          <>
            <i className="fas fa-trash" />{' '}
          </>
        )}
        {children}
      </LinkBase>
    ),
    [id, onLinkClick, href, hidden, disabled, style, withIcon, children],
  );

  return (
    <div className={marginClass}>
      {hint === '' ? (
        linkContent
      ) : (
        // Following approach with HTML attribute in title prop provided because of https://github.com/tvkhoa/react-tippy/issues/17
        <Tooltip arrow title={`<p>${hint}</p>`}>
          {linkContent}
        </Tooltip>
      )}
      {modal ? (
        <Modal ref={modalRef}>
          <DeletionModalContent
            modalRef={modalRef}
            onSubmit={onSubmit}
            modelName={modelName}
            warning={modalWarning}
            additionalContent={additionalModalContent}
            confirmationButtonText={modalConfirmationButtonText}
          >
            {modalBody}
          </DeletionModalContent>
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};
