import React, { useCallback, useRef, useEffect, useState, useMemo } from 'react';
import { get, remove, snakeCase } from 'lodash';
import { Modal } from '../modals/Modal';
import { ManageColumnsModalContent } from './ManageColumnsModalContent';
import { API } from '../../services/api';
import { Loader } from '../Loader';
import { DarkLink } from '../links/DarkLink';

export const ManageColumnsLink = props => {
  const { model, refreshTable, fixedFiltersFields } = props;
  const modalRef = useRef(null);
  const [fetching, setFetching] = useState(false);
  const [columns, setColumns] = useState([]);
  const [dynamicColumns, setDynamicColumns] = useState([]);

  const fetchConfig = useCallback(async () => {
    const { ok, data } = await API.cfgIndexPages.show(model);
    if (ok) {
      setColumns(get(data, 'data.attributes.columns', []));
      setDynamicColumns(get(data, 'data.attributes.dynamic_columns', []));
      setFetching(false);
    }
  }, [model]);
  const onClick = useCallback(async () => {
    modalRef.current.open();
    setFetching(true);
  }, [model]);
  useEffect(() => {
    if (fetching) fetchConfig();
  }, [fetching]);

  const visibleColumns = useMemo(
    () => columns.filter(column => !fixedFiltersFields.includes(column.name)),
    [columns, fixedFiltersFields],
  );
  const modelName = useMemo(() => I18n.t(`activerecord.models.${snakeCase(model)}`), [model]);

  return (
    <>
      <DarkLink onClick={onClick}>{I18n.t('form.header.manage_columns')}</DarkLink>
      <Modal
        style={{ content: { maxWidth: '500px' } }}
        title={I18n.t('form.header.manage_columns')}
        subTitle={I18n.t('form.header.manage_columns_modal.subtitle', { model_name: modelName })}
        ref={modalRef}
      >
        {modalProps =>
          fetching ? (
            <Loader />
          ) : (
            <ManageColumnsModalContent
              {...modalProps}
              model={model}
              onSuccess={refreshTable}
              columns={visibleColumns}
              dynamicColumns={dynamicColumns}
            />
          )
        }
      </Modal>
    </>
  );
};
