import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { FastField, FieldArray } from 'formik';
import { ModalSection } from '../../../../../components/modals/ModalSection';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../../../components/form/FormFooter';
import { LinkBase } from '../../../../../components/links/auxiliary/LinkBase';
import { defaultHandleSubmit, isDefined } from '../../../../../services/utils';
import { CancellationLink } from '../../../../../components/links/CancellationLink';
import { BrandButton } from '../../../../../components/buttons/BrandButton';
import { DefaultLink } from '../../../../../components/links/DefaultLink';
import { AsyncSelectorField } from '../../../../../components/fields/AsyncSelectorField';
import { fieldDisplayModes } from '../../../../../constants/fieldDisplayModes';
import { API } from '../../../../../services/api';
import { htmlOptionRenderer } from '../../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { ConditionExpectedAnswer } from './components/ConditionExpectedAnswer';
import { LogicalOperatorType } from './components/LogicalOperatorType';
import { FieldHeader, StyledParagraph } from './styles';
import { ConditionsExplanation } from './components/ConditionsExplanation';
import { Modal } from '../../../../../components/modals/Modal';
import { RemoveAllConditionsModalContent } from './components/RemoveAllConditionsModalContent';
import { colors } from '../../../../../../Style/colors';
import { fontSize } from '../../../../../assets/styles/typography';
import { getErrorOutsideField } from '../../../../../services/fieldUtils';

const AddConditionLinkWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const FormSectionError = styled.span`
  color: ${colors.required};
  font-size: ${fontSize.textSm};
`;

const t = (key, params) =>
  I18n.t(
    `administration.inspection_types.new_edit.checklist.question_details_modal.${key}`,
    params,
  );

const ConditionFieldWrapper = ({
  children,
  hideRemoveConditionLink,
  handleRemoveCondition,
  error,
}) => (
  <>
    <div className="w-100 mb-2 row d-flex align-items-center">
      <div className="col-12 col-md-11">{children}</div>
      <div className="col-12 col-md-1">
        <LinkBase
          onClick={handleRemoveCondition}
          hidden={hideRemoveConditionLink}
          className="text-danger"
        >
          {t('remove_condition')}
        </LinkBase>
      </div>
    </div>
    {error && <FormSectionError>{error}</FormSectionError>}
  </>
);

const ConditionExpectedAnswerWrapper = ({ children, error }) => (
  <>
    <div className="w-100 mb-3 row">
      <div className="col-12 col-md-11">{children}</div>
    </div>
    {error && <FormSectionError>{error}</FormSectionError>}
  </>
);

export const Form = props => {
  const {
    values: {
      id,
      inspection_type_id,
      visibilityConditions,
      inspection_finding_definition_name,
      logical_operator_type,
    },
    isSubmitting,
    setFieldValue,
    handleSubmit,
    hide,
    touched,
    errors,
    anyRelatedInspections,
  } = props;
  const [hoveredConditionIndex, setHoveredConditionIndex] = useState(null);
  const removeAllConditionsModalRef = useRef(null);

  return (
    <form>
      <ModalSection>
        <StyledParagraph>
          {visibilityConditions.length > 0
            ? t('subtitle_with_conditions', {
                question_name: inspection_finding_definition_name,
              })
            : t('subtitle_without_conditions', {
                question_name: inspection_finding_definition_name,
              })}
        </StyledParagraph>
        <FieldArray
          name="visibilityConditions"
          render={arrayHelpers => (
            <>
              {visibilityConditions.map((condition, index) => (
                <div
                  onMouseOver={() => {
                    setHoveredConditionIndex(index);
                  }}
                  onMouseLeave={() => {
                    setHoveredConditionIndex(null);
                  }}
                >
                  <FieldHeader>{t('condition_header', { index: index + 1 })}</FieldHeader>
                  <ConditionFieldWrapper
                    hideRemoveConditionLink={index !== hoveredConditionIndex}
                    handleRemoveCondition={() => {
                      arrayHelpers.remove(index);
                    }}
                    error={getErrorOutsideField(
                      `visibilityConditions[${index}].leader_id`,
                      touched,
                      errors,
                    )}
                  >
                    <FastField
                      name={`visibilityConditions[${index}].leader_id`}
                      resourceName={I18n.t('resource_names.inspection_finding_definitions_types')}
                      api={API.selectors.inspectionFindingDefinitionsTypes.index}
                      apiParams={{
                        inspection_type_id,
                        without_id: id,
                      }}
                      selectionApi={API.selectors.inspectionFindingDefinitionsTypes.show}
                      optionRenderer={htmlOptionRenderer('text')}
                      onChange={async (event, _) => {
                        await setFieldValue(`visibilityConditions[${index}]`, {
                          follower_id: condition.follower_id,
                          leader_id: event.data.id,
                          answer_type: event.data.answer_type,
                          configuration: event.data.configuration,
                          leader_finding_definition_name: event.data.leader_finding_definition_name,
                          leader_expected_answer: '',
                        });
                      }}
                      component={AsyncSelectorField}
                      displayMode={fieldDisplayModes.NO_WRAPPER}
                    />
                  </ConditionFieldWrapper>
                  <ConditionExpectedAnswerWrapper
                    error={
                      isDefined(condition.leader_id) &&
                      getErrorOutsideField(
                        `visibilityConditions[${index}].leader_expected_answer`,
                        touched,
                        errors,
                      )
                    }
                  >
                    {condition.leader_id && condition.answer_type && (
                      <ConditionExpectedAnswer
                        index={index}
                        answer={condition.leader_expected_answer}
                        setFieldValue={setFieldValue}
                        answerType={condition.answer_type}
                        configuration={condition.configuration}
                      />
                    )}
                  </ConditionExpectedAnswerWrapper>
                </div>
              ))}
              <AddConditionLinkWrapper>
                <DefaultLink
                  onClick={() =>
                    arrayHelpers.push({
                      follower_id: id,
                      leader_id: '',
                      leader_expected_answer: '',
                    })
                  }
                >
                  {t('add_condition')}
                </DefaultLink>
              </AddConditionLinkWrapper>
              {visibilityConditions.length > 1 && (
                <LogicalOperatorType
                  onClick={value => {
                    setFieldValue('logical_operator_type', value);
                  }}
                  logicalOperatorType={logical_operator_type}
                />
              )}
              <ConditionsExplanation
                questionName={inspection_finding_definition_name}
                visibilityConditions={visibilityConditions}
                logicalOperatorType={logical_operator_type}
              />
              {anyRelatedInspections && (
                <StyledParagraph>
                  {I18n.t(
                    'administration.inspection_types.new_edit.footer.related_inspection_warning_modal.warning',
                  )}
                </StyledParagraph>
              )}
            </>
          )}
        />
        <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
          <FormFooterLeftPart>
            <LinkBase
              onClick={() => removeAllConditionsModalRef.current.open()}
              hidden={visibilityConditions.length === 0}
              className="text-danger"
            >
              {t('remove_all_conditions')}
            </LinkBase>
          </FormFooterLeftPart>
          <FormFooterRightPart>
            <CancellationLink onClick={hide} />
            <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
              {t('save')}
            </BrandButton>
          </FormFooterRightPart>
        </FormFooter>
        <Modal
          ref={removeAllConditionsModalRef}
          wrapperStyle={{ maxWidth: '700px', margin: '3rem auto' }}
        >
          {modalProps => (
            <RemoveAllConditionsModalContent
              setFieldValue={setFieldValue}
              handleSubmit={handleSubmit}
              {...modalProps}
            />
          )}
        </Modal>
      </ModalSection>
    </form>
  );
};
