import React, { useRef, useState } from 'react';
import { FormSection } from '../../../../components/form/FormSection';
import { FormSectionHeader } from '../../../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../../../components/form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../../../components/form/FormSectionHeaderLinks';
import { AsyncSimpleList } from '../../../../components/lists/AsyncSimpleList';
import { Modal } from '../../../../components/modals/Modal';
import { API } from '../../../../services/api';
import {
  SimpleListDeletionLink,
  SimpleListLink,
} from '../../../../components/lists/SimpleListLinks';
import {
  handleSubmitWithAfterSave,
  isDefined,
  showBackendErrorMessage,
  showSuccessMessage,
  valueWithUnit,
} from '../../../../services/utils';
import { EmptyListPlaceholder } from '../../../../components/lists/EmptyListPlaceholder';
import { dateFormatter } from '../../../../services/dateFormatUtils';
import { AddRadLicenseItemAssignmentModalContent } from './index';

const t = key => I18n.t(`radiation_safety.rad_permits.lists.rad_license_item_assignments.${key}`);
const modalTranslation = key =>
  I18n.t(`radiation_safety.rad_permits.modals.rad_license_item_assignments.${key}`);

export const RadLicenseItemAssignments = props => {
  const {
    isSubmitting,
    handleSubmit,
    setFieldValue,
    values: { id: radPermitId, rad_license_id },
  } = props;
  const listRef = useRef(null);
  const addRadLicenseItemAssignmentModalRef = useRef(null);
  const [radLicenseItemAssignment, setRadLicenseItemAssignment] = useState({});
  const [modalTitle, setModalTitle] = useState('');

  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{t('title')}</FormSectionTitle>
          <FormSectionHeaderLink
            disabled={isSubmitting}
            onClick={async () => {
              const afterSave = async newId => {
                const { ok, data } = await API.radiationSafety
                  .radLicenseItemAssignments(newId)
                  .new();
                if (ok) {
                  await setRadLicenseItemAssignment(data.data.attributes);
                  await setModalTitle(modalTranslation('title.new'));
                  await addRadLicenseItemAssignmentModalRef.current.open();
                }
              };
              await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
              setRadLicenseItemAssignment({});
            }}
          >
            {t('add_rad_license_item')}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {!isDefined(radPermitId) && <EmptyListPlaceholder />}
        {isDefined(radPermitId) && (
          <AsyncSimpleList
            api={API.radiationSafety.radLicenseItemAssignments(radPermitId).index}
            ref={listRef}
            dataKey="data"
            resourceName={I18n.t('resource_names.rad_license_item')}
            mapData={({ attributes }) => [
              [t('label'), attributes.label],
              [
                t('activity_limit'),
                `${valueWithUnit(attributes.activity_limit, attributes.activity_unit_code, '∞')}`,
              ],
              [
                t('quantity_limit'),
                `${valueWithUnit(attributes.quantity_limit, attributes.quantity_unit_code, '∞')}`,
              ],
              [t('purchase_number_limit'), attributes.purchase_number_limit],
              [t('start_date'), dateFormatter.date(attributes.start_date)],
              [t('closed_date'), dateFormatter.date(attributes.closed_date)],
              <SimpleListLink
                key="edit"
                disabled={isSubmitting}
                onClick={async () => {
                  const { ok, data } = await API.radiationSafety
                    .radLicenseItemAssignments(radPermitId)
                    .show(attributes.id);
                  if (ok) {
                    await setRadLicenseItemAssignment(data.data.attributes);
                    await setModalTitle(modalTranslation('title.edit'));
                    await handleSubmitWithAfterSave(
                      setFieldValue,
                      handleSubmit,
                      addRadLicenseItemAssignmentModalRef.current.open,
                    );
                  } else {
                    showBackendErrorMessage(t('flash.error.save'), data);
                  }
                }}
              >
                {t('edit')}
              </SimpleListLink>,
              <SimpleListDeletionLink
                key="delete"
                modelName={t('model_name')}
                onSubmit={async () => {
                  const { ok, data } = await API.radiationSafety
                    .radLicenseItemAssignments(radPermitId)
                    .destroy(attributes.id);
                  if (ok) {
                    listRef.current.refresh();
                    showSuccessMessage(t('flash.success.remove'));
                  } else {
                    showBackendErrorMessage(t('flash.error.remove'), data);
                  }
                }}
              >
                {t('remove.title')}
              </SimpleListDeletionLink>,
            ]}
          />
        )}
      </FormSection>
      <Modal title={modalTitle} ref={addRadLicenseItemAssignmentModalRef}>
        {modalProps => (
          <AddRadLicenseItemAssignmentModalContent
            radPermitId={radPermitId}
            radLicenseId={rad_license_id}
            radLicenseItemAssignment={radLicenseItemAssignment}
            listRef={listRef}
            {...modalProps}
            {...props}
          />
        )}
      </Modal>
    </>
  );
};
