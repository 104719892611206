import React from 'react';
import { FastField } from 'formik';
import { isDefined } from '../../../services/utils';
import { FormHeader } from '../../../components/form/FormHeader';
import {
  defaultShouldUpdate,
  mapToSelectorOptions,
  produceShouldUpdate,
  sortFilesZA,
} from '../../../services/fieldUtils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { DateField } from '../../../components/fields/DateField';
import { SelectorField } from '../../../components/fields/SelectorField';
import { FilesField } from '../../../components/fields/FilesField';
import { LinksField } from '../../../components/fields/LinksField';
import { Footer } from './Footer';
import { NumericAndUnitFields } from '../../../components/fields/compositeFields/NumericAndUnitFields';

const t = key => I18n.t(`radiation_safety.rad_purchases.new_edit.${key}`);

const setLicenseItemAssignmentRelatedFields = async (values, setFieldValue) => {
  const {
    activity_unit_id,
    quantity_unit_id,
    activity_limit,
    remaining_activity,
    remaining_quantity,
  } = values.data;

  await setFieldValue('activityUnitId', activity_unit_id);
  await setFieldValue('quantityUnitId', quantity_unit_id);
  await setFieldValue('activityLimit', activity_limit);
  await setFieldValue('remainingActivity', remaining_activity);
  await setFieldValue('remainingQuantity', remaining_quantity);
};

export const Form = props => {
  const {
    setFieldValue,
    values: {
      id,
      rad_permit_id,
      remainingQuantity,
      remainingActivity,
      purchased_activity,
      purchased_quantity,
      rad_license_item_assignment_id,
    },
    enums,
  } = props;

  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)}
        auditsLink={isDefined(id) ? `/radiation_safety/rad_purchases/${id}/audits` : ''}
      />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.rad_purchase',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.rad_purchase'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="identifier" disabled component={TextField} />
          <FastField
            name="rad_permit_id"
            required
            api={API.selectors.radPermits.index}
            selectionApi={API.selectors.radPermits.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
            onChange={async (newSelection, onChange) => {
              await onChange(newSelection);
              await setFieldValue('rad_user_id', null);
            }}
          />
          <FastField
            name="rad_user_id"
            required
            disabled={!rad_permit_id}
            api={API.selectors.radUsers.index}
            selectionApi={API.selectors.radUsers.show}
            apiParams={{ rad_permit_id }}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('text')}
            component={AsyncSelectorField}
            shouldUpdate={produceShouldUpdate([], [], ['rad_permit_id'])}
          />
          <FastField name="po_number" component={TextField} />
          <FastField name="po_date" component={DateField} />
          <FastField
            name="vendor_id"
            api={API.selectors.vendors.index}
            selectionApi={API.selectors.vendors.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField name="comment" component={TextField} />
          <FastField
            name="order_contact_id"
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
        </FormSection>
        <FormSection title={t('sections.product_information')}>
          <FastField
            name="rad_license_item_assignment_id"
            required
            disabled={!rad_permit_id}
            api={API.selectors.radLicenseItemAssignments.index}
            selectionApi={API.selectors.radLicenseItemAssignments.show}
            apiParams={{ rad_permit_id, with_remaining_limits: true }}
            shouldUpdate={produceShouldUpdate([], [], ['rad_permit_id'])}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={htmlOptionRenderer('text')}
            onChange={async (newSelection, onChange) => {
              await onChange(newSelection);
              await setLicenseItemAssignmentRelatedFields(newSelection, setFieldValue);
            }}
            component={AsyncSelectorField}
          />
          <FastField
            name="activityLimit"
            disabled
            component={NumericAndUnitFields}
            label={t('additional_fields.activity_limit')}
            unitName="activityUnitId"
            unitApiParams={{ um_type: ['radioactivity'] }}
          />
          <FastField
            name="remainingActivity"
            disabled
            component={NumericAndUnitFields}
            label={t('additional_fields.remaining_activity')}
            unitName="activityUnitId"
            unitApiParams={{ um_type: ['radioactivity'] }}
          />
          <FastField
            name="remainingQuantity"
            disabled
            component={NumericAndUnitFields}
            label={t('additional_fields.remaining_quantity')}
            unitName="quantityUnitId"
            shouldUpdate={produceShouldUpdate([], [], ['rad_license_item_assignment_id'])}
            placeholder={rad_license_item_assignment_id ? '∞' : undefined}
            unitProps={{ placeholder: undefined }}
            unitApiParams={{ um_type: ['mass'] }}
          />
          <FastField
            name="purchased_activity"
            required
            component={NumericAndUnitFields}
            unitName="activityUnitId"
            shouldUpdate={produceShouldUpdate([], [], ['remainingActivity'])}
            warning={
              isDefined(remainingActivity) &&
              isDefined(purchased_activity) &&
              Number(remainingActivity) < Number(purchased_activity) &&
              t('warnings.cannot_be_more_than_remaining_activity')
            }
            unitProps={{ disabled: true, required: false }}
            unitApiParams={{ um_type: ['radioactivity'] }}
          />
          <FastField
            name="purchased_quantity"
            required
            component={NumericAndUnitFields}
            unitName="quantityUnitId"
            shouldUpdate={produceShouldUpdate([], [], ['remainingQuantity'])}
            warning={
              isDefined(remainingQuantity) &&
              isDefined(purchased_quantity) &&
              Number(remainingQuantity) < Number(purchased_quantity) &&
              t('warnings.cannot_be_more_than_remaining_quantity')
            }
            unitProps={{ disabled: true, required: false }}
            unitApiParams={{ um_type: ['mass'] }}
          />
          <FastField name="catalogue_number" component={TextField} />
          <FastField
            name="rad_compound_id"
            api={API.selectors.radCompounds.index}
            selectionApi={API.selectors.radCompounds.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField name="date_calibration" component={DateField} />
        </FormSection>
        <FormSection title={t('sections.receipt_of_goods')}>
          <FastField name="date_received" component={DateField} />
          <FastField
            name="rad_purchase_status_id"
            required
            api={API.selectors.radPurchaseStatuses.index}
            selectionApi={API.selectors.radPurchaseStatuses.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField name="date_disposed" component={DateField} />
          <FastField name="packing_slip_number" component={TextField} />
          <FastField
            name="attachments"
            disabled={false}
            component={FilesField}
            sortPredicate={sortFilesZA}
          />
          <FastField name="links" disabled={false} component={LinksField} />
        </FormSection>
      </DefaultAttributesGroup>
      <Footer {...props} />
    </form>
  );
};
