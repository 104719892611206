import React, { PureComponent } from 'react';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import Select, { components } from 'react-select-next';
import debounce from 'lodash/fp/debounce';
import { FieldWrapper } from './auxiliary/FieldWrapper';
import { fieldWrapperProps } from '../../services/fieldUtils';
import { selectorCustomStyles } from './auxiliary/selectorCustomStyles';
import { defaultOptionRenderer } from './selectorOptionRenderers/defaultOptionRenderer';

export class AsyncMultiSelectorField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      nextPage: 1,
      fetching: false,
      firstPageLoaded: false,
      selection: [],
      needle: '',
    };
    this.fetchNextPage = this.fetchNextPage.bind(this);
  }

  componentDidMount() {
    this.prepareSelection();
  }

  componentDidUpdate(prevProps, prevState, _) {
    if (
      prevProps.api !== this.props.api ||
      JSON.stringify(prevProps.apiParams) !== JSON.stringify(this.props.apiParams)
    ) {
      this.resetOptions();
    }
    if (JSON.stringify(prevProps.field.value) !== JSON.stringify(this.props.field.value)) {
      this.prepareSelection();
    }
  }

  resetOptions(needle = '', cb = () => {}) {
    this.setState(
      {
        options: [],
        nextPage: 1,
        fetching: false,
        firstPageLoaded: false,
        needle,
      },
      cb,
    );
  }

  getSelectionDataFromCurrentSelection(id) {
    const { selection } = this.state;
    if (selection) {
      return get(selection.find(s => s.value === id), 'data');
    }
    return undefined;
  }

  getSelectionDataFromInitialSelectionData(id) {
    const {
      field: { name },
      form: { initialValues },
      initialSelectionData,
    } = this.props;
    const initialSelectorValues = initialValues[name];
    if (initialSelectionData) {
      return initialSelectionData[initialSelectorValues.findIndex(v => v === id)];
    }
    return undefined;
  }

  getSelectionFromApi(id, index) {
    const { selectionApi } = this.props;
    if (selectionApi && (id !== '' && !isNil(id))) {
      selectionApi(id).then(({ ok, data }) => {
        if (ok) {
          this.setState({
            selection: [
              ...this.state.selection.slice(0, index),
              { value: id, data },
              ...this.state.selection.slice(index + 1),
            ],
          });
        }
      });
    }
    return {};
  }

  prepareSelection() {
    const {
      field: { value },
    } = this.props;
    const newSelection = value.map((id, index) => {
      const selectionData =
        this.getSelectionDataFromCurrentSelection(id) ||
        this.getSelectionDataFromInitialSelectionData(id) ||
        this.getSelectionFromApi(id, index);
      return { value: id, data: selectionData };
    });
    this.setState({ selection: newSelection });
  }

  fetchNextPage() {
    const { api, apiParams, valueKey } = this.props;
    const { nextPage, options, fetching, needle } = this.state;
    if (!nextPage || fetching) {
      return;
    }
    this.setState({ fetching: true }, async () => {
      const { ok, data } = await api({ ...apiParams, page: nextPage, q: needle });
      if (ok) {
        const newOptions = data.results.map(r => ({
          value: r[valueKey],
          data: r,
        }));
        this.setState({
          firstPageLoaded: true,
          nextPage: data.pagination.more ? nextPage + 1 : undefined,
          options: options.concat(newOptions),
          fetching: false,
        });
      }
    });
  }

  // needed to fix a bug regarding first pagination of react-select Selector
  MenuRenderer = props => {
    const { firstPageLoaded } = this.state;
    const key = firstPageLoaded ? 'menu-without-options' : 'menu-with-options';
    return <components.Menu {...props} key={key} />;
  };

  render() {
    const {
      field: { name },
      form: { setFieldValue, setFieldTouched },
      disabled,
      onChange,
      onBlur,
      placeholder,
      optionRenderer = defaultOptionRenderer('label'),
      headerRenderer = optionRenderer,
      resourceName,
      id,
    } = this.props;
    const { nextPage, options, fetching, selection } = this.state;

    const inputId = id || `${resourceName}-${name}`;

    const defaultOnChange = async selections => {
      await setFieldValue(name, selections.map(s => s.value));
      await setFieldTouched(name, true);
    };
    const defaultOnBlur = async () => {
      await setFieldTouched(name, true);
    };

    const onInputChange = needle => this.resetOptions(needle, this.fetchNextPage);

    const debouncedOnInputChange = debounce(600, onInputChange);

    return (
      <FieldWrapper {...fieldWrapperProps({ inputId, ...this.props })}>
        <div style={{ width: '100%' }} id={inputId}>
          <Select
            value={selection}
            classNamePrefix={inputId}
            options={options}
            styles={selectorCustomStyles}
            isDisabled={disabled}
            placeholder={placeholder}
            menuPlacement="auto"
            onChange={newSelection => {
              this.setState({ selection: newSelection }, () => {
                onChange ? onChange(newSelection, defaultOnChange) : defaultOnChange(newSelection);
              });
            }}
            onBlur={e => {
              onBlur ? onBlur(e, defaultOnBlur) : defaultOnBlur();
            }}
            isLoading={fetching}
            filterOption={() => true}
            onInputChange={debouncedOnInputChange}
            onMenuOpen={() => nextPage === 1 && this.fetchNextPage()}
            onMenuScrollToBottom={this.fetchNextPage}
            formatOptionLabel={(option, { context }) =>
              context === 'value' ? headerRenderer(option.data) : optionRenderer(option.data)
            }
            components={{ Menu: this.MenuRenderer }}
            isMulti
          />
        </div>
      </FieldWrapper>
    );
  }
}

AsyncMultiSelectorField.defaultProps = {
  valueKey: 'id',
};
