import React, { useState, useEffect } from 'react';
import { Loader } from '../../components/Loader';
import { GeneralRequestForm } from './Form';
import { isDefined } from '../../services/utils';
import { API } from '../../services/api';

export const GeneralRequest = props => {
  const [fetching, setFetching] = useState(true);
  const [generalRequest, setGeneralRequest] = useState({});
  useEffect(() => {
    (async () => {
      const api = isDefined(props.id)
        ? API.requests.generalRequests.show
        : API.requests.generalRequests.new;
      const { ok, data } = await api(props.id);
      if (ok) {
        await setGeneralRequest(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <GeneralRequestForm {...props} generalRequest={generalRequest} />;
};
export default GeneralRequest;
