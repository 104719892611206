import React from 'react';

export const RangeSelect = props => {
  const { id, onChange, configuration, answer } = props;
  const rangeLabel = _range => `${_range.min_value}-${_range.max_value}`;

  return (
    <div className="checklist_questions_table">
      <div
        className="range-button-group yes-no-container btn-group btn-group-toggle"
        data-toggle="buttons"
      >
        {configuration.ranges.map((range, index) => {
          if (!range.min_value || !range.max_value) return null;

          return (
            <button
              id={id}
              type="button"
              onClick={() => onChange(rangeLabel(range))}
              key={`${index}-${rangeLabel(range)}`}
              className={`col btn btn-sm range-select-btn yes-no-btn ${
                answer === rangeLabel(range) ? 'active' : ''
              }`}
              style={{ background: range.background, color: range.color || 'white' }}
            >
              <input
                autoComplete="off"
                type="radio"
                value={rangeLabel(range)}
                checked={answer === rangeLabel(range)}
              />
              <span>{rangeLabel(range)}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};
