import React, { useState, useEffect } from 'react';
import { isDefined } from '../../services/utils';
import { API } from '../../services/api';
import { Loader } from '../../components/Loader';
import { WasteGeneratorForm } from './Form';

export const WasteGenerator = props => {
  const [fetching, setFetching] = useState(true);
  const [wasteGenerator, setWasteGenerator] = useState({});

  useEffect(() => {
    (async () => {
      const api = isDefined(props.id)
        ? API.administration.wasteGenerators.show
        : API.administration.wasteGenerators.new;
      const { ok, data } = await api(props.id);
      if (ok) {
        await setWasteGenerator(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <WasteGeneratorForm wasteGenerator={wasteGenerator} {...props} />;
};
export default WasteGenerator;
