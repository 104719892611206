import React from 'react';

const produceOnClick = onClick => e => {
  e.preventDefault();
  onClick(e);
};

export const LightButton = ({ children, onClick, ...props }) => (
  <button className="btn btn-bg btn-light" onClick={produceOnClick(onClick)} {...props}>
    {children}
  </button>
);
