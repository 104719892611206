import React from 'react';
import { FastField } from 'formik';
import { DefaultAttributesGroup } from '../../../../components/form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../../constants/fieldDisplayModes';
import { ModalSection } from '../../../../components/modals/ModalSection';
import { API } from '../../../../services/api';
import { htmlOptionRenderer } from '../../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../../components/fields/AsyncSelectorField';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../../components/form/FormFooter';
import { DeletionLink } from '../../../../components/links/DeletionLink';
import {
  defaultHandleSubmit,
  showSuccessMessage,
  showBackendErrorMessage,
} from '../../../../services/utils';
import { CancellationLink } from '../../../../components/links/CancellationLink';
import { BrandButton } from '../../../../components/buttons/BrandButton';
import { NumericField } from '../../../../components/fields/NumericField';

const t = key => I18n.t(`requests.waste_requests.new_edit.modals.waste_request_containers.${key}`);

export const WasteRequestContainerForm = props => {
  const {
    values: { id },
    hide,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    wasteRequestId,
    listRef,
  } = props;
  return (
    <form>
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          displayMode: fieldDisplayModes.WIDE_FORM_ROW,
          labelI18nKeyPrefix: 'activerecord.attributes.waste_request_container',
          resourceName: I18n.t('resource_names.waste_request_container'),
        }}
      >
        <ModalSection>
          <FastField
            name="container_type_id"
            required
            api={API.selectors.containerTypes.index}
            selectionApi={API.selectors.containerTypes.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField name="quantity" required component={NumericField} />
          <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
            <FormFooterLeftPart>
              <DeletionLink
                withIcon
                hidden={!id}
                modelName={I18n.t('activerecord.models.waste_request_container')}
                onSubmit={async () => {
                  const { ok, data } = await API.requests.wasteRequests
                    .wasteRequestContainers(wasteRequestId)
                    .destroy(id);
                  if (ok) {
                    showSuccessMessage(t('flash.success.remove'));
                    hide();
                    listRef.current.refresh();
                  } else {
                    showBackendErrorMessage(t('flash.error.remove'), data);
                  }
                }}
              />
            </FormFooterLeftPart>
            <FormFooterRightPart>
              <CancellationLink onClick={hide} />
              <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
                {t('footer.save')}
              </BrandButton>
            </FormFooterRightPart>
          </FormFooter>
        </ModalSection>
      </DefaultAttributesGroup>
    </form>
  );
};
