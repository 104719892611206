import React, { useEffect, useState } from 'react';
import { isDefined } from '../../services/utils';
import { API } from '../../services/api';
import { Loader } from '../../components/Loader';
import { RadInventoryItemForm } from './Form';

export const RadInventoryItem = props => {
  const [fetching, setFetching] = useState(true);
  const [radInventoryItem, setRadInventoryItem] = useState({});
  useEffect(() => {
    (async () => {
      const apiBase = API.radiationSafety.radInventoryItems(props.radInventoryId);
      const api = isDefined(props.id)
        ? apiBase.show
        : () => apiBase.new({ rad_purchase_id: props.radPurchaseId });
      const { ok, data } = await api(props.id);
      if (ok) {
        await setRadInventoryItem(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <RadInventoryItemForm radInventoryItem={radInventoryItem} />;
};
export default RadInventoryItem;
