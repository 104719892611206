import React, { useState, useEffect } from 'react';
import { Loader } from '../../components/Loader';
import { CampusForm } from './Form';
import { isDefined } from '../../services/utils';
import { API } from '../../services/api';

export const Campus = props => {
  const [fetching, setFetching] = useState(true);
  const [campus, setCampus] = useState({});
  useEffect(() => {
    (async () => {
      const api = API.locations.campuses[isDefined(props.id) ? 'show' : 'new'];
      const { ok, data } = await api(props.id);
      if (ok) {
        await setCampus(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <CampusForm {...props} campus={campus} />;
};
export default Campus;
