import { withFormik } from 'formik';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
  updateBreadcrumbs,
} from '../../../services/utils';
import { API } from '../../../services/api';
import { Form } from './Form';
import { validationSchema } from './validationSchema';
import { applyHalfLife } from '../utils';

const t = key => I18n.t(`radiation_safety.rad_inventory_items.new_edit.${key}`);

const RadInventoryItemFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const item = RadInventoryItemFunctions.prepareValuesToSubmit({ ...values });
    if (!isDefined(item.id)) RadInventoryItemFunctions.create(item, formikProps, values);
    else RadInventoryItemFunctions.update(item, formikProps, values);
  },
  create: async (item, formikProps, values) => {
    const { afterSave, radInventoryId } = values;
    const { ok, data } = await API.radiationSafety.radInventoryItems(radInventoryId).create({
      rad_inventory_item: item,
    });
    if (ok) {
      window.history.pushState(
        '',
        '',
        `/radiation_safety/rad_inventories/${radInventoryId}/rad_inventory_items/${data.data.id}/edit${window.location.search}`,
      );
      updateBreadcrumbs(I18n.t('breadcrumbs.radiation_safety.rad_inventory_items.edit.title'));
      formikProps.resetForm({
        values: RadInventoryItemFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (item, formikProps, values) => {
    const { afterSave, id, radInventoryId, submitType } = values;
    const apiBase = API.radiationSafety.radInventoryItems(radInventoryId);
    let apiMethod = apiBase.update;
    if (submitType === 'dispose') apiMethod = apiBase.dispose;
    else if (submitType === 'reopen') apiMethod = apiBase.reopen;
    const { ok, data } = await apiMethod(id, { rad_inventory_item: item });
    if (ok) {
      formikProps.resetForm({
        values: RadInventoryItemFunctions.prepareInitialValues(data.data.attributes),
      });
      afterSave(data.data.id);
      showSuccessMessage(t(`flash.success.${submitType}`));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t(`flash.error.${submitType}`), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: radInventoryItem => {
    const {
      disposed,
      rad_inventory_id,
      half_life_days,
      started_at,
      last_limits_related_change_at,
      initial_activity,
      initial_quantity,
      activity_after_last_change,
      quantity_after_last_change,
    } = radInventoryItem;

    const remainingActivityBase = activity_after_last_change || initial_activity;
    const remainingQuantityBase = quantity_after_last_change || initial_quantity;
    const remainingValuesDecayStartAt = last_limits_related_change_at || started_at;

    const remainingActivity = applyHalfLife(
      remainingActivityBase,
      half_life_days,
      remainingValuesDecayStartAt,
    );

    const remainingQuantity = applyHalfLife(
      remainingQuantityBase,
      half_life_days,
      remainingValuesDecayStartAt,
    );
    return {
      afterSave: () => {},
      prepareInitialValues: RadInventoryItemFunctions.prepareInitialValues,
      disposed,
      radInventoryId: rad_inventory_id,
      submitType: 'save',
      remainingActivityBase,
      remainingQuantityBase,
      remainingValuesDecayStartAt,
      remainingActivity,
      remainingQuantity,
    };
  },
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'identifier',
    'alternate_identifier',
    'rad_permit_id',
    'rad_purchase_id',
    'rad_inventory_item_status_id',
    'rad_license_item_assignment_id',
    'rad_compound_id',
    'rad_item_type_id',
    'rad_form_id',
    'half_life_days',
    'started_at',
    'initial_activity',
    'initial_quantity',
    'activity_unit_id',
    'quantity_unit_id',
    'activity_unit_code',
    'quantity_unit_code',
    'withdrawals_count',
  ],
});

export const RadInventoryItemForm = withFormik({
  mapPropsToValues: props => RadInventoryItemFunctions.prepareInitialValues(props.radInventoryItem),
  handleSubmit: RadInventoryItemFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(Form);
