import { withFormik } from 'formik';
import { EmergencyContactProfileForm } from './Form';
import {
  isDefined,
  prepareFormFunctions,
  prepareHasManyRelationToSubmit,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { API } from '../../../../services/api';
import { validationSchema } from './validationSchema';

const t = key => I18n.t(`hsds.emergency_contact_profiles.${key}`);

const EmergencyContactProfileFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const emergencyContactProfile = EmergencyContactProfileFunctions.prepareValuesToSubmit({
      ...values,
    });
    if (!isDefined(emergencyContactProfile.id)) {
      EmergencyContactProfileFunctions.create(emergencyContactProfile, formikProps);
    } else {
      EmergencyContactProfileFunctions.update(emergencyContactProfile, formikProps);
    }
  },
  prepareValuesToSubmit: values => {
    values.emergency_contacts_attributes = prepareHasManyRelationToSubmit(
      values.emergency_contacts_attributes,
      values.emergencyContacts,
    );
    return EmergencyContactProfileFunctions.applyBackendValuesWhitelist(values);
  },
  create: async (emergencyContactProfile, formikProps) => {
    const { ok, data } = await API.spaceRiskAssessments.emergencyContactProfiles.create(
      emergencyContactProfile,
    );
    if (ok) {
      formikProps.resetForm({
        values: EmergencyContactProfileFunctions.prepareInitialValues(data.data.attributes),
      });
      formikProps.props.hide();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (emergencyContactProfile, formikProps) => {
    const { ok, data } = await API.spaceRiskAssessments.emergencyContactProfiles.update(
      emergencyContactProfile.id,
      emergencyContactProfile,
    );
    if (ok) {
      formikProps.resetForm({
        values: EmergencyContactProfileFunctions.prepareInitialValues(data.data.attributes),
      });
      formikProps.props.hide();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: emergencyContactProfile => ({
    emergencyContacts: emergencyContactProfile.emergency_contacts_attributes,
  }),
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: [
    'id',
    'name',
    'emergency_contacts_attributes',
  ],
});

export const EmergencyContactProfileModalContent = withFormik({
  mapPropsToValues: props =>
    EmergencyContactProfileFunctions.prepareInitialValues(props.emergencyContactProfile),
  handleSubmit: EmergencyContactProfileFunctions.handleSubmit,
  validate: produceDefaultValidation(validationSchema),
})(EmergencyContactProfileForm);
