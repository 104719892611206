import React, { useRef } from 'react';
import { FormSection } from '../../../components/form/FormSection';
import { FormSectionTitle } from '../../../components/form/FormSectionTitle';
import { FormSectionHeader } from '../../../components/form/FormSectionHeader';
import { FormSectionHeaderLink } from '../../../components/form/FormSectionHeaderLinks';
import {
  handleSubmitWithAfterSave,
  isDefined,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';
import { EmptyListPlaceholder } from '../../../components/lists/EmptyListPlaceholder';
import { AsyncSimpleList } from '../../../components/lists/AsyncSimpleList';
import { API } from '../../../services/api';
import { SimpleListDeletionLink } from '../../../components/lists/SimpleListLinks';
import { AddEquipmentModalContent } from './AddEquipmentModalContent';
import { Modal } from '../../../components/modals/Modal';

const t = key => I18n.t(`safe_work_authorization_measures.${key}`);

export const Equipment = props => {
  const {
    isSubmitting,
    swaId,
    handleSubmit,
    setFieldValue,
    values: { id: swaMeasureId },
  } = props;
  const listRef = useRef(null);
  const addEquipmentModalRef = useRef(null);
  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{t('lists.equipment.title')}</FormSectionTitle>
          <FormSectionHeaderLink
            disabled={isSubmitting}
            onClick={async () => {
              await handleSubmitWithAfterSave(
                setFieldValue,
                handleSubmit,
                addEquipmentModalRef.current.open,
              );
            }}
          >
            {t('lists.equipment.add')}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {!isDefined(swaMeasureId) && <EmptyListPlaceholder />}
        {isDefined(swaMeasureId) && (
          <AsyncSimpleList
            ref={listRef}
            api={API.requests.safeWorkAuthorizations.measures(swaId).equipment(swaMeasureId).index}
            dataKey="data"
            resourceName={I18n.t('resource_names.safe_work_authorization_measure_equipment')}
            mapRowHrefs={({ attributes: { equipmentable } }) => equipmentable.path}
            mapData={({ attributes: { equipmentable }, id }) => [
              [t('lists.equipment.type'), equipmentable.subtype],
              [t('lists.equipment.manufacturer'), equipmentable.manufacturer],
              [t('lists.equipment.identifier'), equipmentable.identifier],
              <SimpleListDeletionLink
                key="remove"
                modelName={t('lists.equipment.model_name')}
                onSubmit={async () => {
                  const { ok, data } = await API.requests.safeWorkAuthorizations
                    .measures(swaId)
                    .equipment(swaMeasureId)
                    .destroy(id);
                  if (ok) {
                    listRef.current.refresh();
                    showSuccessMessage(t('lists.equipment.remove.flash.success'));
                  } else {
                    showBackendErrorMessage(t('lists.equipment.remove.flash.error'), data);
                  }
                }}
              >
                {t('lists.equipment.remove.title')}
              </SimpleListDeletionLink>,
            ]}
          />
        )}
      </FormSection>
      <Modal title={t('modals.add_equipment.title')} ref={addEquipmentModalRef}>
        {modalProps => (
          <AddEquipmentModalContent
            swaId={swaId}
            measureId={swaMeasureId}
            listRef={listRef}
            resourceName={I18n.t('resource_names.safe_work_authorization_measure_equipment')}
            {...modalProps}
          />
        )}
      </Modal>
    </>
  );
};
