import yup from '../../../components/form/CustomYupValidator';
import { isDefined } from '../../../services/utils';

const t = key => I18n.t(`activerecord.errors.models.inspection_type.${key}`);

export const validationSchema = yup.object().shape({
  inspection_type_name: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  people_group_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  objectTypeConfiguration: yup
    .object()
    .required(I18n.t('validations.required'))
    .test(
      'at_least_one_object_type_should_be_selected',
      t('at_least_one_object_type_should_be_selected'),
      function () {
        return [
          this.parent.permits_fdny,
          this.parent.equipment,
          this.parent.construction_project,
          this.parent.biological_safety,
          this.parent.location,
          this.parent.department,
          this.parent.safe_work_authorization,
          this.parent.incident,
          this.parent.accident,
        ].some(e => e);
      },
    ),
  inspectionFindingDefinitionsPerCategory: yup
    .array()
    .required(I18n.t('validations.required'))
    .test(
      'at_least_one_question_should_be_added',
      t('at_least_one_question_should_be_added'),
      function () {
        let result = [];
        this.parent.inspectionFindingDefinitionsPerCategory.forEach(category => {
          result = [...result, ...category.inspection_finding_definitions_types];
        });
        return (
          result.length > 0 &&
          result.some(question => {
            return isDefined(question.inspection_finding_definition_id);
          })
        );
      },
    ),
});
