import React from 'react';
import { FastField } from 'formik';
import { FormHeader } from '../../../components/form/FormHeader';
import { isDefined } from '../../../services/utils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { SelectorField } from '../../../components/fields/SelectorField';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';
import { ChecklistBuilder } from './ChecklistBuilder/ChecklistBuilder';
import { Footer } from './Footer';

const t = key => I18n.t(`administration.inspection_types.new_edit.${key}`);

export const Form = props => {
  const {
    errors,
    touched,
    values: { id, anyRelatedInspections, objectTypeConfiguration },
  } = props;

  return (
    <form>
      <FormHeader title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)} />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.inspection_type',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.inspection_type'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="identifier" disabled component={TextField} />
          <FastField
            name="inspection_type_name"
            required
            component={TextField}
            hint={t('hints.inspection_type_name')}
          />
          <FastField
            name="people_group_id"
            required
            disabled={anyRelatedInspections}
            api={API.selectors.peopleGroups.index}
            selectionApi={API.selectors.peopleGroups.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField
            name="archive_it"
            required
            options={[
              { value: false, data: I18n.t('activerecord.attributes.inspection_type.active') },
              { value: true, data: I18n.t('activerecord.attributes.inspection_type.inactive') },
            ]}
            component={SelectorField}
          />
          <FastField name="allow_cp_findings" component={CheckBoxField} />
        </FormSection>
        <FormSection
          title={t('sections.object_types')}
          required
          name="objectTypeConfiguration"
          errors={errors}
          touched={touched}
        >
          <DefaultAttributesGroup
            defaultChildrenAttributes={{
              labelI18nKeyPrefix: 'activerecord.attributes.inspection_type.object_types',
              shouldUpdate: defaultShouldUpdate,
              resourceName: I18n.t('resource_names.inspection_type'),
            }}
          >
            {Object.keys(objectTypeConfiguration).map(objectType => (
              <FastField
                name={objectType}
                value={objectTypeConfiguration[objectType]}
                component={CheckBoxField}
              />
            ))}
          </DefaultAttributesGroup>
        </FormSection>
        <FormSection
          title={t('sections.checklist')}
          required
          name="inspectionFindingDefinitionsPerCategory"
          errors={errors}
          touched={touched}
        >
          <ChecklistBuilder {...props} />
        </FormSection>
      </DefaultAttributesGroup>
      <Footer {...props} />
    </form>
  );
};
