import React, { useRef } from 'react';
import { FastField } from 'formik';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { DeletionLink } from '../../../components/links/DeletionLink';
import {
  defaultHandleSubmit,
  isDefined,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../services/utils';
import { LightButton } from '../../../components/buttons/LightButton';
import { BrandButton } from '../../../components/buttons/BrandButton';
import { API } from '../../../services/api';
import { Modal } from '../../../components/modals/Modal';
import { ModalSection } from '../../../components/modals/ModalSection';
import { ModalHint } from '../../../components/modals/ModalHint';
import { CancellationLink } from '../../../components/links/CancellationLink';
import { SelectorField } from '../../../components/fields/SelectorField';
import { mapToSelectorOptions } from '../../../services/fieldUtils';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';

const t = key => I18n.t(`requests.waste_requests.new_edit.${key}`);
const resolutionModalT = key => t(`modals.resolution.${key}`);
const resolutionModalOptionsT = key => resolutionModalT(`sub_status_options.${key}`);

const subStatuses = [
  { value: 'collection_assigned', header: resolutionModalOptionsT('collection_assigned') },
  { value: 'without_collection', header: resolutionModalOptionsT('without_collection') },
  { value: 'canceled', header: resolutionModalOptionsT('canceled') },
];

export const Footer = props => {
  const {
    isSubmitting,
    handleSubmit,
    setFieldValue,
    currentUser,
    values: { id, status },
  } = props;

  const isCompleted = status === 'completed';
  const userIsAdmin = currentUser.app_admin || currentUser.org_admin;
  const resolutionModalRef = useRef(null);
  const isCollectionAssigned = ['collection_assigned'].includes(props.values.sub_status);

  return (
    <>
      <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting, hidden: isCompleted }}>
        <FormFooterLeftPart>
          <DeletionLink
            withIcon
            hidden={!(id && userIsAdmin)}
            modelName={I18n.t('activerecord.models.waste_request')}
            onSubmit={async () => {
              const { ok, data } = await API.requests.wasteRequests.destroy(id);
              if (ok) {
                setInitialFlashMessageForNextPage(t('flash.success.destroy'));
                window.location.href = '/requests/waste_requests?status=open';
              } else {
                showBackendErrorMessage(t('flash.error.destroy'), data);
              }
            }}
          />
        </FormFooterLeftPart>
        <FormFooterRightPart>
          <LightButton
            hidden={isCompleted}
            onClick={async () => {
              await setFieldValue('submitType', 'save');
              await defaultHandleSubmit(setFieldValue, handleSubmit);
            }}
          >
            {t('footer.save')}
          </LightButton>
          <BrandButton
            hidden={!isDefined(id) || isCompleted}
            onClick={() => {
              resolutionModalRef.current.open();
            }}
          >
            {t('footer.resolve')}
          </BrandButton>
          <LightButton
            hidden={!isCompleted || isCollectionAssigned}
            onClick={async () => {
              await setFieldValue('submitType', 'reopen');
              await defaultHandleSubmit(setFieldValue, handleSubmit);
            }}
          >
            {t('footer.reopen')}
          </LightButton>
        </FormFooterRightPart>
      </FormFooter>
      <Modal title={resolutionModalT('title')} ref={resolutionModalRef}>
        <ModalSection>
          <ModalHint>{resolutionModalT('hint')}</ModalHint>
          <FastField
            required
            name="sub_status"
            resourceName={I18n.t('resource_names.waste_request')}
            options={mapToSelectorOptions(subStatuses, 'header', 'value')}
            displayMode={fieldDisplayModes.INPUT_FIRST_GROWING_WIDTH_INPUT}
            component={SelectorField}
          />
          <FastField
            name="send_mails"
            component={CheckBoxField}
            label={resolutionModalT('send_email')}
            displayMode={fieldDisplayModes.INPUT_FIRST_GROWING_WIDTH_LABEL}
          />
          <ModalHint>{resolutionModalT('mail_hint')}</ModalHint>
          <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
            <FormFooterRightPart>
              <CancellationLink onClick={() => resolutionModalRef.current.hide()} />
              <BrandButton
                onClick={async () => {
                  resolutionModalRef.current.hide();
                  await setFieldValue('submitType', 'resolve');
                  await defaultHandleSubmit(setFieldValue, handleSubmit);
                }}
              >
                {resolutionModalT('footer.resolve')}
              </BrandButton>
            </FormFooterRightPart>
          </FormFooter>
        </ModalSection>
      </Modal>
    </>
  );
};
