import React from 'react';
import { FastField, Field } from 'formik';
import _ from 'lodash';
import { isDefined } from '../../../services/utils';
import { FormHeader } from '../../../components/form/FormHeader';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate, mapToSelectorOptions } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncMultiSelectorField } from '../../../components/fields/AsyncMultiSelectorField';
import { DateTimeField } from '../../../components/fields/DateTimeField';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { TextAreaField } from '../../../components/fields/TextAreaField';
import { LocationFields } from '../../../components/fields/compositeFields/LocationFields';
import { Footer } from './Footer';
import { Comments } from '../../../components/other/comments/Comments';
import { FilesField } from '../../../components/fields/FilesField';
import { LinksField } from '../../../components/fields/LinksField';
import { SelectorField } from '../../../components/fields/SelectorField';
import RelatedItemsDropdown from '../../../components/form/RelatedItemsDropdown';

const t = key => I18n.t(`requests.incident_requests.new_edit.${key}`);

export const Form = props => {
  const {
    setFieldValue,
    values: {
      status,
      id,
      datetime_of_occurrence_zone,
      auxiliaryDatetimeOfOccurrenceZone,
      datetime_reported_zone,
      auxiliaryDatetimeReportedZone,
    },
  } = props;

  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)}
        auditsLink={isDefined(id) ? `/requests/incident_requests/${id}/audits` : ''}
        additionalActions={[
          isDefined(id) && (
            <RelatedItemsDropdown
              mainObject={{
                resource_name: 'incident_request',
                id,
              }}
              shouldRerender={status}
              api={incidentRequestId => API.relatedItems.incidentRequests(incidentRequestId)}
            />
          ),
        ]}
      />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.incident_request',
          shouldUpdate: defaultShouldUpdate,
          disabled: status === 'completed',
          resourceName: I18n.t('resource_names.incident_request'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="identifier" disabled component={TextField} />
          <Field
            name="datetime_reported"
            required
            component={DateTimeField}
            timeZones={{
              fixedTimeZone: datetime_reported_zone,
              inputTimeZone: auxiliaryDatetimeReportedZone,
            }}
          />
          <FastField
            name="auxiliaryDatetimeReportedZone"
            options={mapToSelectorOptions(props.enums.timeZones, 'header', 'value')}
            component={SelectorField}
            label=""
          />
          <FastField
            name="reporting_person_id"
            required
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
          <Field
            name="datetime_of_occurrence"
            required
            component={DateTimeField}
            timeZones={{
              fixedTimeZone: datetime_of_occurrence_zone,
              inputTimeZone: auxiliaryDatetimeOfOccurrenceZone,
            }}
          />
          <FastField
            name="auxiliaryDatetimeOfOccurrenceZone"
            options={mapToSelectorOptions(props.enums.timeZones, 'header', 'value')}
            component={SelectorField}
            label=""
          />
          <FastField
            name="also_notified_person_ids"
            label={I18n.t('activerecord.attributes.incident_request.also_notified_people_ids')}
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncMultiSelectorField}
          />
          <FastField name="follow_up_contact" component={TextField} />
          <FastField
            name="issue_type_id"
            api={API.selectors.issueTypes.index}
            selectionApi={API.selectors.issueTypes.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
            required
          />
          <FastField
            name="event_type_id"
            required
            api={API.selectors.eventTypes.index}
            selectionApi={API.selectors.eventTypes.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField required name="incident_issue" component={TextAreaField} />
          <LocationFields buildingSelectorAttributes={{ required: true }} {...props} />
          <Field name="location_details" component={TextField} />
          <FastField name="request_attachments" component={FilesField} />
          <FastField name="request_links" component={LinksField} />
        </FormSection>
        <FormSection title={t('sections.resolution')}>
          <FastField
            name="assigned_person_id"
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
            onChange={(selection, defaultOnChange) => {
              defaultOnChange(selection);
              const peopleGroups = selection.data.people_groups;
              const peopleGroupToSelect =
                _.find(peopleGroups, { default: true }) || peopleGroups[0] || {};
              setFieldValue('incident_people_group_id', peopleGroupToSelect.id);
            }}
          />
        </FormSection>
        {id && (
          <FormSection title={t('sections.comments')}>
            <Comments api={API.requests.incidentRequests.comments(id)} disabled={false} />
          </FormSection>
        )}
      </DefaultAttributesGroup>
      <Footer {...props} />
    </form>
  );
};
