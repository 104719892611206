import React from 'react';
import { FastField } from 'formik';
import { FormHeader } from '../../../components/form/FormHeader';
import { isDefined } from '../../../services/utils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { Footer } from './Footer';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';
import { NumericField } from '../../../components/fields/NumericField';
import { EquipmentFieldDefinitions } from './EquipmentFieldDefinitions/EquipmentFieldDefinitions';

const t = key => I18n.t(`equipment_types.new_edit.${key}`);

export const Form = props => {
  const {
    values: { id },
  } = props;

  return (
    <form>
      <FormHeader title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)} />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.equipment_type',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.equipment_type'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="identifier" disabled component={TextField} />
          <FastField name="equip_type_name" required component={TextField} />
          <FastField name="ehs_inspect_freq" required component={NumericField} />
          <FastField name="reg_inspect_freq" component={NumericField} />
          <FastField name="is_active" component={CheckBoxField} />
        </FormSection>
      </DefaultAttributesGroup>
      <Footer {...props} />
      <EquipmentFieldDefinitions {...props} />
    </form>
  );
};
