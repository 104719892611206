import { isEmpty } from 'lodash';
import { withFormik } from 'formik';
import { Form } from './Form';
import {
  isDefined,
  prepareFormFunctions,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { API } from '../../../../services/api';
import { validationSchema } from './validationSchema';

const t = (key, props = {}) =>
  I18n.t(
    `waste_collection_types.modals.add_waste_chemical_item_candidate_field_definition.${key}`,
    props,
  );

export const WasteChemicalItemCandidateFieldDefinitionsFunctions = prepareFormFunctions({
  handleSubmit: (values, formikProps) => {
    const wasteChemicalItemCandidateFieldDefinition = WasteChemicalItemCandidateFieldDefinitionsFunctions.prepareValuesToSubmit(
      values,
    );
    if (!isDefined(wasteChemicalItemCandidateFieldDefinition.id)) {
      WasteChemicalItemCandidateFieldDefinitionsFunctions.create(
        formikProps.props.wasteChemicalItemCandidateTypeId,
        wasteChemicalItemCandidateFieldDefinition,
        formikProps,
      );
    } else {
      WasteChemicalItemCandidateFieldDefinitionsFunctions.update(
        formikProps.props.wasteChemicalItemCandidateTypeId,
        wasteChemicalItemCandidateFieldDefinition,
        formikProps,
      );
    }
  },
  create: async (wasteChemicalItemCandidateTypeId, values, formikProps) => {
    const { hide, listRef } = formikProps.props;
    const api = API.administration.wasteChemicalItemCandidateFieldDefinitions;
    const { ok, data } = await api(wasteChemicalItemCandidateTypeId).create({
      waste_chemical_item_candidate_field_definition: values,
    });
    if (ok) {
      formikProps.resetForm();
      hide();
      listRef.current.refresh();
      showSuccessMessage(t('flash.success.save'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.save'), data);
    }
  },
  update: async (wasteCollectionTypeId, wasteChemicalItemCandidateFieldDefinition, formikProps) => {
    const { hide, listRef } = formikProps.props;
    const api = API.administration.wasteChemicalItemCandidateFieldDefinitions;
    const { ok, data } = await api(wasteCollectionTypeId).update(
      wasteChemicalItemCandidateFieldDefinition.id,
      {
        waste_chemical_item_candidate_field_definition: wasteChemicalItemCandidateFieldDefinition,
      },
    );
    if (ok) {
      formikProps.resetForm();
      hide();
      listRef.current.refresh();
      showSuccessMessage(t('flash.success.update'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error.update'), data);
    }
  },
  joinSelectorValues: selectorValues => (isEmpty(selectorValues) ? null : selectorValues.join(';')),
  // default values for keys which are NOT to be sent on server (keys should be camelCased)
  auxiliaryValues: {},
  // keys of values which are to be sent on server (keys should be snake_cased)
  backendValuesWhitelist: ['id', 'name', 'tooltip', 'placeholder', 'variant', 'selector_values'],
  preprocessBackendValues: backendValues => ({
    ...backendValues,
    selector_values: WasteChemicalItemCandidateFieldDefinitionsFunctions.joinSelectorValues(
      backendValues.selector_values,
    ),
  }),
});

export const AddWasteChemicalItemCandidateFieldDefinitionModalContent = withFormik({
  validate: values => produceDefaultValidation(validationSchema)(values),
  mapPropsToValues: props =>
    WasteChemicalItemCandidateFieldDefinitionsFunctions.prepareInitialValues(
      props.wasteChemicalItemCandidateFieldDefinition,
    ),
  handleSubmit: WasteChemicalItemCandidateFieldDefinitionsFunctions.handleSubmit,
})(Form);
