import React from 'react';
import { withFormik } from 'formik';
import { ModalSection } from '../../../components/modals/ModalSection';
import { FormFooter, FormFooterRightPart } from '../../../components/form/FormFooter';
import { BrandButton } from '../../../components/buttons/BrandButton';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import yup from '../../../components/form/CustomYupValidator';
import { EquipmentFields } from '../../../components/fields/compositeFields/EquipmentFields';
import { API } from '../../../services/api';
import { CancellationLink } from '../../../components/links/CancellationLink';
import {
  defaultHandleSubmit,
  produceDefaultValidation,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../services/utils';

const t = key => I18n.t(`safe_work_authorization_measures.modals.add_equipment.${key}`);

const validationSchema = yup.object().shape({
  equipmentable_type: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
  equipmentable_id: yup
    .string()
    .nullable()
    .required(I18n.t('validations.required')),
});

const AddEquipmentForm = props => {
  const { hide, setFieldValue, handleSubmit, isSubmitting, resourceName } = props;
  return (
    <form>
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          displayMode: fieldDisplayModes.WIDE_FORM_ROW,
          labelI18nKeyPrefix: 'activerecord.attributes.safe_work_authorization_measure_equipment',
          required: true,
          resourceName,
        }}
      >
        <ModalSection>
          <EquipmentFields
            equipmentTypeName="equipmentable_type"
            equipmentIdName="equipmentable_id"
            {...props}
          />
          <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
            <FormFooterRightPart>
              <CancellationLink onClick={hide} />
              <BrandButton onClick={() => defaultHandleSubmit(setFieldValue, handleSubmit)}>
                {t('add')}
              </BrandButton>
            </FormFooterRightPart>
          </FormFooter>
        </ModalSection>
      </DefaultAttributesGroup>
    </form>
  );
};

export const AddEquipmentModalContent = withFormik({
  mapPropsToValues: () => ({ equipmentable_type: '', equipmentable_id: '' }),
  handleSubmit: async (values, formikProps) => {
    const { swaId, measureId, hide, listRef } = formikProps.props;
    const { ok, data } = await API.requests.safeWorkAuthorizations
      .measures(swaId)
      .equipment(measureId)
      .create({ safe_work_authorization_measure_equipment: values });
    if (ok) {
      formikProps.resetForm();
      hide();
      listRef.current.refresh();
      showSuccessMessage(t('flash.success'));
    } else {
      formikProps.setSubmitting(false);
      showBackendErrorMessage(t('flash.error'), data);
    }
  },
  validate: produceDefaultValidation(validationSchema),
})(AddEquipmentForm);
