import React from 'react';
import styled from 'styled-components';
import { breakpoints, breakpointsForMaxWidth } from '../../../../../assets/styles/grid';
import { colors } from '../../../../../assets/styles/colors';
import { fontSize } from '../../../../../assets/styles/typography';

const Row = styled.div`
  padding: 0;
`;

const Label = styled.label.attrs({ htmlFor: props => props.inputId })`
  font-size: ${fontSize.textSm};
  color: ${colors.grayDark};
  line-height: 36px;
  margin-bottom: 0;
  @media only screen and (min-width: ${breakpoints.small}) {
    justify-content: flex-end;
    text-align: right;
  }
  @media only screen and (max-width: ${breakpointsForMaxWidth.small}) {
    margin-left: 4px;
    margin-top: 8px;
    margin-bottom: 2px;
  }
`;

const AdditionalContent = styled.div`
  margin-left: 16px;
  font-size: ${fontSize.textSm};
  @media only screen and (max-width: ${breakpointsForMaxWidth.large}) {
    justify-content: flex-end;
    margin-left: 0;
    margin-top: 8px;
  }
`;

const Info = styled.div`
  font-size: ${fontSize.textSm};
`;

export const QuestionFieldWrapper = props => {
  const {
    label,
    conditionsInfo,
    children,
    additionalContent,
    inputId,
    onMouseOver,
    onMouseLeave,
  } = props;

  return (
    <div className="w-100" onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
      <Row className="w-100 mx-0 mb-1 row d-flex align-items-center">
        <div className="col-1" />
        <div className="col-8 row d-flex align-items-center">
          <Label className="col-xs-12 col-sm-3 col-md-4 col-lg-4 pl-0" inputId={inputId}>
            {label}
          </Label>
          <div className="col-xs-12 col-sm-9 col-md-8 col-lg-8 px-0">{children}</div>
        </div>
        <div className="col-3">
          {additionalContent && (
            <AdditionalContent className="col-12 px-0">
              {additionalContent}
            </AdditionalContent>
          )}
        </div>
      </Row>
      <Row className="w-100 row mx-0 mb-3 row d-flex align-items-center">
        <div className="col-1" />
        <div className="col-8 row d-flex align-items-center">
          <div className="col-xs-12 col-sm-3 col-md-4 col-lg-4 pl-0" />
          {conditionsInfo && (
            <Info className="col-xs-12 col-sm-9 col-md-8 col-lg-8 px-0">
              <div className="d-flex flex-row justify-content-start">
                <div className="mr-1">
                  <i className="fas fa-arrow-right" />
                </div>
                {conditionsInfo}
              </div>
            </Info>
          )}
        </div>
      </Row>
    </div>
  );
};
