import React, { useEffect, useState } from 'react';
import { isDefined } from '../../services/utils';
import { API } from '../../services/api';
import { Loader } from '../../components/Loader';
import { InspectionTypeForm } from './Form';

export const InspectionType = props => {
  const [fetching, setFetching] = useState(true);
  const [inspectionType, setInspectionType] = useState({});
  useEffect(() => {
    (async () => {
      const api = isDefined(props.id)
        ? API.administration.inspectionTypes.show
        : API.administration.inspectionTypes.new;
      const { ok, data } = await api(props.id);
      if (ok) {
        await setInspectionType(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <InspectionTypeForm {...props} inspectionType={inspectionType} />;
};
export default InspectionType;
