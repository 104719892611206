import React, { useState, useEffect } from 'react';
import { isDefined } from '../../services/utils';
import { API } from '../../services/api';
import { Loader } from '../../components/Loader';
import { RadLicenseForm } from './Form';

export const RadLicense = props => {
  const [fetching, setFetching] = useState(true);
  const [radLicense, setRadLicense] = useState({});

  useEffect(() => {
    (async () => {
      const api = isDefined(props.id)
        ? API.radiationSafety.radLicenses.show
        : API.radiationSafety.radLicenses.new;
      const { ok, data } = await api(props.id);
      if (ok) {
        await setRadLicense(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <RadLicenseForm radLicense={radLicense} {...props} />;
};
export default RadLicense;
