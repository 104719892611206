import React from 'react';
import { FastField } from 'formik';
import { isDefined } from '../../../services/utils';
import { FormHeader } from '../../../components/form/FormHeader';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate, mapToSelectorOptions } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncMultiSelectorField } from '../../../components/fields/AsyncMultiSelectorField';
import { DateTimeField } from '../../../components/fields/DateTimeField';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { TextAreaField } from '../../../components/fields/TextAreaField';
import { LocationFields } from '../../../components/fields/compositeFields/LocationFields';
import { Footer } from './Footer';
import { FilesField } from '../../../components/fields/FilesField';
import { SelectorField } from '../../../components/fields/SelectorField';
import { Comments } from '../../../components/other/comments/Comments';
import { LinksField } from '../../../components/fields/LinksField';
import { DateField } from '../../../components/fields/DateField';

const t = key => I18n.t(`general_request.new_edit.${key}`);

export const Form = props => {
  const {
    values: { status, id },
  } = props;

  const isCompleted = status === 'completed';

  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)}
        auditsLink={isDefined(id) ? `/requests/general_requests/${id}/audits` : ''}
      />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.general_request',
          shouldUpdate: defaultShouldUpdate,
          disabled: isCompleted,
          resourceName: I18n.t('resource_names.general_request'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="identifier" disabled component={TextField} />
          <FastField
            name="general_request_type_id"
            required
            api={API.selectors.generalRequestTypes.index}
            selectionApi={API.selectors.generalRequestTypes.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField name="requested_date" required component={DateTimeField} />
          <FastField
            name="creator_id"
            required
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
          <FastField
            name="also_notified_person_ids"
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncMultiSelectorField}
          />
          <LocationFields {...props} />
          <FastField name="location_details" component={TextAreaField} />
          <FastField required name="description" component={TextAreaField} />
          <FastField name="request_attachments" component={FilesField} />
          <FastField name="request_links" component={LinksField} />
        </FormSection>
        <FormSection title={t('sections.ehs_details')}>
          <FastField name="internal_notes" component={TextAreaField} />
          <FastField
            name="label_ids"
            api={API.selectors.labels.index}
            selectionApi={API.selectors.labels.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncMultiSelectorField}
          />
          <FastField
            name="priority"
            options={mapToSelectorOptions(props.enums.priority, 'header', 'value')}
            component={SelectorField}
          />
        </FormSection>
        <FormSection title={t('sections.resolution')}>
          <FastField
            name="ehs_assignee_id"
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
          <FastField
            hidden={!isCompleted}
            name="auxiliaryCompletedSubStatus"
            label={I18n.t('activerecord.attributes.general_request.auxiliary_completed_sub_status')}
            options={mapToSelectorOptions(props.enums.completed_sub_status, 'header', 'value')}
            component={SelectorField}
          />
          <FastField
            hidden={!isCompleted}
            name="auxiliaryResolutionDate"
            label={I18n.t('activerecord.attributes.general_request.auxiliary_resolution_date')}
            component={DateTimeField}
          />
          <FastField hidden={isCompleted} name="due_date" component={DateField} />
          <FastField
            required
            hidden={isCompleted}
            name="sub_status"
            options={mapToSelectorOptions(props.enums.open_sub_status, 'header', 'value')}
            component={SelectorField}
          />
          <FastField name="actions_taken" component={TextAreaField} />
          <FastField name="resolution_attachments" component={FilesField} />
          <FastField name="resolution_links" component={LinksField} />
        </FormSection>
        {id && (
          <FormSection title={t('sections.comments')}>
            <Comments api={API.requests.generalRequests.comments(id)} disabled={false} />
          </FormSection>
        )}
      </DefaultAttributesGroup>
      <Footer {...props} />
    </form>
  );
};
