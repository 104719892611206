import React, { useState } from 'react';
import { FastField } from 'formik';
import { FormHeader } from '../../../components/form/FormHeader';
import { isDefined } from '../../../services/utils';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { DateField } from '../../../components/fields/DateField';
import { SelectorField } from '../../../components/fields/SelectorField';
import { LocationFields } from '../../../components/fields/compositeFields/LocationFields';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { FilesField } from '../../../components/fields/FilesField';
import { LinksField } from '../../../components/fields/LinksField';
import { EquipmentTypeDetails } from './EquipmentTypeDetails';
import { ComplianceTimeline } from './ComplianceTimeline/ComplianceTimeline';
import { Footer } from './Footer';
import { PrintLabelButton } from './PrintLabelButton';
import { setEquipmentType } from './setEquipmentType';

const t = (key, attributes = {}) =>
  I18n.t(`inventory.equipment.equipment.new_edit.${key}`, attributes);

export const Form = props => {
  const {
    values: { id, imported },
    values,
    setFieldValue,
  } = props;

  const [typeDetailsLoading, setTypeDetailsLoading] = useState(false);

  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`, {
          equipment_type: values.equipment_type_name,
        })}
        auditsLink={isDefined(id) ? `/inventory/equipment/equipment/${id}/audits` : ''}
        investigable={
          isDefined(id) && {
            type: 'Equipment',
            id,
            identifier: values.identifier,
          }
        }
        additionalActions={[isDefined(id) && <PrintLabelButton equipmentId={id} />]}
      />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          resourceName: I18n.t('resource_names.equipment'),
          labelI18nKeyPrefix: 'activerecord.attributes.equipment',
          disabled: imported,
          shouldUpdate: defaultShouldUpdate,
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="identifier" disabled component={TextField} />
          <FastField
            name="equipment_type_id"
            required
            disabled={isDefined(id)}
            api={API.selectors.equipmentTypes.index}
            apiParams={{ active: true }}
            selectionApi={API.selectors.equipmentTypes.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
            onChange={async (newSelection, onChange) => {
              setTypeDetailsLoading(true);
              await onChange(newSelection);
              await setEquipmentType(newSelection, setFieldValue);
              setTypeDetailsLoading(false);
            }}
          />
          <FastField name="manufacturer" required component={TextField} />
          <FastField name="model" required component={TextField} />
          <FastField name="serial_no" required component={TextField} />
          <FastField name="date_installed" required component={DateField} />
          <FastField name="date_removed" component={DateField} />
          <FastField
            name="active"
            required
            options={[
              { value: true, data: 'Active' },
              { value: false, data: 'Inactive' },
            ]}
            component={SelectorField}
          />
          <FastField name="equip_use" component={TextField} />
          <FastField name="alternate_equipment_name" component={TextField} />
        </FormSection>
        <EquipmentTypeDetails loading={typeDetailsLoading} {...props} />
        <FormSection title={t('sections.location')}>
          <LocationFields
            buildingSelectorAttributes={{ required: true }}
            floorSelectorAttributes={{ name: 'bldg_flr_id' }}
            spaceSelectorAttributes={{ name: 'location_lab' }}
            {...props}
          />
          <FastField name="location_other" component={TextField} />
        </FormSection>
        <FormSection title={t('sections.responsible_people')}>
          <FastField
            name="responsible_person1_id"
            required
            disabled={false}
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
          <FastField
            name="responsible_person2_id"
            disabled={false}
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
          <FastField
            name="responsible_person3_id"
            disabled={false}
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
          <FastField name="owner" disabled={false} component={TextField} />
        </FormSection>
        <FormSection title={t('sections.attachments')}>
          <FastField name="attachments" disabled={false} component={FilesField} />
          <FastField name="links" disabled={false} component={LinksField} />
          <FastField name="comments" disabled={false} component={TextField} />
        </FormSection>
        <ComplianceTimeline {...props} />
      </DefaultAttributesGroup>
      <Footer {...props} />
    </form>
  );
};
