import React, { useRef, useState } from 'react';
import { FormSection } from '../../../../components/form/FormSection';
import { FormSectionHeader } from '../../../../components/form/FormSectionHeader';
import { FormSectionTitle } from '../../../../components/form/FormSectionTitle';
import { FormSectionHeaderLink } from '../../../../components/form/FormSectionHeaderLinks';
import {
  handleSubmitWithAfterSave,
  isDefined,
  showBackendErrorMessage,
  showSuccessMessage,
} from '../../../../services/utils';
import { EmptyListPlaceholder } from '../../../../components/lists/EmptyListPlaceholder';
import { AsyncSimpleList } from '../../../../components/lists/AsyncSimpleList';
import { API } from '../../../../services/api';
import {
  SimpleListDeletionLink,
  SimpleListLink,
} from '../../../../components/lists/SimpleListLinks';
import { HsdsEmergencyContactsModalContent } from './index';
import { Modal } from '../../../../components/modals/Modal';

const t = key => I18n.t(`hsds.hazard_assessments.new_edit.lists.hsds_emergency_contacts.${key}`);
const modalTitleTranslator = key =>
  I18n.t(`hsds.hazard_assessments.new_edit.modals.hsds_emergency_contacts.title.${key}`);
const hsdsEmergencyContactTranslator = key =>
  I18n.t(`activerecord.attributes.hsds_emergency_contact.${key}`);

export const HsdsEmergencyContactsList = props => {
  const {
    isSubmitting,
    handleSubmit,
    setFieldValue,
    values: { id: spaceRiskAssessmentId },
  } = props;
  const listRef = useRef(null);
  const modalRef = useRef(null);
  const [hsdsEmergencyContact, setHsdsEmergencyContact] = useState({});
  const [modalTitle, setModalTitle] = useState('');
  return (
    <>
      <FormSection>
        <FormSectionHeader>
          <FormSectionTitle>{t('title')}</FormSectionTitle>
          <FormSectionHeaderLink
            disabled={isSubmitting}
            onClick={async () => {
              const afterSave = async newId => {
                const { ok, data } = await API.spaceRiskAssessments
                  .hsdsEmergencyContacts(newId)
                  .new();
                if (ok) {
                  await setHsdsEmergencyContact(data.data.attributes);
                  await setModalTitle(modalTitleTranslator('new'));
                  await modalRef.current.open();
                }
              };
              await handleSubmitWithAfterSave(setFieldValue, handleSubmit, afterSave);
            }}
          >
            {t('add')}
          </FormSectionHeaderLink>
        </FormSectionHeader>
        {!isDefined(spaceRiskAssessmentId) && <EmptyListPlaceholder />}
        {isDefined(spaceRiskAssessmentId) && (
          <AsyncSimpleList
            ref={listRef}
            api={API.spaceRiskAssessments.hsdsEmergencyContacts(spaceRiskAssessmentId).index}
            dataKey="data"
            resourceName={I18n.t('resource_names.hsds_emergency_contact')}
            mapData={({
              attributes: {
                contact_person,
                category,
                office_phone,
                additional_emergency_phone,
                post_emergency_phone,
              },
              id,
            }) => [
              [hsdsEmergencyContactTranslator('contact_person_id'), contact_person.full_name],
              [hsdsEmergencyContactTranslator('category'), category],
              [hsdsEmergencyContactTranslator('office_phone'), office_phone],
              [
                hsdsEmergencyContactTranslator('additional_emergency_phone'),
                additional_emergency_phone,
              ],
              [
                hsdsEmergencyContactTranslator('post_emergency_phone'),
                post_emergency_phone ? I18n.t('true') : I18n.t('false'),
              ],
              <SimpleListLink
                key="view"
                disabled={isSubmitting}
                onClick={async () => {
                  const { ok, data } = await API.spaceRiskAssessments
                    .hsdsEmergencyContacts(spaceRiskAssessmentId)
                    .show(id);
                  if (ok) {
                    await setHsdsEmergencyContact(data.data.attributes);
                    await setModalTitle(modalTitleTranslator('edit'));
                    await handleSubmitWithAfterSave(
                      setFieldValue,
                      handleSubmit,
                      modalRef.current.open,
                    );
                  }
                }}
              >
                {t('view')}
              </SimpleListLink>,
              <SimpleListDeletionLink
                key="remove"
                modelName={t('model_name')}
                onSubmit={async () => {
                  const { ok, data } = await API.spaceRiskAssessments
                    .hsdsEmergencyContacts(spaceRiskAssessmentId)
                    .destroy(id);
                  if (ok) {
                    listRef.current.refresh();
                    showSuccessMessage(t('flash.success.remove'));
                  } else {
                    showBackendErrorMessage(t('flash.error.remove'), data);
                  }
                }}
              >
                {t('remove')}
              </SimpleListDeletionLink>,
            ]}
          />
        )}
      </FormSection>
      <Modal title={modalTitle} ref={modalRef}>
        {modalProps => (
          <HsdsEmergencyContactsModalContent
            spaceRiskAssessmentId={spaceRiskAssessmentId}
            hsdsEmergencyContact={hsdsEmergencyContact}
            currentUser={props.currentUser}
            listRef={listRef}
            {...modalProps}
          />
        )}
      </Modal>
    </>
  );
};
