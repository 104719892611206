import React, { useEffect, useState } from 'react';
import { API } from '../../services/api';
import { WasteCollectionTypeForm } from './Form';
import { Loader } from '../../components/Loader';
import { isDefined } from '../../services/utils';

export const WasteCollectionType = props => {
  const [fetching, setFetching] = useState(true);
  const [wasteCollectionType, setWasteCollectionType] = useState({});
  useEffect(() => {
    (async () => {
      const api = isDefined(props.id)
        ? API.administration.wasteCollectionTypes.show
        : API.administration.wasteCollectionTypes.new;
      const { ok, data } = await api(props.id);
      if (ok) {
        await setWasteCollectionType(data.data.attributes);
        await setFetching(false);
      }
    })();
  }, []);
  if (fetching) {
    return <Loader />;
  }
  return <WasteCollectionTypeForm {...props} wasteCollectionType={wasteCollectionType} />;
};
export default WasteCollectionType;
