import React from 'react';
import { isDefined } from '../../services/utils';

const produceOnClick = onClick => e => {
  e.preventDefault();
  onClick(e);
};

export const SmallLightButton = ({ children, onClick, ...props }) => (
  <button
    className={`btn btn-light btn-sm ${isDefined(props.extended) ? 'btn-extended' : ''}`}
    onClick={produceOnClick(onClick)}
    {...props}
  >
    {children}
  </button>
);
