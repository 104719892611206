import React, { PureComponent } from 'react';
import { Loader } from '../../Loader';
import { FieldWrapper } from '../../fields/auxiliary/FieldWrapper';
import { UserIcon } from './UserIcon';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { Comment } from './Comment';
import { SmallBrandButton } from '../../buttons/SmallBrandButton';
import { TextInput } from '../../inputs/TextInput';
import { showBackendErrorMessage } from '../../../services/utils';

export class Comments extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      comments: [],
      newComment: '',
    };
  }

  componentDidMount() {
    this.fetchComments();
  }

  fetchComments = async () => {
    const { api } = this.props;
    const {
      ok,
      data: { data },
    } = await api.index();
    if (ok) {
      this.setState({ fetching: false, comments: data });
    }
  };

  addComment = () => {
    const { api } = this.props;
    const { newComment } = this.state;
    this.setState({ fetching: true }, async () => {
      const { ok, data } = await api.create(newComment);
      if (!ok) {
        showBackendErrorMessage(I18n.t('comments.add_error'), data);
      }
      this.setState({ newComment: '' }, this.fetchComments);
    });
  };

  render() {
    const { displayMode = fieldDisplayModes.WIDE_FORM_ROW, disabled, resourceName } = this.props;
    const { fetching, comments, newComment } = this.state;

    return (
      <FieldWrapper displayMode={displayMode}>
        {fetching && <Loader />}
        {!fetching && (
          <div className="w-100">
            {comments.map(({ id, attributes }) => (
              <div className="d-flex" key={`comment-${id}`}>
                <UserIcon />
                <Comment id={`${resourceName}-comment-${id}`} attributes={attributes} />
              </div>
            ))}
            {!disabled && (
              <>
                <div className="d-flex">
                  <UserIcon />
                  <TextInput
                    id={`${resourceName}-add-comment-input`}
                    style={{ marginLeft: '10px' }}
                    placeholder={I18n.t('comments.add')}
                    value={newComment}
                    onChange={e => this.setState({ newComment: e.target.value })}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        this.addComment();
                      }
                    }}
                  />
                </div>
                <div className="d-flex justify-content-end mt-2">
                  <SmallBrandButton
                    id={`${resourceName}-add-comment-button`}
                    disabled={!newComment}
                    onClick={this.addComment}
                  >
                    {I18n.t('comments.add')}
                  </SmallBrandButton>
                </div>
              </>
            )}
          </div>
        )}
      </FieldWrapper>
    );
  }
}
