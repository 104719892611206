import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding-right: 10px;
`;

export const FooterAdditionalInformation = props => (
  <Wrapper>
    <i className="fas fa-check-circle" />
    <span>{` ${props.children}`}</span>
  </Wrapper>
);
