import React, { useEffect, useRef, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { FastField } from 'formik';
import includes from 'lodash/includes';
import { FormSection } from '../../../components/form/FormSection';
import { DateField } from '../../../components/fields/DateField';
import { TimeField } from '../../../components/fields/TimeField';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';
import { producePrepareEntries } from '../../../services/safeWorkAuthorization/producePrepareEntries';
import { produceShouldUpdate } from '../../../services/fieldUtils';
import { SmallLightButton } from '../../../components/buttons/SmallLightButton';
import { isFlagTrue } from '../../../services/utils';
import { Modal } from '../../../components/modals/Modal';
import { CustomDateAndTimeModalContent } from './CustomDateAndTimeModalContent';
import { DateAndTimeFromSwa } from './DateAndTimeFromSwa';
import { overwriteEntries } from '../../../services/safeWorkAuthorization/copySwaEntries';
import { dateFormatValidator, getUtcMomentIgnoringTime } from '../../../services/dateFormatUtils';

const t = key => I18n.t(`safe_work_authorization_measures.${key}`);

const prepareEntries = producePrepareEntries('swa_measure_date_time_entries_attributes');

export const DateAndTime = ({ defaultChildrenAttributes, ...props }) => {
  const { setFieldValue, values, setFieldTouched, swa, errors } = props;
  const {
    id,
    use_swa_dates_and_times,
    swa_measure_date_time_entries_attributes: entries,
    custom_dates_and_times,
    start_time,
    end_time,
    start_date,
    end_date
  } = values;
  const modalRef = useRef();
  const [sectionDisabled, setSectionDisabled] = useState(false);
  const [editedName, setEditedName] = useState(null);
  const [warningBannerVisible, setWarningBannerVisible] = useState(false);
  const [entriesClone, setEntriesClone] = useState(false);

  const shouldUpdateEntries =
    start_date &&
    end_date &&
    getUtcMomentIgnoringTime(start_date)
      .add(3, 'months')
      .isSameOrAfter(getUtcMomentIgnoringTime(end_date));

  const sharedOnChangeLogic = async (event, onChange, prepareEntriesInvocation, name) => {
    await onChange(event);
    if (
      !dateFormatValidator.isServerDate(event, false) &&
      includes(['start_date', 'end_date'], name)
    ) {
      setEditedName(name);
      return;
    }
    setSectionDisabled(true);
    if (shouldUpdateEntries)
      await setFieldValue('swa_measure_date_time_entries_attributes', prepareEntriesInvocation());
    if (custom_dates_and_times) setWarningBannerVisible(true);
    setSectionDisabled(false);
  };

  const anyDatesErrors = errors.start_date || errors.end_date;

  const setValuesFromSwa = async () => {
    setSectionDisabled(true);
    await setFieldValue('start_date', swa.start_date);
    await setFieldValue('end_date', swa.end_date);
    await setFieldValue('start_time', swa.start_time);
    await setFieldValue('end_time', swa.end_time);
    await setFieldValue('weekdays', swa.weekdays);
    await setFieldValue('saturdays', swa.saturdays);
    await setFieldValue('sundays', swa.sundays);
    await setFieldValue('custom_dates_and_times', swa.custom_dates_and_times);
    await setFieldValue(
      'swa_measure_date_time_entries_attributes',
      overwriteEntries(entries, swa.swa_date_time_entries_attributes),
    );
    setSectionDisabled(false);
  };

  // when on new form create entries for consistency
  useEffect(() => {
    if (!id) {
      setFieldValue('swa_measure_date_time_entries_attributes', prepareEntries(values));
    }
  }, []);

  return (
    <FormSection
      title={t('sections.date_and_time')}
      defaultChildrenAttributes={{
        ...defaultChildrenAttributes,
        component: CheckBoxField,
        disabled: anyDatesErrors || sectionDisabled,
        hidden: use_swa_dates_and_times,
        shouldUpdate: produceShouldUpdate([], [], ['swa_measure_date_time_entries_attributes']),
      }}
    >
      <FastField
        name="use_swa_dates_and_times"
        disabled={sectionDisabled}
        component={CheckBoxField}
        hidden={false}
        additionalContentInset
        additionalContent={
          <SmallLightButton
            hidden={sectionDisabled || use_swa_dates_and_times}
            onClick={setValuesFromSwa}
          >
            {t('other.reset_to_match_swa')}
          </SmallLightButton>
        }
      />
      <DateAndTimeFromSwa hidden={!use_swa_dates_and_times} />
      <FastField
        name="start_date"
        required
        disabled={editedName !== 'start_date' && sectionDisabled}
        component={DateField}
        onChange={async (newValue, onChange) => {
          const prepareEntriesInvocation = () =>
            prepareEntries({ ...values, start_date: newValue });
          await sharedOnChangeLogic(newValue, onChange, prepareEntriesInvocation, 'start_date');
        }}
        shouldUpdate={produceShouldUpdate(
          [],
          [],
          ['swa_measure_date_time_entries_attributes', 'end_date'],
        )}
      />
      <FastField
        name="end_date"
        required
        disabled={editedName !== 'end_date' && sectionDisabled}
        component={DateField}
        onChange={async (newValue, onChange) => {
          const prepareEntriesInvocation = () => prepareEntries({ ...values, end_date: newValue });
          await sharedOnChangeLogic(newValue, onChange, prepareEntriesInvocation, 'end_date');
          await setFieldTouched('start_date', true);
        }}
      />
      <FastField
        name="start_time"
        required
        component={TimeField}
        onChange={async (newValue, onChange) => {
          const additionalArgs = { previousStartTime: start_time };
          const prepareEntriesInvocation = () =>
            prepareEntries({ ...values, start_time: newValue }, additionalArgs);
          await sharedOnChangeLogic(newValue, onChange, prepareEntriesInvocation);
        }}
        shouldUpdate={produceShouldUpdate(
          [],
          [],
          ['swa_measure_date_time_entries_attributes', 'end_time'],
        )}
      />
      <FastField
        name="end_time"
        required
        component={TimeField}
        onChange={async (newValue, onChange) => {
          const additionalArgs = { previousEndTime: end_time };
          const prepareEntriesInvocation = () =>
            prepareEntries({ ...values, end_time: newValue }, additionalArgs);
          await sharedOnChangeLogic(newValue, onChange, prepareEntriesInvocation);
          await setFieldTouched('start_time', true);
        }}
      />
      <FastField
        name="weekdays"
        onChange={async (event, onChange) => {
          const prepareEntriesInvocation = () =>
            prepareEntries({ ...values, weekdays: event.target.value });
          await sharedOnChangeLogic(event, onChange, prepareEntriesInvocation);
        }}
      />
      <FastField
        name="saturdays"
        onChange={async (event, onChange) => {
          const prepareEntriesInvocation = () =>
            prepareEntries({ ...values, saturdays: event.target.value });
          await sharedOnChangeLogic(event, onChange, prepareEntriesInvocation);
        }}
      />
      <FastField
        name="sundays"
        onChange={async (event, onChange) => {
          const prepareEntriesInvocation = () =>
            prepareEntries({ ...values, sundays: event.target.value });
          await sharedOnChangeLogic(event, onChange, prepareEntriesInvocation);
        }}
      />
      <FastField
        name="custom_dates_and_times"
        onChange={async (event, onChange) => {
          setSectionDisabled(true);
          await onChange(event);
          await setFieldValue('swa_measure_date_time_entries_attributes', prepareEntries(values));
          setSectionDisabled(false);
        }}
        additionalContentInset
        additionalContent={
          <SmallLightButton
            hidden={
              !custom_dates_and_times || sectionDisabled || errors.start_date || errors.start_time
            }
            onClick={() => modalRef.current.open()}
          >
            {t('other.set_custom_dates_and_times')}
          </SmallLightButton>
        }
        warning={
          warningBannerVisible && isFlagTrue(custom_dates_and_times)
            ? t('other.date_and_time_general_changed')
            : null
        }
      />
      <Modal
        title={t('modals.dates_and_times.title')}
        ref={modalRef}
        onOpen={() => {
          setWarningBannerVisible(false);
          setEntriesClone(cloneDeep(entries));
        }}
        onHide={async (onHideProps = {}) => {
          if (!onHideProps.shouldPersistChanges) {
            setSectionDisabled(true);
            await setFieldValue('swa_measure_date_time_entries_attributes', entriesClone);
            setSectionDisabled(false);
          }
          setEntriesClone(null);
        }}
      >
        <CustomDateAndTimeModalContent
          {...props}
          entries={entries}
          modalRef={modalRef}
          onReset={() => {
            setFieldValue(
              'swa_measure_date_time_entries_attributes',
              prepareEntries(values, { reset: true }),
            );
          }}
        />
      </Modal>
    </FormSection>
  );
};
