import React, { useRef, useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import includes from 'lodash/includes'
import { FastField } from 'formik';
import { FormSection } from '../../../components/form/FormSection';
import { DateField } from '../../../components/fields/DateField';
import { TimeField } from '../../../components/fields/TimeField';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';
import { produceShouldUpdate } from '../../../services/fieldUtils';
import { SmallLightButton } from '../../../components/buttons/SmallLightButton';
import { Modal } from '../../../components/modals/Modal';
import { CustomDateAndTimeModalContent } from './CustomDateAndTimeModalContent';
import { isFlagTrue } from '../../../services/utils';
import { TextField } from '../../../components/fields/TextField';
import { producePrepareEntries } from '../../../services/safeWorkAuthorization/producePrepareEntries';
import { dateFormatValidator, getUtcMomentIgnoringTime} from '../../../services/dateFormatUtils';

const t = key => I18n.t(`safe_work_authorizations.${key}`);

const prepareEntries = producePrepareEntries('swa_date_time_entries_attributes');

export const DateAndTime = props => {
  const { setFieldValue, values, setFieldTouched, errors } = props;
  const {
    id,
    swa_date_time_entries_attributes: entries,
    is_completed,
    custom_dates_and_times,
    start_time,
    end_time,
    start_date,
    end_date
  } = values;
  const modalRef = useRef();
  const [sectionDisabled, setSectionDisabled] = useState(false);
  const [editedName, setEditedName] = useState(null);
  const [warningBannerVisible, setWarningBannerVisible] = useState(false);
  const [entriesClone, setEntriesClone] = useState(false);

  const shouldUpdateEntries =
    start_date &&
    end_date &&
    getUtcMomentIgnoringTime(start_date)
      .add(3, 'months')
      .isSameOrAfter(getUtcMomentIgnoringTime(end_date));

  const sharedOnChangeLogic = async (event, onChange, prepareEntriesInvocation, name) => {
    await onChange(event);
    if (
      !dateFormatValidator.isServerDate(event, false) &&
      includes(['start_date', 'end_date'], name)
    ) {
      setEditedName(name);
      return;
    }
    setSectionDisabled(true);
    if (shouldUpdateEntries) await setFieldValue('swa_date_time_entries_attributes', prepareEntriesInvocation());
    if (custom_dates_and_times) setWarningBannerVisible(true);
    setSectionDisabled(false);
  };

  const anyDatesErrors = errors.start_date || errors.end_date;

  // when on new form create entries for consistency
  useEffect(() => {
    if (!id) {
      setFieldValue('swa_date_time_entries_attributes', prepareEntries(values));
    }
  }, []);

  return (
    <>
      <FormSection
        title={t('sections.date_and_time')}
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.safe_work_authorization',
          disabled: is_completed || anyDatesErrors || sectionDisabled,
          shouldUpdate: produceShouldUpdate([], [], ['swa_date_time_entries_attributes']),
          component: CheckBoxField,
          resourceName: I18n.t('resource_names.safe_work_authorization'),
        }}
      >
        <FastField
          name="start_date"
          required
          disabled={editedName !== 'start_date' && (is_completed || sectionDisabled)}
          component={DateField}
          onChange={async (newValue, onChange) => {
            const prepareEntriesInvocation = () =>
              prepareEntries({ ...values, start_date: newValue });
            await sharedOnChangeLogic(newValue, onChange, prepareEntriesInvocation, 'start_date');
          }}
          shouldUpdate={produceShouldUpdate(
            [],
            [],
            ['swa_date_time_entries_attributes', 'end_date'],
          )}
        />
        <FastField
          name="end_date"
          required
          disabled={editedName !== 'end_date' && (is_completed || sectionDisabled)}
          component={DateField}
          onChange={async (newValue, onChange) => {
            const prepareEntriesInvocation = () =>
              prepareEntries({ ...values, end_date: newValue });
            await sharedOnChangeLogic(newValue, onChange, prepareEntriesInvocation, 'end_date');
            await setFieldTouched('start_date', true);
          }}
        />
        <FastField
          name="start_time"
          component={TimeField}
          onChange={async (newValue, onChange) => {
            const additionalArgs = { previousStartTime: start_time };
            const prepareEntriesInvocation = () =>
              prepareEntries({ ...values, start_time: newValue }, additionalArgs);
            await sharedOnChangeLogic(newValue, onChange, prepareEntriesInvocation);
          }}
          shouldUpdate={produceShouldUpdate(
            [],
            [],
            ['swa_date_time_entries_attributes', 'end_time'],
          )}
        />
        <FastField
          name="end_time"
          component={TimeField}
          onChange={async (newValue, onChange) => {
            const additionalArgs = { previousEndTime: end_time };
            const prepareEntriesInvocation = () =>
              prepareEntries({ ...values, end_time: newValue }, additionalArgs);
            await sharedOnChangeLogic(newValue, onChange, prepareEntriesInvocation);
            await setFieldTouched('start_time', true);
          }}
        />
        <FastField
          name="weekdays"
          onChange={async (event, onChange) => {
            const prepareEntriesInvocation = () =>
              prepareEntries({ ...values, weekdays: event.target.value });
            await sharedOnChangeLogic(event, onChange, prepareEntriesInvocation);
          }}
        />
        <FastField
          name="saturdays"
          onChange={async (event, onChange) => {
            const prepareEntriesInvocation = () =>
              prepareEntries({ ...values, saturdays: event.target.value });
            await sharedOnChangeLogic(event, onChange, prepareEntriesInvocation);
          }}
        />
        <FastField
          name="sundays"
          onChange={async (event, onChange) => {
            const prepareEntriesInvocation = () =>
              prepareEntries({ ...values, sundays: event.target.value });
            await sharedOnChangeLogic(event, onChange, prepareEntriesInvocation);
          }}
        />
        <FastField
          name="custom_dates_and_times"
          onChange={async (event, onChange) => {
            setSectionDisabled(true);
            await onChange(event);
            await setFieldValue('swa_date_time_entries_attributes', prepareEntries(values));
            setSectionDisabled(false);
          }}
          additionalContentInset
          additionalContent={
            <SmallLightButton
              hidden={
                !custom_dates_and_times || sectionDisabled || errors.start_date || errors.start_time
              }
              onClick={() => modalRef.current.open()}
            >
              {t('other.set_custom_dates_and_times')}
            </SmallLightButton>
          }
          warning={
            warningBannerVisible && isFlagTrue(custom_dates_and_times)
              ? t('other.date_and_time_general_changed')
              : null
          }
        />
        <FastField name="dates_and_times_additional_information" component={TextField} />
      </FormSection>
      <Modal
        title={t('modals.dates_and_times.title')}
        ref={modalRef}
        onOpen={() => {
          setWarningBannerVisible(false);
          setEntriesClone(cloneDeep(entries));
        }}
        onHide={async (onHideProps = {}) => {
          if (!onHideProps.shouldPersistChanges) {
            setSectionDisabled(true);
            await setFieldValue('swa_date_time_entries_attributes', entriesClone);
            setSectionDisabled(false);
          }
          setEntriesClone(null);
        }}
      >
        <CustomDateAndTimeModalContent
          {...props}
          entries={entries}
          modalRef={modalRef}
          onReset={() => {
            setFieldValue(
              'swa_date_time_entries_attributes',
              prepareEntries(values, { reset: true }),
            );
          }}
        />
      </Modal>
    </>
  );
};
