import React, { useRef } from 'react';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { BrandButton } from '../../../components/buttons/BrandButton';
import {
  defaultHandleSubmit,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../services/utils';
import { DeletionLink } from '../../../components/links/DeletionLink';
import { API } from '../../../services/api';
import { Modal } from '../../../components/modals/Modal';
import { ConfirmationModalContent } from '../../../components/modals/ConfirmationModalContent';

const t = key => I18n.t(`administration.inspection_types.new_edit.${key}`);

export const Footer = ({
  isSubmitting,
  setFieldValue,
  handleSubmit,
  values: { id, anyRelatedInspections },
  currentUser,
}) => {
  const relatedInspectionsWarningModalRef = useRef(null);

  return (
    <>
      <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
        <FormFooterLeftPart>
          <DeletionLink
            withIcon
            hidden={!(id && (currentUser.app_admin || currentUser.org_admin))}
            modelName={I18n.t('activerecord.models.inspection_type')}
            onSubmit={async () => {
              const { ok, data } = await API.administration.inspectionTypes.destroy(id);
              if (ok) {
                setInitialFlashMessageForNextPage(t('flash.success.remove'));
                window.location.href = `/administration/inspection_types`;
              } else {
                showBackendErrorMessage(t('flash.error.remove'), data);
              }
            }}
          />
        </FormFooterLeftPart>
        <FormFooterRightPart>
          <BrandButton
            onClick={async () => {
              if (anyRelatedInspections) {
                relatedInspectionsWarningModalRef.current.open();
              } else {
                await setFieldValue('submitType', 'save');
                await defaultHandleSubmit(setFieldValue, handleSubmit);
              }
            }}
          >
            {t('footer.save')}
          </BrandButton>
        </FormFooterRightPart>
      </FormFooter>
      <Modal
        ref={relatedInspectionsWarningModalRef}
        title={t('footer.related_inspection_warning_modal.title')}
      >
        <ConfirmationModalContent
          modalRef={relatedInspectionsWarningModalRef}
          hint={t('footer.related_inspection_warning_modal.warning')}
          confirm={t('footer.save')}
          onConfirm={async () => {
            await setFieldValue('submitType', 'save');
            await defaultHandleSubmit(setFieldValue, handleSubmit);
          }}
        />
      </Modal>
    </>
  );
};
