import React, { useRef } from 'react';
import { FastField } from 'formik';
import {
  FormFooter,
  FormFooterLeftPart,
  FormFooterRightPart,
} from '../../../components/form/FormFooter';
import { LightButton } from '../../../components/buttons/LightButton';
import { BrandButton } from '../../../components/buttons/BrandButton';
import { DeletionLink } from '../../../components/links/DeletionLink';
import { Modal } from '../../../components/modals/Modal';
import { ModalSection } from '../../../components/modals/ModalSection';
import { DefaultLink } from '../../../components/links/DefaultLink';
import { API } from '../../../services/api';
import {
  defaultHandleSubmit,
  setInitialFlashMessageForNextPage,
  showBackendErrorMessage,
} from '../../../services/utils';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { fieldDisplayModes } from '../../../constants/fieldDisplayModes';
import { CheckBoxField } from '../../../components/fields/CheckBoxField';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { produceShouldUpdate } from '../../../services/fieldUtils';

const t = key => I18n.t(`requests.incident_requests.new_edit.${key}`);
const resolutionModalT = key => t(`modals.resolution.${key}`);

export const Footer = props => {
  const {
    isSubmitting,
    handleSubmit,
    setFieldValue,
    currentUser,
    values: { id, status, reopenable, incidentCreation, incident_people_group_id, incident_ids },
  } = props;

  const resolutionModalRef = useRef(null);
  const isCompleted = status === 'completed';
  const userIsAdmin = currentUser.app_admin || currentUser.org_admin;
  const countOfIncidents = incident_ids.length;

  return (
    <>
      <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting, hidden: isCompleted }}>
        <FormFooterLeftPart>
          <DeletionLink
            withIcon
            hidden={!(id && userIsAdmin)}
            modelName={I18n.t('activerecord.models.incident_request')}
            additionalModalContent={
              countOfIncidents > 0 &&
              I18n.t('activerecord.warning_messages.models.incident_request.related_to_incident', {
                count: countOfIncidents,
              })
            }
            onSubmit={async () => {
              const { ok, data } = await API.requests.incidentRequests.destroy(id);
              if (ok) {
                setInitialFlashMessageForNextPage(t('flash.success.delete'));
                window.location.href = `/requests/incident_requests?status=open`;
              } else {
                showBackendErrorMessage(t('flash.error.delete'), data);
              }
            }}
          />
        </FormFooterLeftPart>
        <FormFooterRightPart>
          <LightButton
            onClick={async () => {
              await setFieldValue('submitType', 'save');
              await defaultHandleSubmit(setFieldValue, handleSubmit);
            }}
          >
            {t('footer.save')}
          </LightButton>
          <LightButton
            hidden={!reopenable}
            onClick={async () => {
              await setFieldValue('submitType', 'reopen');
              await defaultHandleSubmit(setFieldValue, handleSubmit);
            }}
          >
            {t('footer.reopen')}
          </LightButton>
          <BrandButton
            hidden={!id || isCompleted}
            onClick={() => {
              resolutionModalRef.current.open();
            }}
          >
            {t('footer.resolve')}
          </BrandButton>
        </FormFooterRightPart>
      </FormFooter>
      <Modal title={resolutionModalT('title')} ref={resolutionModalRef}>
        <ModalSection>
          <FastField
            name="incidentCreation"
            component={CheckBoxField}
            label={resolutionModalT('prompt')}
            displayMode={fieldDisplayModes.INPUT_FIRST_GROWING_WIDTH_LABEL}
          />
          <DefaultAttributesGroup
            defaultChildrenAttributes={{
              displayMode: fieldDisplayModes.WIDE_FORM_ROW,
              required: true,
              hidden: !incidentCreation,
              resourceName: I18n.t('resource_names.incident_request'),
            }}
          >
            <FastField
              name="incident_people_group_id"
              component={AsyncSelectorField}
              label={I18n.t('activerecord.attributes.incident.people_group_id')}
              api={API.selectors.peopleGroups.index}
              selectionApi={API.selectors.peopleGroups.show}
              optionRenderer={htmlOptionRenderer('text')}
              onChange={(selection, defaultOnChange) => {
                defaultOnChange(selection);
                setFieldValue('assigned_person_id', '');
              }}
              shouldUpdate={produceShouldUpdate([], [], ['incidentCreation'])}
            />
            <FastField
              name="assigned_person_id"
              component={AsyncSelectorField}
              labelI18nKeyPrefix="activerecord.attributes.incident_request"
              api={API.selectors.people.index}
              apiParams={
                incident_people_group_id && { with_people_group_id: incident_people_group_id }
              }
              selectionApi={API.selectors.people.show}
              optionRenderer={htmlOptionRenderer('text')}
              headerRenderer={defaultOptionRenderer('full_name')}
              shouldUpdate={produceShouldUpdate(
                [],
                [],
                ['incidentCreation', 'incident_people_group_id'],
              )}
            />
          </DefaultAttributesGroup>
          <FormFooter defaultChildrenAttributes={{ disabled: isSubmitting }}>
            <FormFooterRightPart>
              <DefaultLink onClick={() => resolutionModalRef.current.hide()}>
                {resolutionModalT('footer.cancel')}
              </DefaultLink>
              <BrandButton
                onClick={async () => {
                  resolutionModalRef.current.hide();
                  await setFieldValue(
                    'submitType',
                    incidentCreation ? 'resolveAndCreateIncident' : 'resolve',
                  );
                  await defaultHandleSubmit(setFieldValue, handleSubmit);
                }}
              >
                {resolutionModalT('footer.resolve')}
              </BrandButton>
            </FormFooterRightPart>
          </FormFooter>
        </ModalSection>
      </Modal>
    </>
  );
};
