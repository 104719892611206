import React from 'react';

const t = (key, args = {}) => I18n.t(`helpers.page_entries_info.${key}`, args);

const getContent = ({ currentPage, totalPages, count, totalCount, limitValue }) => {
  if (totalCount === 0) {
    return t('one_page.display_entries.zero');
  } else if (totalCount === 1) {
    return t('one_page.display_entries.one');
  } else if (totalPages === 1) {
    return t('one_page.display_entries.other', { count });
  }
  const first = (currentPage - 1) * limitValue + 1;
  const last = (currentPage - 1) * limitValue + count;
  return t('more_pages.display_entries', {
    count,
    first,
    last,
    total: totalCount,
  });
};

export const IndexTableEntriesCount = props => (
  <span
    className={props.totalCount !== 0 ? 'small-text' : ''}
    dangerouslySetInnerHTML={{ __html: getContent(props) }}
  />
);
