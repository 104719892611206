import React from 'react';
import styled from 'styled-components';
import { fontSize } from '../../../assets/styles/typography';
import { colors } from '../../../assets/styles/colors';
import { dateFormatter } from '../../../services/dateFormatUtils';

const Container = styled.div`
  font-size: ${fontSize.textSm};
  padding-left: 10px;
  padding-bottom: 1.5rem;
`;

const UserName = styled.span`
  font-weight: bold;
`;

const CreatedDate = styled.span`
  padding-left: 5px;
  color: ${colors.gray};
`;

export const Comment = ({
  id,
  attributes: {
    user: { first_name, last_name },
    created_at,
    body,
  },
}) => (
  <Container id={id}>
    <div>
      <UserName>
        {first_name} {last_name}
      </UserName>
      <CreatedDate>{dateFormatter.fromNow(created_at)}</CreatedDate>
    </div>
    <div>{body}</div>
  </Container>
);
