import React from 'react';
import { FastField } from 'formik';
import { handleSubmitWithAfterSave, isDefined } from '../../../services/utils';
import { FormHeader } from '../../../components/form/FormHeader';
import { DefaultAttributesGroup } from '../../../components/form/DefaultAttributesGroup';
import { defaultShouldUpdate, mapToSelectorOptions } from '../../../services/fieldUtils';
import { FormSection } from '../../../components/form/FormSection';
import { TextField } from '../../../components/fields/TextField';
import { API } from '../../../services/api';
import { htmlOptionRenderer } from '../../../components/fields/selectorOptionRenderers/htmlOptionRenderer';
import { AsyncSelectorField } from '../../../components/fields/AsyncSelectorField';
import { defaultOptionRenderer } from '../../../components/fields/selectorOptionRenderers/defaultOptionRenderer';
import { SelectorField } from '../../../components/fields/SelectorField';
import { LocationFields } from '../../../components/fields/compositeFields/LocationFields';
import { DateTimeField } from '../../../components/fields/DateTimeField';
import { DateField } from '../../../components/fields/DateField';
import { TextAreaField } from '../../../components/fields/TextAreaField';
import { FilesField } from '../../../components/fields/FilesField';
import { Footer } from './Footer';
import { Locations } from '../../../components/other/locations/Locations';
import { LinksField } from '../../../components/fields/LinksField';

const t = key => I18n.t(`inventory.permits.lab_permits.new_edit.${key}`);

export const Form = props => {
  const {
    values: { id, permit_fdny_acct },
    setFieldValue,
    handleSubmit,
    enums,
  } = props;
  return (
    <form>
      <FormHeader
        title={t(`title.${isDefined(id) ? 'edit' : 'new'}`)}
        auditsLink={isDefined(id) ? `/inventory/permits/lab_permits/${id}/audits` : ''}
        investigable={
          isDefined(id) && {
            id,
            identifier: permit_fdny_acct,
            type: 'PermitsFdny',
          }
        }
      />
      <DefaultAttributesGroup
        defaultChildrenAttributes={{
          labelI18nKeyPrefix: 'activerecord.attributes.permits_fdny',
          shouldUpdate: defaultShouldUpdate,
          resourceName: I18n.t('resource_names.permits_fdny'),
        }}
      >
        <FormSection title={t('sections.overview')}>
          <FastField name="identifier" disabled component={TextField} />
          <FastField
            name="permits_issuer_id"
            required
            api={API.selectors.permitsIssuers.index}
            selectionApi={API.selectors.permitsIssuers.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <LocationFields
            buildingSelectorAttributes={{ name: 'primary_building_id', required: true }}
            floorSelectorAttributes={{ name: 'primary_buildings_floor_id' }}
            spaceSelectorAttributes={{ name: 'primary_lab_id' }}
            {...props}
          />
          <FastField
            required
            name="permits_type_fdny_id"
            api={API.selectors.permitTypes.index}
            selectionApi={API.selectors.permitTypes.show}
            optionRenderer={htmlOptionRenderer('text')}
            component={AsyncSelectorField}
          />
          <FastField required name="permit_fdny_acct" component={TextField} />
          <FastField
            name="responsible_person_id"
            api={API.selectors.people.index}
            selectionApi={API.selectors.people.show}
            optionRenderer={htmlOptionRenderer('text')}
            headerRenderer={defaultOptionRenderer('full_name')}
            component={AsyncSelectorField}
          />
          <FastField name="permit_date_last_fdny_inspect" component={DateTimeField} />
          <FastField name="issuance_date" component={DateField} />
          <FastField name="permit_expire_date" component={DateField} />
          <FastField
            required
            name="permit_status"
            options={mapToSelectorOptions(enums.status, 'header', 'value')}
            component={SelectorField}
          />
          <FastField name="renewal_process" component={TextAreaField} />
          <FastField name="permit_comments" component={TextAreaField} />
          <FastField name="attachments" component={FilesField} />
          <FastField name="links" component={LinksField} />
        </FormSection>
      </DefaultAttributesGroup>
      <Footer {...props} />
      <Locations
        api={API.permits.locations}
        id={id}
        title={t('sections.secondary_locations')}
        resourceName={I18n.t('resource_names.permits_fdny_location')}
        humanizedResourceName={I18n.t('activerecord.models.permits_fdny_location')}
        handleAdditionLinkOnClick={open =>
          handleSubmitWithAfterSave(setFieldValue, handleSubmit, open)
        }
        prepareValuesToSubmit={values => ({
          permit_location: values,
        })}
      />
    </form>
  );
};
